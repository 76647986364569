<div class="position-relative">
    <fa-icon
        [icon]="circleIcon"
        size="2xl"
        [ngClass]="initialsClass"/>
    <small *ngIf="!isNonSystemUser"
        class="position-absolute top-50 start-50 translate-middle text-white">
        {{ initials }}
    </small>
    <fa-icon *ngIf="isNonSystemUser"
             [icon]="userIcon"
             class="position-absolute top-50 start-50 translate-middle text-white"/>
    <span
        *ngIf="hasNotifications"
        class="position-absolute top-0 end-0 p-1 rounded-circle notification-badge">
        <span class="visually-hidden">New release notes</span>
    </span>
</div>
