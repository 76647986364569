import { FEED_SUPPORT_TICKETS_ALL, FLEXITICKET_PORTAL } from '@app/feed-support/constants/feed-support-tickets.constant';
import * as angular from 'angular';
import { MODULE_NAME } from '../../../config/constants';

angular.module(MODULE_NAME)
    .controller('FeedSupportCreateTicket', ['$scope', '$http', '$location', '$state', '$stateParams', '$upload', '$timeout', '$cookies', 'AppStateService', 'FeedSupportStateService', 'FeedSupportStateServiceNew', 'fdxUI', 'ModalService', function ($scope, $http, $location, $state, $stateParams, $upload, $timeout, $cookies, AppStateService, FeedSupportStateService, FeedSupportStateServiceNew, fdxUI, ModalService) {
        // Set on index.php
        $scope.config_hubspot_id = '';
        if (typeof window.config_hubspot_id !== 'undefined') {
            $scope.config_hubspot_id = window.config_hubspot_id;
        }

        $scope.account_id = AppStateService.getAccount().id;
        $scope.user = AppStateService.getUser();

        $scope.fs_navigation_tab = $stateParams['fs_navigation_tab'] || 'create_new_ticket';
        $scope.form = null;
        $scope.label = null;
        $scope.article = 'a';
        $scope.formLoaded = false;
        $scope.analystPlaceholder = '';

        $scope.entities = {
            "db_groups": [],
            "db_group_populated": false,
            "db_groups_only": [],
            "dbs_only": [],
            "db_groups_and_dbs": []
        };
        $scope.store_definition = "db_groups_only";

        $scope.display_categories = false;
        $scope.load_imports = false;
        $scope.custom_field_names = ["db_group_id", "Service", "Service Detail", "Feed Language", "Marketing Channel", "Marketing Channel Country", "Credentials"]; // This list will expand

        $scope.ebay_minimal_ui = {};
        $scope.ebay_minimal_ui.flag = false;
        $scope.ebay_minimal_ui.form = '/app/feedsupport/forms/ebay_minimal_ui.html';
        $scope.ebay_minimal_ui.display_ready = false;
        $scope.ebay_minimal_ui.selected =
        {
            'Service': 'General or Maintenance',
            'Service Detail': 'General',
            'display_template': '/app/feedsupport/forms/ebay_minimal_ui.html',
            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials', 'Platform'],
        };

        $scope.analyst_only = {
            'effective_user': {},
            'users': [],
            'loading_users': false,
        };

        $scope.primary_user_flag = false;
        $scope.primary_email_list = "";

        $scope.show_form = {};
        $scope.logo = '';

        $scope.rand_string = (new Date()).getTime();
        $scope.feedsupport_ticket_submitting = false;

        $scope.timezones = {
            'default_timezone': { "name": "(UTC-08:00) Pacific Time (US & Canada) - Pacific Standard Time", "offset": "-08:00" },
            'selected_timezone': { "name": "(UTC-08:00) Pacific Time (US & Canada) - Pacific Standard Time", "offset": "-08:00" }
        }

        $scope.post_ticket_redirect = {
            'accordion_name': '',
            'title': '',
            'db_group_id': '',
        };

        $scope.unique_options = {};
        $scope.custom_user_experience = [];
        $scope.selected_template = {};
        $scope.previous_template = {};

        $scope.toast_number_in_progress = -1;
        $scope.toast_number_retry_delay = 1500;
        $scope.toast_number_retry_limit = 40;

        $scope.analyst_role = AppStateService.isPrivacyLevelAtLeastAnalyst();

        $scope.store_dropdown = {
            'unfiltered_limit': 100,
            'search_result_limit': 100,
            'load_flag': false,
            'stores_list': []
        }

        // Analyst Ticket Portal
        $scope.analyst_ticket_portal = {
            'services': [],
            'service_details': [],
            'mappings': {},
            'agents': [],
            'cf_platform': [],
            'cf_billing_premise': [],
            'cf_first_month_pro_rated': [],
            'change_flag': false,
            'initialized': false
        }

        $scope.$watch('fs_navigation_tab', function (tab) {
            if (tab == 'create_new_ticket') {
                $scope.initialize_entities();
                $scope.custom_category_access();
            }
            else {
                $scope.unset_stores();
            }

        });

        $scope.hubspot_utk_cookie = "";

        $scope.$watch(function () {
            return $cookies.get("hubspotutk");
        }, function (v) {
            if (v) {
                $scope.hubspot_utk_cookie = v;
            }
            else {
                $scope.hubspot_utk_cookie = "";
            }

        });

        $scope.$watch('primary_user_flag', function (access) {
            $scope.primary_user_flag = access;
            if ($scope.primary_user_flag === false && $scope.primary_email_list == "") {
                var params = {};
                $http.get('/api.php/accounts/' + $scope.account_id + '/primary_user', params)
                    .then(function (response) {
                        $scope.primary_email_list = response.data;
                    }, function (response) {
                        // console.log(response);
                    });
            }
        });

        $scope.$watch('timezones.selected_timezone', function (new_timezone) {
            $scope.onTimeZoneChange();
        });


        $scope.$on('wizard_completed_successfully', function (event, args) {
            $scope.forms.selected.extras.wizard_store_url = args['store_url'];
            $scope.forms.selected.extras.wizard_db_id = args['db_id'];
            $scope.forms.selected.extras.wizard_oauth_token = args['oauth_token'];
            $scope.forms.selected.extras.wizard_skipped = false;
            $scope.forms.selected.extras.wizard_done = true;

        });

        $scope.$on('wizard_skipped', function (event, args) {
            $scope.forms.selected.extras.wizard_skipped = true;

        });



        $scope.has_vivino = function () {
            return AppStateService.getAccount()?.features?.feedsupport_categories?.includes('vivino');
        };

        $scope.initialize_entities = function (update_entities_flag = false, effective_user_id = '0') {
            if (!$scope.entities.db_group_populated || update_entities_flag) {

                if (!$scope.analyst_role) {
                    effective_user_id = '0';
                }

                var fsPromise = FeedSupportStateService.getStores($scope.account_id, effective_user_id, update_entities_flag).then((stores) => {

                    if (typeof stores == 'undefined' || stores == null) {
                        stores = [];
                    }

                    if (stores.length > 100) {
                        $scope.store_dropdown.load_flag = true;
                        $scope.entities.db_groups = [];
                    }
                    else {
                        $scope.store_dropdown.load_flag = false;
                        $scope.entities.db_groups = stores;

                        $scope.entities.db_groups = $scope.honor_unique_case($scope.entities.db_groups);
                        $scope.entities.db_group_populated = true;

                    }

                    var store_access_flag = true;

                    if ($scope.store_definition == 'dbs_only') {

                        var db_selected = typeof $scope.forms.selected != 'undefined'
                            && typeof $scope.forms.selected.entities != 'undefined'
                            && $scope.forms.selected.entities.db_id > 0;

                        if (effective_user_id > 0 && db_selected) {
                            store_access_flag = false;
                            // Changing the user means its possible the db  ID may no longer match. If its not in the list- remove it.
                            for (var db_group in stores) {
                                if (stores[db_group].db_id == $scope.forms.selected.entities.db_id) {
                                    store_access_flag = true;
                                }
                            }
                        }

                    }
                    else {

                        var group_selected = typeof $scope.forms.selected != 'undefined'
                            && typeof $scope.forms.selected.entities != 'undefined'
                            && $scope.forms.selected.entities.db_group_id > 0;

                        if (effective_user_id > 0 && group_selected) {
                            store_access_flag = false;
                            // Changing the user means its possible the db group ID may no longer match. If its not in the list- remove it.
                            for (var db_group in stores) {
                                if (stores[db_group].id == $scope.forms.selected.entities.db_group_id) {
                                    store_access_flag = true;
                                }
                            }
                        }

                    }

                    if (!store_access_flag) {
                        // Unsetting the DB Group ID is insufficient and ambiguous.
                        // $scope.forms.selected.entities.db_group_id = 0;
                        $scope.clear_all($scope.forms.selected);
                    }

                    // We also need to do the same other entities. Right now only imports is supports
                    if ($scope.load_imports) {
                        // If imports are supported- check if they are relevant to the selected form.
                        if (typeof $scope.forms.selected !== 'undefined' && typeof $scope.forms.selected.relevant_entities !== 'undefined') {
                            for (var relevant_entity in $scope.forms.selected.relevant_entities) {
                                if ($scope.forms.selected.relevant_entities[relevant_entity] == 'import_id') {
                                    $scope.analyst_load_imports(effective_user_id);
                                }
                            }
                        }
                    }

                    if ($scope.ebay_minimal_ui.flag && $scope.entities.db_groups.length == 1) {
                        if (typeof $scope.forms.selected !== 'undefined' && typeof $scope.forms.selected.extras !== 'undefined') {
                            $scope.forms.selected.extras.store = $scope.entities.db_groups[0];
                        }

                        if (typeof $scope.forms.selected !== 'undefined' && typeof $scope.forms.selected.entities !== 'undefined') {
                            $scope.assign_store($scope.forms.selected);
                        }
                    }
                    $scope.ebay_minimal_ui.display_ready = true;
                });
            }
        }

        // Store Dropdown Management
        $scope.dropdownOpen = function (isOpen) {
            if (isOpen) {
                $scope.set_stores();
            }
            else {
                $scope.unset_stores();
            }
        }

        $scope.set_stores = function () {
            if ($scope.store_dropdown.load_flag) {
                var effective_user_id = 0;
                if (typeof $scope.analyst_only.effective_user.user_id !== 'undefined') {
                    effective_user_id = $scope.analyst_only.effective_user.user_id;
                }
                var fsPromise = FeedSupportStateService.getStores($scope.account_id, effective_user_id, true).then((stores) => {
                    if (typeof stores == 'undefined' || stores == null) {
                        stores = [];
                    }
                    $scope.store_dropdown.stores_list = $scope.honor_unique_case(stores);

                });
            }
            else {
                $scope.store_dropdown.stores_list = $scope.entities.db_groups;
            }
        }

        $scope.unset_stores = function () {
            $scope.store_dropdown.stores_list = [];
        }

        $scope.honor_unique_case = function (incomplete_list) {

            var unique_case = '';
            if (typeof $scope.forms !== 'undefined' &&
                typeof $scope.forms.selected !== 'undefined' &&
                typeof $scope.forms.selected.unique_case !== 'undefined' &&
                $scope.forms.selected.unique_case !== null) {
                unique_case = $scope.forms.selected.unique_case;
            }

            if ($scope.analyst_role) {
                incomplete_list.splice(0, 0, {
                    active: false,
                    display_name: "[Store Does Not Exist]",
                    id: "-1",
                    name: "",
                    lastflag: "1"
                });
            }
            else {
                if (unique_case == 'flexiticket') {
                    incomplete_list.splice(0, 0, {
                        active: false,
                        display_name: "[Store Does Not Exist]",
                        id: "-1",
                        name: "",
                        lastflag: "1"
                    });
                }

                if (unique_case == 'billing') {
                    incomplete_list.splice(0, 0, {
                        active: false,
                        display_name: "[Store Does Not Exist]",
                        id: "-1",
                        name: "",
                        lastflag: "1"
                    });
                }
            }

            if (unique_case == 'cda') {
                incomplete_list.splice(0, 0, {
                    active: false,
                    display_name: "[No Specific Catalog]",
                    id: "-1",
                    name: "",
                    lastflag: "1"
                });
            }
            return incomplete_list;
        }




        // Imports Dropdown Management
        // (Untested update to honor "Performance Improvements")

        $scope.imports_dropdown = {
            'unfiltered_imports_limit': 100,
            'search_result_imports_limit': 100,
            'load_import_flag': false,
            'imports_list': []
        }
        $scope.dropdownImportsOpen = function (isOpen) {
            if (isOpen) {
                $scope.set_imports();
            }
            else {
                $scope.unset_imports();
            }
        }

        $scope.set_imports = function () {
            if ($scope.imports_dropdown.load_import_flag) {
                var effective_user_id = 0;
                if (typeof $scope.analyst_only.effective_user.user_id !== 'undefined') {
                    effective_user_id = $scope.analyst_only.effective_user.user_id;
                }

                // We also need to do the same other entities. Right now only imports is supports
                if ($scope.load_imports) {
                    // If imports are supported- check if they are relevant to the selected form.
                    if (typeof $scope.forms.selected !== 'undefined' && typeof $scope.forms.selected.relevant_entities !== 'undefined') {
                        for (var relevant_entity in $scope.forms.selected.relevant_entities) {
                            if ($scope.forms.selected.relevant_entities[relevant_entity] == 'import_id') {
                                $scope.imports_dropdown.imports_list = $scope.analyst_load_imports(effective_user_id);
                            }
                        }
                    }
                }
            }
            else {
                $scope.imports_dropdown.imports_list = $scope.entities.imports;
            }
        }

        $scope.unset_imports = function () {
            $scope.store_dropdown.stores_list = [];
        }

        // Imports
        $scope.populate_imports = function () {
            $scope.load_imports = true;
            var api_request_flag =
                (typeof $scope.entities.imports_populated == 'undefined' ||
                    $scope.entities.imports_populated == false)
                && (typeof $scope.entities.imports_populating == 'undefined' ||
                    $scope.entities.imports_populating == false);
            if (api_request_flag) {
                $scope.entities.imports_populating = true;

                $scope.entities.imports = $scope.analyst_load_imports();

                $scope.entities.imports_populating = false;
                $scope.entities.imports_populated = true;

                // UNTESTED removing original pull since it is a duplicate of analyst_load_imports
                // $http.get('/api.php/accounts/' + $scope.account_id + '/imports')
                //  .then(function(response) {
                //      if(data.length>100) {
                //          $scope.imports_dropdown.load_import_flag = true;
                //      }
                //      else{
                //          $scope.imports_dropdown.load_import_flag = false;
                //          var data = response.data;
                //          var imports = [];
                //          for (var j = 0; j<data.length;j++) {
                //              imports.push({
                //                  id : data[j].id,
                //                  name : data[j].name,
                //                  db_id: data[j].db_id,
                //                  db_name: data[j].db_name,
                //                  active : data[j].active,
                //                  db_group_id : data[j].db_group_id,
                //                  db_group_name : data[j].db_group_name
                //              });
                //          }
                //          $scope.entities.imports = imports;
                //      }
                //      $scope.entities.imports_populating =false;
                //      $scope.entities.imports_populated = true;
                //  });
            }
        }

        $scope.analyst_load_imports = function (effective_user_id = 0) {

            var effective_user_id = 0;
            var imports_route = '/api.php/accounts/' + $scope.account_id + '/imports';
            var params = {};

            if (typeof $scope.analyst_only.effective_user.user_id !== 'undefined') {
                effective_user_id = $scope.analyst_only.effective_user.user_id;
            }
            if (effective_user_id > 0) {
                imports_route = '/api.php/accounts/' + $scope.account_id + '/analyst_imports';
                params['effective_user_id'] = effective_user_id;
            }

            $http.get(imports_route, { params: params })
                .then(function (response) {

                    var data = response.data;
                    var imports = [];
                    for (var j = 0; j < data.length; j++) {
                        imports.push({
                            id: data[j].id,
                            name: data[j].name,
                            db_id: data[j].db_id,
                            db_name: data[j].db_name,
                            active: data[j].active,
                            db_group_id: data[j].db_group_id,
                            db_group_name: data[j].db_group_name
                        });
                    }

                    if (data.length > 100) {
                        $scope.entities.imports = [];
                        $scope.imports_dropdown.load_import_flag = true;
                    }
                    else {
                        $scope.entities.imports = imports;
                        $scope.entities.import_populated = false;
                    }


                    // Changing the user means its possible the import ID may no longer match. If its not in the list- remove it.
                    var import_access_flag = false;
                    for (var fdx_import in imports) {
                        if (imports[fdx_import].id == $scope.forms.selected.entities.import_id) {
                            import_access_flag = true;
                        }
                    }

                    if (!import_access_flag && $scope.forms.selected.entities.import_id > 0) {
                        // Unsetting the Import ID is insufficient and ambiguous.
                        // $scope.forms.selected.entities.import_id = 0;
                        $scope.clear_all($scope.forms.selected);
                    }

                    return imports;
                });
        }



        $scope.check_first_category = function (accordion) {

            for (var key in $scope.forms.accordions) {
                if ($scope.forms.accordions[key].hide == false) {
                    return $scope.forms.accordions[key].name == accordion.name;

                }
            }
        }

        $scope.adtaxi_custom_info_filled = false;
        $scope.adtaxi_info_complete = function () {
            $scope.adtaxi_custom_info_filled = !!$scope.forms.selected.answers.answer_4 && !!$scope.forms.selected.answers.answer_5;

        }
        $scope.merchant_info_filled = false;
        $scope.merchant_info_complete = function () {
            $scope.merchant_info_filled = !!$scope.forms.selected.answers.answer_9 && !!$scope.forms.selected.answers.answer_10;
        }

        // Create / Update Tickets

        $scope.submit_ticket = async function (completed_form, post_ticket = '', ignore_missing_attachment = false) {

            if (!completed_form.files) {
                completed_form.files = {};
            }

            var params = {};
            var message_body = "Ticket:";
            var answer_number = 1;
            var custom_fields = { //These fields (Except account_id) can be overwritten if a question is provided with the same name as the key
                'account_id': $scope.account_id,
                'Service': completed_form['Service'],
                'Service Detail': completed_form['Service Detail'],
            };
            $scope.feedsupport_ticket_submitting = true;

            var atp_flag = (
                typeof completed_form.extras !== 'undefined' &&
                typeof completed_form.extras.atp_flag !== 'undefined' &&
                completed_form.extras.atp_flag == 'true'
            );

            var qa_index = {};

            for (const [qa_key, qa_value] of Object.entries(completed_form.answers)) {
                if (qa_key.substring(0, 8) == "question" || qa_key.substring(0, 6) == "answer") {
                    var question_number = qa_key.split('_')[1];
                    var question_string = typeof completed_form.answers["question_" + question_number] != "undefined" ? completed_form.answers["question_" + question_number] : "-Question Not Found-";
                    var answer_string = typeof completed_form.answers["answer_" + question_number] != "undefined" ? completed_form.answers["answer_" + question_number] : "";
                    var skip_question = qa_value == "" || qa_value == " " || ((question_string == "-Question Not Found-" || question_string == "") && answer_string == "FeedSupport - Question Omitted");
                    if (skip_question) {
                        continue;
                    }
                    else if ($scope.custom_field_names.includes(question_string)) {
                        // All custom fields must not be an object
                        if (typeof answer_string !== 'object') {
                            custom_fields[question_string] = answer_string;
                        }
                        else {
                            custom_fields[question_string] = "Multiple";
                        }

                    }
                    else if (question_string == "Email cc") {
                        qa_index[question_number] = "<br><STRONG>" + question_string + ":</STRONG> <font color=\"red\">" + answer_string + "</font>";
                    }
                    else {
                        qa_index[question_number] = "<br><STRONG>" + question_string + ":</STRONG> " + answer_string;
                    }
                }

            }

            const qa_ordered = Object.keys(qa_index).sort().reduce(
                (obj, key) => {
                    obj[key] = qa_index[key];
                    return obj;
                },
                {}
            );

            Object.values(qa_ordered).forEach(value => message_body += value);

            // If the total message body length is too large- the ticket will fail to submit.
            if (message_body.length > 65000) {
                let message_body_limit_message =
                    'FeedSupport tickets have a character limit on the entire ticket being created of 65,000 characters. '
                    + 'Things like copying very large lists or including certain inline images can easily surpass this limit. '
                    + 'Try including such details as an attachment instead of in the answers to questions in your ticket.';

                ModalService
                    .showDangerInfoModal(
                        'Message body character limit reached',
                        message_body_limit_message
                    )
                    .then(angular.noop, angular.noop);
                $scope.feedsupport_ticket_submitting = false;
                return false;
            }

            // Keep names unique as we loop through multiple files and loops
            var file_loop_index = 1;
            // If we looped through some logic, let's add those as well
            for (let key in completed_form.loops) {
                for (var i = 0; i < completed_form.loops[key].length; i++) {
                    // Check for a header to each loop.
                    if (typeof completed_form.loops[key][i]["header"] != 'undefined') {
                        message_body += "<br><br>" + completed_form.loops[key][i]["header"];
                    }

                    // Now loop through questions
                    var loop_number = 1;

                    while ("answer_" + loop_number in completed_form.loops[key][i]) {
                        if (completed_form.loops[key][i]["question_" + loop_number] != "") {
                            // Flag certain answers as custom field values
                            if ($scope.custom_field_names.includes(completed_form.loops[key][i]["question_" + loop_number])) {
                                custom_fields[completed_form.loops[key][i]["question_" + loop_number]] = completed_form.loops[key][i]["answer_" + loop_number];
                            }
                            else {
                                message_body += "<br><STRONG>" + completed_form.loops[key][i]["question_" + loop_number] + ":</STRONG> " + completed_form.loops[key][i]["answer_" + loop_number];
                            }

                        }
                        loop_number++;
                    }

                    // Check for a footer for each loop
                    if (typeof completed_form.loops[key][i]["footer"] != 'undefined') {
                        message_body += "<br>" + completed_form.loops[key][i]["footer"];
                    }


                    // Finally check for file attachments
                    if (typeof completed_form.loops[key][i]['files'] != 'undefined') {
                        for (const loop_file_key in completed_form.loops[key][i]['files']) {

                            var filename = "File name not found";
                            if (typeof completed_form.loops[key][i]['files'][loop_file_key]['name'] !== 'undefined') {
                                filename = completed_form.loops[key][i]['files'][loop_file_key]['name'];
                            }
                            else if (typeof completed_form.loops[key][i]['files'][loop_file_key][0] !== 'undefined'
                                && typeof completed_form.loops[key][i]['files'][loop_file_key][0]['name'] !== 'undefined') {
                                filename = completed_form.loops[key][i]['files'][loop_file_key][0]['name'];
                            }
                            message_body += "<br><STRONG>File:</STRONG> " + filename;
                            completed_form.files["file_" + file_loop_index + "_loop_" + loop_file_key] = completed_form.loops[key][i]['files'][loop_file_key];
                            file_loop_index++;
                        }



                        // for (var j = 0; j < completed_form.loops[key][i]['files'].length; j++) {
                        //  message_body+="<br><STRONG>File:</STRONG> "+completed_form.loops[key][i]['files'][j]['name'];

                        //  // Files use an indexed naming structure to ensure they can be sequentially access from front end controls.
                        //  // As a result we have to figure out the last file added and incrementally name this one.
                        //  var k = 1;
                        //  while ("file_"+k in  completed_form.files){
                        //      k++;
                        //  }
                        //  completed_form.files["loop_file_"+k] = completed_form.loops[key][i]['file'][j];
                        // }
                    }

                }

            }

            for (const entities_array_key in completed_form.entities) {

                if (typeof completed_form.entities[entities_array_key] !== 'undefined' && completed_form.entities[entities_array_key].length >= 5000) {
                    let char_limit_message = 'There are character limits for various fields (usually 5000). '
                        + 'Occasionally fields are combined (usually for security purposes). Please reduce the size of some of your responses.';
                    // Credentials pulls from multiple text fields for some forms and so could easily exceed the limit without any individual field exceeding that number
                    if (entities_array_key === 'Credentials') {
                        char_limit_message = 'There are character limits for various fields (usually 5000). '
                            + 'Fields which hold particularly sensitive data are grouped together in a protected field not displayed in most places. '
                            + 'Such fields exceed this character limit when combined and must be shortened.';
                    }

                    ModalService
                        .showDangerInfoModal(
                            'Character Limit Reached',
                            char_limit_message
                        )
                        .then(angular.noop, angular.noop);
                    $scope.feedsupport_ticket_submitting = false;
                    return false;
                }

            }

            for (const file_check_key in completed_form.files) {
                // Check file size NOW that we're about to upload files BEFORE submitting ticket at all
                if (completed_form.files[file_check_key][0]['size'] > 40000000) {
                    ModalService
                        .showDangerInfoModal(
                            "Attachment is too large",
                            "A file you've attached to this ticket is too large. File Limit is 40 MB. Please attach a smaller file and try again."
                        )
                        .then(angular.noop, angular.noop);
                    $scope.feedsupport_ticket_submitting = false;
                    return;
                }

                // Confirm each file object is still defined
                if (typeof completed_form.files[file_check_key][0] === 'undefined' && !ignore_missing_attachment) {

                    ModalService
                        .showDangerConfirmationModal(
                            "File not found",
                            'An attachment you have added to your ticket could not be found. Please validate the file exists and can be accessed, then try submitting your ticket again. If you\'d like you may submit the ticket <strong>without</strong> the attachment.',
                            'Submit Without Attachment',
                            'Cancel',
                            true
                        )
                        .then(
                            () => {
                                // Proceed
                                $scope.submit_ticket(completed_form, post_ticket, true);
                            },
                            () => {
                                // Close
                                $scope.feedsupport_ticket_submitting = false;
                            }
                        );
                    return;

                }

                // Check file still exists and is accessible - we use Fetch to emulate this and try to capture meaningful error data
                var file_exists = true;
                try {
                    var url = URL.createObjectURL(completed_form.files[file_check_key][0]);
                    // No need to do anything with the fetch response. This should fail if the file is inaccessible or missing and throw an exception.
                    var response = await fetch(url);


                    if (typeof response['status'] === 'undefined' || response['status'] !== 200) {
                        file_exists = false;

                        // Temporary but let's email whenever this fails and get more information on why these files aren't accessible.
                        // Existing PHP API message does not include details
                        var error_params = {};
                        error_params["ErrorReason"] = 'Fetch Test Failed - bad response code: ';
                        error_params["ErrorReason"] += response['status'] ?? "Status Undefined";
                        error_params['ticket_id'] = 'N/A (Ticket not created)';
                        error_params["ErrorMessage"] = response;
                        var upload_file_string = $scope.file_stringify(completed_form.files[file_check_key][0]);
                        error_params["LoneFileData"] = upload_file_string;
                        $scope.report_feedsupport_email(error_params);
                    }
                }
                catch (exception) {
                    file_exists = false;

                    // Temporary but let's email whenever this fails and get more information on why these files aren't accessible.
                    // Existing PHP API message does not include details
                    var error_params = {};
                    error_params["ErrorReason"] = 'Fetch Test Failed - try/catch response';
                    error_params['ticket_id'] = 'N/A (Ticket not created)';
                    error_params["ErrorMessage"] = exception;
                    var upload_file_string = $scope.file_stringify(completed_form.files[file_check_key][0]);
                    error_params["LoneFileData"] = upload_file_string;
                    $scope.report_feedsupport_email(error_params);
                }



                if (!file_exists && !ignore_missing_attachment) {

                    ModalService
                        .showDangerConfirmationModal(
                            "File not accessable",
                            'An attachment you have added to your ticket is inaccessible or missing. Please validate the file exists and can be accessed, then try submitting your ticket again. If you\'d like you may submit the ticket <strong>without</strong> the attachment.',
                            'Submit Without Attachment',
                            'Cancel',
                            true
                        )
                        .then(
                            () => {
                                // Proceed
                                $scope.submit_ticket(completed_form, post_ticket, true);
                            },
                            () => {
                                // Close
                                $scope.feedsupport_ticket_submitting = false;
                            }
                        );
                    return;

                }
            }


            if (Object.entries(completed_form.files).length > 1) {
                message_body += "<br><STRONG><font color=\"blue\">Files have been attached</font></STRONG>";
            }
            else if (Object.entries(completed_form.files).length > 0) {
                message_body += "<br><STRONG><font color=\"blue\">File has been attached</font></STRONG>";
            }

            // All relevant entities are custom fields. Treat them as such.
            for (let key in completed_form.relevant_entities) {
                if (completed_form.relevant_entities.hasOwnProperty(key)) {
                    // Custom Fields will not honor html - but all WYSIWYG editors initialize with <p></p> - remove these.
                    answer_string = completed_form.entities[completed_form.relevant_entities[key]];
                    if (typeof answer_string == 'string') {
                        answer_string = answer_string.trim();
                        if (answer_string.startsWith("<p>")) {
                            answer_string = answer_string.substring(3);
                        }
                        if (answer_string.endsWith("</p>")) {
                            answer_string = answer_string.substring(0, answer_string.length - 4);
                        }
                        answer_string = answer_string.trim();
                    }

                    custom_fields[completed_form.relevant_entities[key]] = answer_string;
                }
            }

            // In the event of a New Store - we store the name differently. Override the blank field here.
            if (typeof completed_form.entities['Store Name'] != 'undefined') {
                custom_fields['Store'] = completed_form.entities['Store Name'];
            }

            // Keep files in scope
            var upload_files = completed_form.files;

            // Analyst Users submitting on behalf of another user use a different api route
            var api_route = '/api.php/accounts/' + $scope.account_id + '/zoho/tickets';
            if (typeof $scope.analyst_only.effective_user !== 'undefined' && $scope.analyst_only.effective_user.user_id > 0) {
                api_route = '/api.php/accounts/' + $scope.account_id + '/zoho/analyst_tickets';
                params["effective_user_id"] = $scope.analyst_only.effective_user.user_id;
                custom_fields["Submitted By"] = $scope.user.user_name;
            }

            params["description"] = message_body;
            params["custom_fields"] = custom_fields;
            if (typeof completed_form.entities['priority'] != 'undefined') {
                params["priority"] = completed_form.entities['priority'];
            }
            else {
                params["priority"] = 'Standard';
            }

            if (typeof completed_form['assigneeId'] !== 'undefined' && completed_form['assigneeId'] !== '') {
                params["assigneeId"] = completed_form['assigneeId'];
            }

            if (typeof completed_form['new_store_flag'] !== 'undefined' && completed_form['new_store_flag'] == '1') {
                params["new_store_flag"] = "1";
            }

            if (post_ticket == 'hubspot_export') {
                params["hubspot_export"] = $scope.cancellation_to_hubspot(completed_form);
            }

            if (Object.entries(upload_files).length > 0) {
                params["force_zoho_api"] = "1";
            }

            $http.post(api_route, params)
                .then(function (response) {

                    var data = response.data;

                    if (!$state.current?.data?.newUI) {

                        ModalService
                            .showInfoModal(
                                'Your ticket has been submitted',
                                'FeedSupport team is on it and will get back to you as soon as possible. Thank you!'
                            )
                            .then(angular.noop, angular.noop);

                    }

                    try {
                        $scope.feedsupport_ticket_submitting = false;
                        $scope.feed_support.selected = "";
                        $scope.forms.selected = "";
                        $scope.clear_all(completed_form);
                        delete completed_form.Template;

                        if ($scope.post_ticket_redirect.accordion_name != "") {
                            $scope.force_open_form($scope.post_ticket_redirect.accordion_name, $scope.post_ticket_redirect.title);
                            $scope.forms.selected.entities.db_group_id = $scope.post_ticket_redirect.db_group_id;

                            $scope.post_ticket_redirect.accordion_name = "";
                            $scope.post_ticket_redirect.title = "";
                            $scope.post_ticket_redirect.db_group_id = "";
                        }
                        if (atp_flag) {
                            $scope.selected_template = {};
                            $scope.previous_template = {};
                            $scope.atp_reopen();
                        }

                        var ticket_params = {};
                        ticket_params['ticket_id'] = data.data.zoho_ticket_id;

                        for (var uploaded_file in upload_files) {
                            $scope.submit_file(data.data.zoho_ticket_id, upload_files, uploaded_file, 0);
                        }
                    } catch (e) {
                        var error_params = {};
                        error_params["ErrorReason"] = 'Javascript Try/Catch Failure';
                        error_params['ticket_id'] = data.data.zoho_ticket_id;
                        error_params["Exception"] = e;
                        var upload_files_string = $scope.file_stringify(upload_files);
                        error_params["FileData"] = upload_files_string;
                        $scope.report_feedsupport_email(error_params);
                    }


                    if ($scope.ebay_minimal_ui.flag) {
                        $scope.ebay_minimal_ui_auto_open();
                    }

                    if ($state.current?.data?.newUI) {

                        const ticket_id = data.data.id;
                        const zoho_ticket_number = data.data.zoho_ticket_number;

                        const goParams = {
                            account_id: $scope.account_id,
                            fdxTicketId: ticket_id
                        };

                        if (zoho_ticket_number) {
                            $state.go('app.feedsupport-new/success', {
                                ...goParams,
                                ticketNumber: zoho_ticket_number
                            })

                            return;

                        }

                        $state.go('app.feedsupport-new/success', goParams)

                    }
                    else if ($scope.analyst_role) {
                        $scope.toast_number_in_progress = data['data']['id'] ?? -1;
                        $scope.toast_ticket_number(1, data['data']['id'] ?? -1, data['data']['zoho_ticket_number'] ?? '');
                    }


                }, function (response) {
                    var data = response.data;

                    ModalService
                        .showDangerInfoModal(
                            'There was an error creating your ticket',
                            'Please email portal@feedonomics.com so this can be resolved.'
                        )
                        .then(angular.noop, angular.noop);

                    $scope.feedsupport_ticket_submitting = false;
                    $scope.post_ticket_redirect.accordion_name = "";
                    $scope.post_ticket_redirect.title = "";
                    $scope.post_ticket_redirect.db_group_id = "";

                });
        }

        $scope.submit_file = function (zoho_ticket_id, upload_files, uploaded_file, retry_count) {

            if (retry_count > 4) {
                return;
            }

            var ticket_params = {};
            ticket_params['ticket_id'] = zoho_ticket_id;

            if (zoho_ticket_id <= 0 || isNaN(zoho_ticket_id)) {
                var error_params = {};
                error_params["ErrorReason"] = 'Invalid Ticket ID provided for File Upload';
                error_params['ticket_id'] = zoho_ticket_id;
                error_params["Exception"] = e;
                var upload_files_string = $scope.file_stringify(uploaded_file);
                error_params["LoneFileData"] = upload_files_string;
                $scope.report_feedsupport_email(error_params);
            }

            if (upload_files.hasOwnProperty(uploaded_file) && typeof upload_files[uploaded_file] === 'object') {
                $upload.upload({
                    url: '/api.php/accounts/' + $scope.account_id + '/zoho/file_attachments',
                    method: 'POST',
                    data: ticket_params,
                    file: upload_files[uploaded_file]
                }).then(function (response) {
                    // console.log("Successful File Upload");
                    // console.log(response);
                    // Success case is "done" - really only here to properly capture error case below
                }, function (err) {
                    // It has been requested to show no message to users at this time while we investigate - this one line would do exactly that
                    // fdxUI.showToastError('Attachments failed to upload');
                    // console.log("Failed File Upload");
                    // console.log(err);
                    var error_params = {};
                    error_params["ErrorReason"] = 'File Attachments Upload Loop Failed';
                    error_params['ticket_id'] = zoho_ticket_id;
                    error_params["ErrorMessage"] = err;
                    var upload_files_string = $scope.file_stringify(upload_files);
                    error_params["FileData"] = upload_files_string;
                    $scope.report_feedsupport_email(error_params);
                    var response_code = -1;
                    var valid_response_code_flag = err?.data?.data?.response_code;
                    if (valid_response_code_flag) {
                        response_code = err['data']['data']['response_code'];
                    }
                    // 400 Codes responses will likely always fail - only retry unknown causes - specifically 502
                    if (response_code >= 500) {
                        setTimeout(function () {
                            $scope.submit_file(zoho_ticket_id, upload_files, uploaded_file, ++retry_count);
                        }, 5000);
                    }


                });

            }
            else {
                var error_params = {};
                error_params["ErrorReason"] = 'Uploaded File is missing/not an object';
                error_params['ticket_id'] = zoho_ticket_id;
                error_params["Exception"] = e;
                var upload_files_string = $scope.file_stringify(uploaded_file);
                error_params["LoneFileData"] = upload_files_string;
                $scope.report_feedsupport_email(error_params);
            }
        }

        $scope.report_feedsupport_email = function (error_params) {
            var error_api_route = '/api.php/accounts/' + $scope.account_id + '/report_feedsupport_error';
            $http.post(error_api_route, error_params)
                .then(function (response) {
                    // fdxUI.showToastError('There was an error retrieving fields');
                }, function (error_response) {
                    // console.log("Report FeedSupport Error Failed");
                    // console.log(error_response);
                });
        }

        $scope.toast_ticket_number = function (retries, ticket_id, zoho_ticket_number) {
            if (zoho_ticket_number !== '') {
                var toast_message = ["Ticket #", zoho_ticket_number, " Created"].join('');
                fdxUI.showToastSuccess(toast_message, true, 60000);
                $scope.toast_number_in_progress = -1;
            }
            else if (ticket_id === -1) {
                return;
            }
            else if (ticket_id !== $scope.toast_number_in_progress) {
                return;
            }
            else if (retries > $scope.toast_number_retry_limit) {
                return;
            }
            else {

                $http.get('/api.php/accounts/' + $scope.account_id + '/zoho/fdx_tickets/' + ticket_id)
                    .then(function (response) {
                        var resp = response.data;
                        if (typeof resp['data'] !== 'undefined' && typeof resp['data']['zoho_ticket_number'] !== 'undefined' && resp['data']['zoho_ticket_number'] > 0) {
                            $scope.toast_ticket_number(++retries, ticket_id, resp['data']['zoho_ticket_number']);
                        }
                        else {
                            setTimeout(function () {
                                $scope.toast_ticket_number(++retries, ticket_id, '');
                            }, $scope.toast_number_retry_delay);
                        }

                    });
            }
        }

        $scope.create_store = function (completed_form, store_name, new_feed_flag, wizard_db_id = 0) {

            $scope.feedsupport_ticket_submitting = true;
            var params = { 'name': store_name };
            // If we've decided to use DB Groups- it means we want groups. Override the no_db_value check.
            if ($scope.store_definition == 'db_groups_only') {
                params['force_group'] = 1;
            }

            if (wizard_db_id > 0) {
                params['db_id'] = wizard_db_id;
            }

            if (typeof completed_form.extras.wizard_skipped !== 'undefined' && completed_form.extras.wizard_skipped) {
                params['wizard_skipped'] = 1;
            }

            $http.post('/api.php/accounts/' + $scope.account_id + '/store', params)
                .then(function (response) {
                    var data = response.data;
                    var db_group_id = data.db_group_id;
                    completed_form.entities.db_group_id = db_group_id;
                    completed_form['new_store_flag'] = "1";

                    // Capture platform if they've selected one.
                    if (completed_form.answers.answer_10 !== 'url' && completed_form.answers.answer_10 !== 'ftp' && completed_form.answers.answer_10 !== 'other' && typeof completed_form.answers.answer_10 !== 'undefined') {
                        completed_form.entities['Platform'] = completed_form.answers.answer_10;
                    }

                    if (new_feed_flag) {
                        $scope.post_ticket_redirect.accordion_name = "feeds";
                        $scope.post_ticket_redirect.title = "New Feed";
                        $scope.post_ticket_redirect.db_group_id = db_group_id;
                        completed_form['Service Detail'] = "New Feed";
                    }

                    var effective_user_id = 0;
                    if (typeof $scope.analyst_only.effective_user.user_id !== 'undefined') {
                        effective_user_id = $scope.analyst_only.effective_user.user_id;
                    }
                    $scope.initialize_entities(true, effective_user_id);
                    $scope.submit_ticket(completed_form);

                }, function (response) {
                    ModalService
                        .showDangerInfoModal(
                            'There was an error creating your ticket',
                            'Please email portal@feedonomics.com so this can be resolved.'
                        )
                        .then(angular.noop, angular.noop);

                    $scope.feedsupport_ticket_submitting = false;
                });
        }

        $scope.display_form = function (form, accordion, form_to_show) {
            let update_entities = false;
            let old_unique_case = '';

            if ($scope.forms.selected?.unique_case !== undefined) {
                old_unique_case = $scope.forms.selected.unique_case;
            }

            const new_unique_case = form.unique_case ?? '';

            $scope.feed_support.selected = '';

            if (old_unique_case !== new_unique_case) {
                update_entities = true;
            }
            $scope.forms.selected = form;
            $scope.show_form[accordion] = form_to_show;
            $scope.initialize_entities(update_entities);

            form.answers = {};
            form.files = {};
            form.extras = {};
            form.loops = {};
            form.entities = {};
            form.credentials = {};
            form.password_field_type = "password";
        };

        $scope.force_open_form = function (accordion_name, title) {
            for (var accordion in $scope.forms.accordions) {
                if ($scope.forms.accordions.hasOwnProperty(accordion)) {
                    if (($scope.forms.accordions[accordion].name == accordion_name)) {
                        $scope.forms.accordions[accordion].isOpen = true;

                        for (var button in $scope.forms.accordions[accordion].buttons) {
                            if ($scope.forms.accordions[accordion].buttons.hasOwnProperty(button)) {
                                if ($scope.forms.accordions[accordion].buttons[button].title == title) {
                                    $scope.display_form($scope.forms.accordions[accordion].buttons[button], accordion_name, title);
                                }
                            }
                        }
                    }
                    else {
                        $scope.forms.accordions[accordion].isOpen = false;
                    }
                }
            }
        }

        $scope.auto_open = function (accordion) {
            // Analyst Ticket Portal needs to reset Service and Service Detail upon load.
            if (accordion.name == 'analyst_ticket_portal') {
                accordion.buttons[0]['Service'] = '';
                accordion.buttons[0]['Service Detail'] = '';
                $scope.rand_string = (new Date()).getTime();
            }
            // Automatically open any accordion with only one option.
            if (accordion.buttons.length == 1) {
                $scope.display_form(accordion.buttons[0], accordion.name, accordion.buttons[0].title);
            }

        }



        // Specific Support Forms

        $scope.get_form = function (form) {
            return form + "?p=" + $scope.rand_string;
        }

        $scope.initialize_form = function (form, form_choice) {
            form['Service'] = form_choice['Service'];
            form['Service Detail'] = form_choice['Service Detail'];
            $scope.clear_all(form_choice);

            // We can't clear ALL extras- that includes Group which is selected before this is called.
            // However many forms require feed_support_channel and if this isn't cleared the forms end up broken.
            if (typeof form.extras.feed_support_channel !== 'undefined') {
                delete form.extras.feed_support_channel;
            }
        }

        $scope.set_service_detail = function (form, feed_support_channel, ignore_flag = 0) {
            if (ignore_flag != '1') {
                form['Service'] = feed_support_channel['Service'];
                form['Service Detail'] = feed_support_channel['Service Detail'];
            }
        }

        $scope.onTimeZoneChange = function () {
            // In case this control ends up being used elsewhere - this lets us outline which answer should be populated. It's janky I know.
            if (typeof $scope.forms.selected !== 'undefined'
                && typeof $scope.forms.selected.extras !== 'undefined'
                && typeof $scope.forms.selected.extras.timezone_question !== 'undefined') {
                $scope.forms.selected.answers[$scope.forms.selected.extras.timezone_question] = $scope.timezones.selected_timezone.name;
            }
        }

        $scope.scroll = function (el) {
            var elmnt = document.getElementById(el);
            if (typeof elmnt != "undefined") {
                elmnt.scrollIntoView({
                    behavior: "smooth", block: "center", inline: "start"
                });
            }
        }

        $scope.assign_store = function (form) {
            if (typeof form.entities == 'undefined') {
                form.entities = {};
            }
            // We always want to TRY to capture Group AND DB. Store should be Group- but Database is Group is unavailable
            form.entities.db_group_id = form.extras.store.id;
            form.entities.db_id = form.extras.store.db_id;
            form.entities['cf_db_group_name'] = form.extras.store.name;
            form.entities['Database Name'] = form.extras.store.db_name;
            if (form.extras.store.id != 0) {
                form.entities['Store'] = form.extras.store.name;
            }
            else {
                form.entities['Store'] = form.extras.store.db_name;
            }
        }

        $scope.clear_all = function (form) {
            form.answers = {};
            form.files = {};
            form.extras = {};
            form.loops = {};
            form.entities = {};
            form.credentials = {};
            delete form.assigneeId;
            // This will be slightly more involved when more entities exist to be referenced.
        }

        $scope.clear_above = function (form, answer_number, check_blank = "") {
            if (check_blank == "") {

                for (const [qa_key, qa_value] of Object.entries(form.answers)) {
                    if (qa_key.substring(0, 8) == "question" || qa_key.substring(0, 6) == "answer") {
                        var question_number = qa_key.split('_')[1];
                        if (question_number >= answer_number) {
                            delete form.answers[qa_key];
                        }
                    }
                }
            }
        }

        $scope.clear_above_within_loop = function (loop, answer_number, check_blank = "") {
            if (check_blank == "") {

                for (const [qa_key, qa_value] of Object.entries(loop)) {
                    if (qa_key.substring(0, 8) == "question" || qa_key.substring(0, 6) == "answer") {
                        var question_number = qa_key.split('_')[1];
                        if (question_number >= answer_number) {
                            delete loop[qa_key];
                        }
                    }
                }

            }
        }

        $scope.clear_files = function (form, check_blank = "") {
            if (check_blank == "") {

                form.files = {};
            }
        }

        $scope.clear_files_above = function (form, file_number, check_blank = "") {
            if (check_blank == "") {

                for (const [qa_key, qa_value] of Object.entries(form.files)) {
                    if (qa_key.substring(0, 5) == "file_" && qa_key.split("_").length == 2) {
                        var question_number = qa_key.split('_')[1];
                        if (question_number >= file_number /* was `answer_number` but that parameter is not in the function? */) {
                            delete form.files[qa_key];
                        }
                    }
                }
            }
        }

        $scope.clear_specific_property = function (form, parent_string, property) {
            if (parent_string == "") {
                delete form[property];
            }
            else {
                delete form[parent_string][property];
            }
        }

        $scope.initialize_api_access_form = function (form) {
            form.answers.answer_1 = ''
                + '<u><strong>What emails will need access?:</strong></u><br>'
                + '<ul><li></li></ul><br>'
                + '<u><strong>IP Addresses that will need to be whitelisted?:</strong></u><br>'
                + '<ul><li></li></ul><br>'
                + '<u><strong>Reason for Access?:</strong></u><br>'
                + '<ul><li></li></ul><br>';
        }


        // Loops

        $scope.initialize_loop = function (form, loop_name) {

            if (typeof form.loops == 'undefined') {
                form.loops = {};
            }
            form.loops[loop_name] = [];
            form.loops[loop_name][0] = {};
        }

        $scope.increment_loop = function (form, loop_name, loop_increment) {
            loop_increment++;
            form.loops[loop_name][loop_increment] = {};
        }

        // Clear Functions

        $scope.clear_loops_above = function (form, loop_name, loop_increment, check_blank = "") {
            if (check_blank == "") {
                for (var key in form.loops[loop_name]) {
                    if (key >= loop_increment) {
                        form.loops[loop_name].splice(key, 1);
                    }
                }
            }
        }

        $scope.clear_extras = function (form) {
            form.extras = {};
        }

        // Credentials Functions

        $scope.capture_credentials = function (form, index, question_string, answer_string) {
            if (typeof answer_string == 'undefined') {
                answer_string = '';
            }
            answer_string = answer_string.trim();
            if (answer_string.startsWith("<p>")) {
                answer_string = answer_string.substring(3);
            }
            if (answer_string.endsWith("</p>")) {
                answer_string = answer_string.substring(0, answer_string.length - 4);
            }
            answer_string = answer_string.trim();

            if (answer_string == '') {
                delete form.credentials[index];
            }
            else {
                form.credentials[index] = { "question": question_string, "answer": answer_string };
            }

            var credentials_string = "";

            for (var credential in form.credentials) {
                if (form.credentials.hasOwnProperty(credential)) {
                    credentials_string += form.credentials[credential].question + ": " + form.credentials[credential].answer + "\r\n";

                }
            }
            form.entities['Credentials'] = credentials_string;
        }

        $scope.clear_credentials = function (form) {

            form.credentials = {};
            form.entities['Credentials'] = '';
            var credential_number = 1;

            for (var index in form.extras) {
                if (index.indexOf("credentials_") !== -1) {
                    delete form.extras[index];
                }
            }
        }

        $scope.clear_credentials_above = function (form, credential_number = 0) {
            for (var index in Object.keys(form.credentials)) {
                if (index > credential_number) {
                    delete form.credential[index];
                }
            }

            var credentials_string = "";

            for (var credential in form.credentials) {
                if (form.credentials.hasOwnProperty(credential)) {
                    credentials_string += "<br><STRONG>" + credential.question + ":</STRONG> " + credential.answer;

                }
            }
            form.entities['Credentials'] = credentials_string;
        }

        // Specific Template Data Objects

        $scope.get_fsc_group = function (channel) {
            for (var i = 0; i < $scope.feed_support_channels.length; i++) {
                if ($scope.feed_support_channels[i].value == channel) {
                    return $scope.feed_support_channels[i].group;
                }
            }
            return false;
        }

        $scope.display_product_ticket = function (option_string, product) {
            if (typeof option_string != 'undefined') {
                return option_string.replace("%s", product);
            }
            else {
                return option_string;
            }
        }

        $scope.new_feed_service_detail = function (form, order_integration_flag) {
            if (order_integration_flag == 'yes') {
                form['Service Detail'] = 'Product catalog and order integration';
            }
            else {
                form['Service Detail'] = 'Product catalog integration only';
            }
        }

        $scope.export_channel_service = function (form, export_channel) {
            if (export_channel.group == 'Marketplace') {
                form.extras.non_marketplace_service = form['Service'];
                form['Service'] = 'Marketplace - Support';
            }
            else if (typeof form.extras.non_marketplace_service !== 'undefined') {
                form['Service'] = form.extras.non_marketplace_service;
                delete form.extras.non_marketplace_service;
            }
        }


        // If a single field can be captured multiple times (in a loop or through multi-select) this finds out the appropriate custom field value
        // This defaults to "Multiple" if all instances of the selection are not identical
        $scope.multiple_entity_assignment = function (form, entity, loop_index, channels) {

            if (typeof form.extras.multiple_entity_assignment == 'undefined') {
                form.extras.multiple_entity_assignment = {};
            }
            if (typeof form.extras.multiple_entity_assignment[entity] == 'undefined') {
                form.extras.multiple_entity_assignment[entity] = {};
            }

            var singular_channel_flag = true;

            // Confirm an individual channel isn't an array with multiple elements
            if (typeof channels == 'object') {
                if (Object.keys(channels).length > 1) {
                    singular_channel_flag = false;
                }
                else {
                    channels = channels[0];
                }
            }

            form.extras.multiple_entity_assignment[entity][loop_index] = channels;

            var base_channel = form.extras.multiple_entity_assignment[entity][0];


            // Check if EVERY instance of channel happens to be identical. If not default to "Multiple"
            for (var i = 0; i < Object.keys(form.extras.multiple_entity_assignment[entity]).length; i++) {

                if (form.extras.multiple_entity_assignment[entity][i] != base_channel && typeof form.extras.multiple_entity_assignment[entity][i] != 'undefined') {
                    singular_channel_flag = false;
                }
            }

            if (singular_channel_flag && typeof base_channel == 'string') {
                form.entities[entity] = base_channel;
            }
            else {
                form.entities[entity] = 'Multiple';
            }
        }

        // When navigating multiple export channels we very specifically need to capture if ANY channel is a Marketplace to assign the right service
        // This needs to be reversible since they may de-select ALL marketplace channels as they navigate throughout.
        $scope.multiple_export_channel_service = function (form, loop_index, channels) {

            if (typeof form.extras.multiple_entity_assignment == 'undefined') {
                form.extras.multiple_entity_assignment = {};
            }
            if (typeof form.extras.multiple_entity_assignment['export_channel_group'] == 'undefined') {
                form.extras.multiple_entity_assignment['export_channel_group'] = {};
            }

            var marketplace_flag = false;

            if (typeof channels == 'object') {
                if (Object.keys(channels).length > 1) {

                    for (let i = 0; i < Object.keys(channels).length; i++) {
                        if (channels[i].group == "Marketplace") {
                            marketplace_flag = true;
                        }
                    }
                    if (marketplace_flag == true) {
                        channels = {
                            'group': 'Marketplace'
                        };
                    }
                }
                else {
                    channels = channels[0];
                }
            }

            var channel_group = (typeof channels !== 'undefined' && typeof channels.group !== 'undefined' ? channels.group : '');
            form.extras.multiple_entity_assignment['export_channel_group'][loop_index] = channel_group;

            // Check if EVERY instance of channel happens to be identical. If not default to "Multiple"
            for (let i = 0; i < Object.keys(form.extras.multiple_entity_assignment['export_channel_group']).length; i++) {

                if (form.extras.multiple_entity_assignment['export_channel_group'][i] == "Marketplace") {
                    marketplace_flag = true;
                }
            }

            if (marketplace_flag == true) {
                if (form['Service'] !== 'Marketplace - Support') {
                    form.extras.non_marketplace_service = form['Service'];
                    form['Service'] = 'Marketplace - Support';
                }

            }
            else if (typeof form.extras.non_marketplace_service !== 'undefined') {
                form['Service'] = form.extras.non_marketplace_service;
                delete form.extras.non_marketplace_service;
            }
        }

        // Multiple Entities need to produce the value for a nice clean representation in the portal. Clean it here.
        $scope.get_multiple_entity_array = function (entity_array) {
            var multiple_entity_array = [];

            for (var i = 0; i < Object.keys(entity_array).length; i++) {
                multiple_entity_array.push(entity_array[i].value);
            }
            return multiple_entity_array;
        }

        // Multiple Checkboxes need to produce the value for a nice clean representation in the portal. Clean it here.
        $scope.get_multiple_checkbox_array = function (checkbox_array) {
            var multiple_checkbox_array = [];

            for (var checkbox in checkbox_array) {
                if (checkbox_array[checkbox] !== '') {
                    multiple_checkbox_array.push(checkbox_array[checkbox]);
                }
            }
            return multiple_checkbox_array;
        };

        // HubSpot Cancellations Exports

        // This function specifically controls the navigation on the Cancellation form.
        $scope.cancellation_service_render = function (form) {
            const cancellation_service_array = form.extras.cancellation_services;
            form.answers.answer_5 = $scope.get_multiple_checkbox_array(cancellation_service_array);

            if ($scope.in_array('Marketplaces', cancellation_service_array)) {
                form.answers.question_6 = 'Do you need Feedonomics to zero your inventory?';
                form.answers.question_7 = 'Marketplace Channels';

                if (typeof form.answers.answer_6 === 'undefined') {
                    form.answers.answer_6 = '';
                } else if (form.answers.answer_6 === 'FeedSupport - Question Omitted') {
                    form.answers.answer_6 = '';
                }

                if (typeof form.answers.answer_7 === 'undefined') {
                    form.answers.answer_7 = '[]';
                } else if (typeof form.extras.cancellation_channels !== 'undefined') {
                    const channel_array = form.extras.cancellation_channels;
                    form.answers.answer_7 = $scope.get_multiple_checkbox_array(channel_array);
                } else if (form.answers.answer_7 === 'FeedSupport - Question Omitted') {
                    form.answers.answer_7 = '[]';
                }
            } else {
                form.answers.question_6 = '';
                form.answers.answer_6 = 'FeedSupport - Question Omitted';

                form.answers.question_7 = '';
                form.answers.answer_7 = 'FeedSupport - Question Omitted';
            }


            if ($scope.in_array('PPC Channels', cancellation_service_array)) {
                form.answers.question_8 = 'Which PPC Channels would you like to cancel?';
                if (typeof form.answers.answer_8 === 'undefined') {
                    form.answers.answer_8 = '';
                } else if (form.answers.answer_8 === 'FeedSupport - Question Omitted') {
                    form.answers.answer_8 = '';
                }
            } else {
                form.answers.question_8 = '';
                form.answers.answer_8 = 'FeedSupport - Question Omitted';
            }

            const region_question_flag =
                $scope.in_array('Marketplaces', cancellation_service_array) ||
                $scope.in_array('PPC Channels', cancellation_service_array) ||
                $scope.in_array('Local Inventory Ads', cancellation_service_array) ||
                $scope.in_array('FeedSPI', cancellation_service_array) ||
                $scope.in_array('Dynamic Test Ads', cancellation_service_array);

            if (region_question_flag) {
                form.answers.question_9 = 'Which regions are you canceling?';
                if (typeof form.answers.answer_9 === 'undefined') {
                    form.answers.answer_9 = '';
                } else if (form.answers.answer_9 === 'FeedSupport - Question Omitted') {
                    form.answers.answer_9 = '';
                }
            } else {
                form.answers.question_9 = '';
                form.answers.answer_9 = 'FeedSupport - Question Omitted';
            }

            if ($scope.in_array('Other', cancellation_service_array)) {
                form.answers.question_10 = 'Which Other services would you like to cancel?';
                if (typeof form.answers.answer_10 === 'undefined') {
                    form.answers.answer_10 = '';
                } else if (form.answers.answer_10 === 'FeedSupport - Question Omitted') {
                    form.answers.answer_10 = '';
                }
            } else {
                form.answers.question_10 = '';
                form.answers.answer_10 = 'FeedSupport - Question Omitted';
            }

            if (typeof form.extras.cancellation_reasons !== 'undefined') {
                const cancellation_reason_array = form.extras.cancellation_reasons;
                form.answers.answer_11 = $scope.get_multiple_checkbox_array(cancellation_reason_array);

                if ($scope.in_array('Poor Service by Feedonomics', cancellation_reason_array)) {
                    form.answers.question_12 = 'Please explain why our service did not meet your expectations';
                    if (typeof form.answers.answer_12 === 'undefined') {
                        form.answers.answer_12 = '';
                    } else if (form.answers.answer_12 === 'FeedSupport - Question Omitted') {
                        form.answers.answer_12 = '';
                    }
                } else {
                    form.answers.question_12 = '';
                    form.answers.answer_12 = 'FeedSupport - Question Omitted';
                }

                if ($scope.in_array('Other', cancellation_reason_array)) {
                    form.answers.question_13 = 'Other reasons for canceling';
                    if (typeof form.answers.answer_13 === 'undefined') {
                        form.answers.answer_13 = '';
                    } else if (form.answers.answer_13 === 'FeedSupport - Question Omitted') {
                        form.answers.answer_13 = '';
                    }
                } else {
                    form.answers.question_13 = '';
                    form.answers.answer_13 = 'FeedSupport - Question Omitted';
                }

                if ($scope.in_array('I have another feed solution', cancellation_reason_array)) {
                    form.answers.question_14 = 'Which feed solution are you switching to?';
                    if (typeof form.answers.answer_14 === 'undefined') {
                        form.answers.answer_14 = '';
                    } else if (form.answers.answer_14 === 'FeedSupport - Question Omitted') {
                        form.answers.answer_14 = '';
                    }
                } else {
                    form.answers.question_14 = '';
                    form.answers.answer_14 = 'FeedSupport - Question Omitted';
                }

            }
        };

        $scope.cancellation_to_hubspot = function (form) {

            $scope.feedsupport_ticket_submitting = true;
            const cancel_services = Object.keys(form.answers.answer_5).map((k) => { return form.answers.answer_5[k] }).join(';');
            form.answers.answer_5 = cancel_services;
            const cancel_reasons = Object.keys(form.answers.answer_11).map((k) => { return form.answers.answer_11[k] }).join(';');
            form.answers.answer_11 = cancel_reasons;
            let client = form.entities['Store'];
            if (client === '') {
                client = form.entities['Database Name'];
            }

            var hubspot_export_email = angular.copy($scope.user.user_name);
            if (typeof $scope.analyst_only.effective_user !== 'undefined' && $scope.analyst_only.effective_user.user_id > 0) {
                hubspot_export_email = $scope.analyst_only.effective_user.user_name;
            }

            var params = {
                'client': client,
                'agency': angular.copy(AppStateService.getAccount().account_name),
                'email': hubspot_export_email,
                //'ticket_':ticket_id,
                'is_your_company_an_agency_': form.answers.answer_1,
                'which_services_would_you_like_to_cancel': cancel_services,
                'reason_for_canceling': cancel_reasons,
                'last_day_to_run': form.answers.answer_15,
                'how_likely_are_you_to_recommend_feedonomics_': form.answers.answer_16
            };

            // Conditional Answers
            if (form.answers.question_2 !== '') {
                params['client_name'] = form.answers.answer_2;
            }
            if (form.answers.question_3 !== '') {
                params['client_email'] = form.answers.answer_3;
            }
            if (form.answers.question_4 !== '') {
                params['client_phone'] = form.answers.answer_4;
            }
            if (form.answers.question_7 !== '') {
                const cancel_channels = Object.keys(form.answers.answer_7).map((k) => { return form.answers.answer_7[k] }).join(';');
                params['marketplace_channels'] = cancel_channels;
                form.answers.answer_7 = cancel_channels;
            }
            if (form.answers.question_8 !== '') {
                params['ppc_channels_to_cancel'] = form.answers.answer_8;
            }
            if (form.answers.question_9 !== '') {
                params['regions_to_cancel'] = form.answers.answer_9;
            }
            if (form.answers.question_10 !== '') {
                params['which_other_services_would_you_like_to_cancel_'] = form.answers.answer_10;
            }
            if (form.answers.question_12 !== '') {
                params['please_explain'] = form.answers.answer_12;
            }

            if (form.answers.question_13 !== '') {
                let answer_string_13 = form.answers.answer_13.trim();
                if (answer_string_13.startsWith("<p>")) {
                    answer_string_13 = answer_string_13.substring(3);
                }
                if (answer_string_13.endsWith("</p>")) {
                    answer_string_13 = answer_string_13.substring(0, answer_string_13.length - 4);
                }
                answer_string_13 = answer_string_13.trim();
                params['reason_for_cancelling'] = answer_string_13;
            }
            if (form.answers.question_14 !== '') {
                let answer_string_14 = form.answers.answer_14.trim();
                if (answer_string_14.startsWith('<p>')) {
                    answer_string_14 = answer_string_14.substring(3);
                }
                if (answer_string_14.endsWith('</p>')) {
                    answer_string_14 = answer_string_14.substring(0, answer_string_14.length - 4);
                }
                answer_string_14 = answer_string_14.trim();
                params['which_feed_solution_are_you_switching_to_'] = answer_string_14;
            }

            // $http.post('/api.php/accounts/'+$scope.account_id+'/cancellation_to_hubspot', params)
            //  .then(function(response) {
            //      // var data = response.data;
            //      //$scope.submit_ticket(form);

            //  }, function(response) {
            //      // On failure- do not alert user. Simply create ticket- email should have been sent to hubspot_export_alert@feedonomics.com
            //      //$scope.submit_ticket(form);
            //  });

            return params;
        }


        // Analyst View Functions
        $scope.load_users = function () {
            // We check twice on page load for initial load to avoid race condition. Let's not run this twice
            if (!$scope.analyst_only.loading_users) {
                $scope.analyst_only.loading_users = true;
                $http.get('/api.php/accounts/' + $scope.account_id + '/analyst_users')
                    .then(function (response) {
                        var user_list = response.data;
                        $scope.analyst_only.users = [];
                        $scope.analystPlaceholder = $state.current?.data?.newUI ? 'select contact' : 'Select Client Contact';

                        $scope.analyst_only.users.push(
                            {
                                'user_id': $scope.user.user_id,
                                'user_name': $scope.user.user_name,
                                'first_name': $scope.user.first_name,
                                'last_name': $scope.user.last_name,
                                'account_access_flag': '0',
                                'role': $scope.user.role
                            }
                        );

                        for (const user in user_list) {
                            // Don't put the currently logged in analyst twice in case they happen to have been issued permissions - usually on dev accounts but still it's sloppy
                            if (typeof user_list[user].zoho_contact_id && user_list[user].zoho_contact_id > 0 && user_list[user].user_name !== $scope.user.user_name) {
                                $scope.analyst_only.users.push(user_list[user]);
                            }
                        }

                        if ($state.current?.data?.newUI && FeedSupportStateServiceNew.effectiveUser) {
                            $scope.analyst_only.effective_user = FeedSupportStateServiceNew.effectiveUser;
                        } else {
                            $scope.analyst_only.effective_user = $scope.analyst_only.users[0];
                        }

                        $scope.analyst_only.loading_users = false;
                    });
            }
        }

        // ebay Minimal UI

        $scope.ebay_minimal_ui_auto_open = function () {
            $scope.forms.selected = angular.copy($scope.ebay_minimal_ui.selected);
            $scope.initialize_entities();

            $scope.forms.selected.answers = {};
            $scope.forms.selected.files = {};
            $scope.forms.selected.extras = {};
            $scope.forms.selected.loops = {};
            $scope.forms.selected.entities = {};
            $scope.forms.selected.credentials = {};
            $scope.forms.selected.password_field_type = "password";


            if ($scope.entities.db_groups.length == 1) {
                $scope.forms.selected.answers.question_1 = 'Please write in detail how we can be of assistance';
                $scope.forms.selected.extras.store = $scope.entities.db_groups[0];
                $scope.assign_store($scope.forms.selected);
            }
        }

        // analyst ticket portal

        $scope.atp_get_fields = function () {

            $http.get('/api.php/zoho/fields', []).then(function (response) {

                var mappings = response.data.data.dependency_mappings;
                var mapping_keys = Object.keys(response.data.data.dependency_mappings.mappings);
                if (typeof mappings.mappings !== 'undefined') {
                    $scope.analyst_ticket_portal.mappings = mappings.mappings;
                    $scope.analyst_ticket_portal.services = [];
                    for (let key in mapping_keys) {
                        $scope.analyst_ticket_portal.services.push({ 'value': mapping_keys[key], 'display_name': mapping_keys[key] });
                    }
                }

                var fields = response.data.data.organization_fields;
                var field_keys = Object.keys(fields);
                for (var field in field_keys) {
                    var field_name = field_keys[field];

                    $scope.analyst_ticket_portal[field_name] = [];
                    for (let key in fields[field_name]) {
                        $scope.analyst_ticket_portal[field_name].push({ 'value': fields[field_name][key]['value'], 'display_name': fields[field_name][key]['value'] });
                    }
                }

            }, function (response) {
                //var data = response.data;
                fdxUI.showToastError('There was an error retrieving fields');
            });
        }

        $scope.atp_get_agents = function () {

            $http.get('/api.php/zoho/agents').then(function (response) {

                $scope.analyst_ticket_portal.agents.push({
                    'agent_status': "ACTIVE",
                    'email': "",
                    'first_name': "",
                    'last_name': "",
                    'name': "",
                    'zoho_agent_id': ""
                });
                for (let agent in response.data.data) {
                    $scope.analyst_ticket_portal.agents.push(response.data.data[agent]);
                }

            }, function (response) {
                //var data = response.data;
                fdxUI.showToastError('There was an error retrieving agents');
            });
        }

        $scope.clear_agent = function (form, asignee_id = '') {
            if (asignee_id == '') {
                delete form['asigneeId'];
                delete form.extras.zoho_agent;
            }
        }

        $scope.atp_populate_service_detail = function (service) {

            for (const key1 in $scope.analyst_ticket_portal.mappings) {
                if (key1 == service) {
                    $scope.analyst_ticket_portal.service_details = [];
                    const keys = $scope.analyst_ticket_portal.mappings[key1];
                    for (const key2 in keys) {
                        $scope.analyst_ticket_portal.service_details.push({ 'value': keys[key2], 'display_name': keys[key2] });
                    }
                }
            }
        }

        $scope.atp_initialize = function () {
            if (!$scope.analyst_ticket_portal.initialized && typeof $scope.forms !== 'undefined' && typeof $scope.forms.accordions !== 'undefined') {
                $scope.analyst_ticket_portal.initialized = true;
                $scope.atp_get_fields();
                $scope.atp_get_agents();
                for (var j = 0; j < $scope.forms.accordions.length; j++) {
                    if ($scope.forms.accordions[j].name == 'analyst_ticket_portal') {
                        $scope.forms.accordions[j].hide = false;
                        $scope.forms.selected = $scope.forms.accordions[j];
                        $scope.auto_open($scope.forms.accordions[j]);
                    }
                }

            }
        }

        $scope.atp_reopen = function () {
            $scope.rand_string = (new Date()).getTime();
            for (var j = 0; j < $scope.forms.accordions.length; j++) {
                if ($scope.forms.accordions[j].name == 'analyst_ticket_portal') {
                    // $scope.forms.selected = $scope.forms.accordions[j];
                    $scope.forms.accordions[j].buttons[0]['Service'] = '';
                    $scope.forms.accordions[j].buttons[0]['Service Detail'] = '';
                    $scope.auto_open($scope.forms.accordions[j]);
                    $scope.analyst_ticket_portal.change_flag = false;
                }
            }
        }

        $scope.atp_store_clear = function (form, service) {
            if (service == 'Add a Store') {
                delete form.extra.store;
            }
            else {
                delete form.entities['Store Name'];
                if (typeof form.extra !== 'undefined' && typeof form.extra.store !== 'undefined') {
                    $scope.assign_store(form);
                }
            }
        }

        $scope.atp_check_numeric = function (value) {
            if (typeof value === 'undefined' || value == '') {
                return false;
            }
            return !(/^\d+$/.test(value));
        }

        $scope.atp_failed_hs_deal_id = function (error_message) {
            fdxUI.showToastError(error_message);
        }

        // Templates
        $scope.apply_template = function (form, template) {
            $scope.clear_all(form);

            if (typeof template['Service'] !== 'undefined') {
                form['Service'] = template['Service'];
                $scope.atp_populate_service_detail(form['Service']);
            }
            else {
                delete form['Service'];
            }

            var template_variables = ['Service Detail', 'Status', 'Priority', 'Channel']
            for (let key in template_variables) {
                if (typeof template[template_variables[key]] !== 'undefined') {
                    form[template_variables[key]] = template[template_variables[key]];
                }
                else {
                    delete form[template_variables[key]];
                }
            }

            if (typeof form.entities == 'undefined') {
                form.entities = {};
            }

            var template_entities = ['Marketing Channel', 'Marketing Channel Country', 'Feed Language', 'Platform', 'Billing Premise', 'Recurring Fee Amount - Monthly', 'One-time Setup Fee Amount', 'First month prorated', 'Billing Note', 'Contract length - months', 'Office']
            for (let key in template_entities) {
                if (typeof template[template_entities[key]] !== 'undefined') {
                    form.entities[template_entities[key]] = template[template_entities[key]];
                }
                else {
                    form.entities[template_entities[key]] = '';
                }
            }


            if (typeof form.answers == 'undefined') {
                form.answers = {};
            }

            if (typeof template['Description'] !== 'undefined') {
                form.answers['question_1'] = 'Description';
                form.answers['answer_1'] = template['Description'];
            }
            else {
                delete form.answers['answer_1'];
            }


            if (typeof form.extras == 'undefined') {
                form.extras = {};
            }
            form.extras.atp_flag = "true";

            $scope.previous_template = template;
            $scope.analyst_ticket_portal.change_flag = false;
        }

        $scope.apply_template_modal = function (form, template) {
            $scope.previous_template = angular.copy($scope.selected_template);
            $scope.selected_template = angular.copy(template);
            if ($scope.analyst_ticket_portal.change_flag) {


                ModalService
                    .showConfirmationModal(
                        'Template Change',
                        'If you have previously entered any data in the form, it will be removed ',
                        'Proceed'
                    )
                    .then(
                        () => {
                            $scope.apply_template($scope.forms.selected, $scope.selected_template);
                        },
                        () => {
                            $scope.reset_template();
                        }
                    );
            }
            else {
                $scope.apply_template(form, template);
            }
        }

        $scope.reset_template = function () {
            delete $scope.forms.selected.Template;
            $scope.forms.selected.Template = angular.copy($scope.previous_template);
            $scope.selected_template = angular.copy($scope.previous_template);
        }

        $scope.set_template_flag = function (value) {
            if (value === false) {
                $scope.analyst_ticket_portal.change_flag = false;
            }
            else {
                if (typeof value == 'undefined') {
                    value = "";
                }
                if (value === true) {
                    value = "true";
                }
                // wysiwyg has strange "default" values but gets flagged by ng-change - this checks for it first.
                var clean_wysiwyg = value.replace("<p>", "").replace("</p>", "").replace("undefined", "").replace("<br>", "");

                if (value === "true" || clean_wysiwyg != "") {
                    $scope.analyst_ticket_portal.change_flag = true;
                }
            }
        }


        // Helper Functions

        $scope.checkJSON = function (string) {
            try {
                JSON.parse(string);
            } catch (e) {
                return false;
            }
            return true;
        }

        $scope.in_array = function (needle, haystack) {
            if (typeof haystack !== 'object') {
                return false;
            }
            return Object.values(haystack).indexOf(needle) !== -1;
        }

        $scope.check_file_exists = function (file) {
            if (file) {
                return true;
            }
            return false;
        }




        // Data Objects / Lists

        $scope.forms = {
            'selected': undefined,
            'accordions': [

                {
                    'name': 'vivino',
                    'display_name': 'FeedSupport',
                    'tooltip': 'A place to onboard new stores and submit support inquiries',
                    'logo': '/app/feedsupport/forms/img/vivino.png',
                    'hide': true,
                    'buttons': [
                        {
                            'name': 'vivino-add-store',
                            'title': 'Add a Store',
                            'description': 'Add a new store to Feedonomics',
                            'glyphicon': 'glyphicon-shopping-cart',
                            'Service': 'Add a Store',
                            'Service Detail': 'Add a Store',
                            'Hover Text': 'Add a new store to Feedonomics',
                            'display_template': '/app/feedsupport/forms/add_store.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials', 'Platform'],
                            'custom_store_values': ['merchantid', 'skip_shopify_wizard', 'merchant_contact_info'],
                            'prompt_new_feed': false

                        },
                        {
                            'name': 'vivino-general-support',
                            'title': 'Support',
                            'description': 'General support inquiry',
                            'glyphicon': 'glyphicon-question-sign',
                            'Service': 'General or Maintenance',
                            'Service Detail': 'General',
                            'Hover Text': 'General support inquiry',
                            'display_template': '/app/feedsupport/forms/default.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                            'custom_store_values': ['merchantid'],
                        },
                    ]
                },

                {
                    'name': 'custom_data_aggregator',
                    'display_name': 'Data Aggregator',
                    'tooltip': 'A place to onboard new catalogs and submit support inquiries',
                    'hide': true,
                    'buttons': [
                        {
                            'name': 'custom-data-aggregator-add-a-catalog',
                            'title': 'Add a Catalog',
                            'description': 'Add a new catalog for import and export',
                            'glyphicon': 'glyphicon-plus',
                            'Service': 'Add a Store',
                            'Service Detail': 'Add a Store',
                            'Hover Text': 'Add a new store to Feedonomics',
                            'display_template': '/app/feedsupport/forms/add_store.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials', 'Platform'],
                            'custom_store_values': ['catalog'],
                            'prompt_new_feed': false,
                            'invisible': false,

                        },
                        {
                            'name': 'custom-data-aggregator-add-a-new-export',
                            'title': 'Add a New Export',
                            'description': 'Add a new export to an existing catalog',
                            'glyphicon': 'glyphicon-plus',
                            'Service': 'Add Export - PPC Channel',
                            'Service Detail': 'Comparison Shopping Engine',
                            'Hover Text': 'Add a new export to an existing catalog',
                            'display_template': '/app/feedsupport/forms/cda_add_export.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials'],
                            'invisible': false,

                        },
                        {
                            'name': 'custom-data-aggregator-cancel-catalog',
                            'title': 'Cancel Catalog',
                            'description': 'Cancel a catalog to halt all imports & exports',
                            'glyphicon': 'glyphicon-remove',
                            'Service': 'Cancel Store',
                            'Service Detail': 'Cancel Store',
                            'Hover Text': 'Cancel an existing catalog to halt all imports and exports',
                            'display_template': '/app/feedsupport/forms/cda_cancellation.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country'],
                            'invisible': false,

                        },
                        {
                            'name': 'custom-data-aggregator-pause-unpause-an-export',
                            'title': 'Pause/Unpause an Export',
                            'description': 'Pause or unpause a specific export',
                            'glyphicon': 'glyphicon-pause',
                            'Service': 'Pause a Store',
                            'Service Detail': 'Pause a Store',
                            'Hover Text': 'Pause or unpause a specific export',
                            'display_template': '/app/feedsupport/forms/cda_pause_export.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country'],
                            'invisible': false,

                        },
                        {
                            'name': 'custom-data-aggregator-maintenance-support',
                            'title': 'Maintenance & Support',
                            'description': 'Inquiries, troubleshooting or adjusting feeds',
                            'glyphicon': 'glyphicon-question-sign',
                            'Service': 'Feed Support',
                            'Service Detail': 'Feed Support',
                            'Hover Text': 'Inquiries, troubleshooting or adjusting feeds',
                            'display_template': '/app/feedsupport/forms/cda_maintenance.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Marketing Channel', 'Marketing Channel Country', 'Feed Language'],
                            'unique_case': 'cda',
                            'invisible': false,
                            'dropdowns': {
                                'product_attributes': [
                                    { 'value': 'ID' },
                                    { 'value': 'SKU' },
                                    { 'value': 'MP' },
                                    { 'value': 'GTIN' },
                                    { 'value': 'Item Group ID' },
                                    { 'value': 'Product Type' },
                                    { 'value': 'Brand' },
                                    { 'value': 'Custom Label' },
                                    { 'value': 'Other' }
                                ]
                            }

                        },
                    ]
                },
                {
                    'name': 'stores',
                    'display_name': 'Stores',
                    'tooltip': 'Stores are your eCommerce website like YourStore.com. A store typically represents a single eCommerce client',
                    'hide': false,
                    'buttons': [
                        {
                            'name': 'stores-add-store',
                            'title': 'Add a Store',
                            'description': 'Add a new store to Feedonomics',
                            'glyphicon': 'glyphicon-shopping-cart',
                            'Service': 'Add a Store',
                            'Service Detail': 'Add a Store',
                            'Hover Text': 'Add a new store to Feedonomics',
                            'display_template': '/app/feedsupport/forms/add_store.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials', 'Platform'],
                            'prompt_new_feed': true

                        },
                        {
                            'name': 'stores-pause-store',
                            'title': 'Pause a Store',
                            'description': 'Pause an existing store',
                            'glyphicon': 'glyphicon-pause',
                            'Service': 'Pause a Store',
                            'Service Detail': 'Pause a Store',
                            'Hover Text': 'Pause an existing store',
                            'display_template': '/app/feedsupport/forms/pause_store.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country']

                        },
                        {
                            'name': 'stores-unpause-store',
                            'title': 'Unpause Store',
                            'description': 'Resume a previously paused store',
                            'glyphicon': 'glyphicon-play',
                            'Service': 'Reactivate a Store',
                            'Service Detail': 'Reactivate a Store',
                            'Hover Text': 'Resume a previously paused store',
                            'display_template': '/app/feedsupport/forms/unpause_store.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country']

                        },
                        {
                            'name': 'stores-initiate-cancellation',
                            'title': 'Initiate Cancellation',
                            'description': 'Cancel an existing store',
                            'glyphicon': 'glyphicon-remove',
                            'Service': 'Cancel Store',
                            'Service Detail': 'Cancel Store',
                            'Hover Text': 'Cancel an existing store',
                            'display_template': '/app/feedsupport/forms/cancellation.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country']

                        },
                    ]
                },
                {
                    'name': 'feeds',
                    'display_name': 'Feeds',
                    'tooltip': 'Feeds are export channels like Google Shopping, Amazon, and Facebook that we send your data to.',
                    'hide': false,
                    'buttons': [
                        {
                            'name': 'feeds-new-feed',
                            'title': 'New Feed',
                            'description': 'Add a new feed to Feedonomics',
                            'glyphicon': 'glyphicon-plus',
                            'Service': 'New Feed',
                            'Service Detail': 'New Feed',
                            'Hover Text': 'Add a new export channel.',
                            'display_template': '/app/feedsupport/forms/add_feed.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Credentials']

                        },
                        {
                            'name': 'feeds-pause-feed',
                            'title': 'Pause Feed',
                            'description': 'Pause a specific feed for your store',
                            'glyphicon': 'glyphicon-pause',
                            'Service': 'Pause Feed',
                            'Service Detail': 'Pause Feed',
                            'Hover Text': 'Pause a specific feed',
                            'display_template': '/app/feedsupport/forms/pause_feed.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country']

                        },
                        {
                            'name': 'feeds-unpause-feed',
                            'title': 'Unpause Feed',
                            'description': 'Resume a previously paused feed',
                            'glyphicon': 'glyphicon-play',
                            'Service': 'Reactivate a Feed',
                            'Service Detail': 'Reactivate a Feed',
                            'Hover Text': 'Resume a previously paused feed',
                            'display_template': '/app/feedsupport/forms/unpause_feed.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country']

                        },
                        {
                            'name': 'feeds-feed-support',
                            'title': 'Feed Support',
                            'description': 'Set up Promotions, Custom Labels, and more.',
                            'glyphicon': 'glyphicon-question-sign',
                            'Service': 'Feed Support',
                            'Service Detail': 'Feed Support',
                            'Hover Text': 'Set up Promotions, Custom Labels, and more.',
                            'display_template': '/app/feedsupport/forms/feed_support.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Marketing Channel', 'Marketing Channel Country', 'Feed Language'],
                            'dropdowns': {
                                'product_attributes': [
                                    { 'value': 'ID' },
                                    { 'value': 'SKU' },
                                    { 'value': 'MP' },
                                    { 'value': 'GTIN' },
                                    { 'value': 'Item Group ID' },
                                    { 'value': 'Product Type' },
                                    { 'value': 'Brand' },
                                    { 'value': 'Custom Label' },
                                    { 'value': 'Other' }
                                ],
                                'options': [
                                    { 'value': 'Add new custom label values in addition to current labels present' },
                                    { 'value': 'Replace all previous values' },
                                    { 'value': 'Other (please explain below)' }
                                ]
                            }

                        }
                    ]
                },
                {
                    'name': 'products',
                    'display_name': 'Additional Services',
                    'tooltip': 'Additional Products including Image Padding and Dynamic Image Overlay', //
                    'hide': false,
                    'buttons': [
                        {
                            'name': 'products-image-padding',
                            'title': 'Image Padding',
                            'description': 'Add padding around your images.',
                            //'glyphicon':'glyphicon-font',
                            'image': '/app/feedsupport/forms/img/imagepadding.png',
                            'Service': 'Image Padding',
                            'Service Detail': 'Image Padding',
                            'Hover Text': 'Add padding around your images.',
                            'display_template': '/app/feedsupport/forms/product_alt.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',]

                        },
                        {
                            'name': 'products-dynamic-image-overlays',
                            'title': 'Dynamic Image Overlays',
                            'description': 'Add a dynamic image overlay to your image.',
                            //'glyphicon':'glyphicon-picture',
                            'image': '/app/feedsupport/forms/img/imageoverlay.png',
                            'Service': 'Dynamic Image Overlays',
                            'Service Detail': 'Dynamic Image Overlays',
                            'Hover Text': 'Add a dynamic image overlay to your image.',
                            'display_template': '/app/feedsupport/forms/product_alt.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',]

                        },
                    ]
                },
                {
                    'name': 'users',
                    'display_name': 'Users',
                    'tooltip': 'Manage user access and permissions',
                    'hide': false,
                    'buttons': [
                        {
                            'name': 'users-add-new-user',
                            'title': 'Add New User',
                            'description': 'Add a new user to your account',
                            'glyphicon': 'glyphicon-plus',
                            'Service': 'Add New User',
                            'Service Detail': 'Add New User',
                            'Hover Text': 'Add a new user to your account',
                            'display_template': '/app/feedsupport/forms/add_new_user.html',
                            'relevant_entities': []

                        },
                        {
                            'name': 'users-delete-user',
                            'title': 'Delete User',
                            'description': 'Delete a user from your account.',
                            'glyphicon': 'glyphicon-remove',
                            'Service': 'Delete User',
                            'Service Detail': 'Delete User',
                            'Hover Text': 'Delete a user from your account.',
                            'display_template': '/app/feedsupport/forms/delete_user.html',
                            'relevant_entities': []

                        },
                        {
                            'name': 'users-manage-permissions',
                            'title': 'Manage Permissions',
                            'description': 'Find out how to manage user permissions.',
                            'glyphicon': 'glyphicon-lock',
                            'Service': 'Managing User Permissions',
                            'Service Detail': 'Managing User Permissions',
                            'Hover Text': 'Find out how to manage user permissions.',
                            'display_template': '/app/feedsupport/forms/manage_permissions.html',
                            'relevant_entities': []

                        },
                    ]
                },
                {
                    'name': 'billing',
                    'display_name': 'Billing Inquiries',
                    'tooltip': 'Manage all your billing inquiries here',
                    'hide': false,
                    'buttons': [
                        {
                            'name': 'billing-billing-question',
                            'title': 'Billing Question',
                            'description': 'General Billing Inquiry',
                            'glyphicon': 'glyphicon-usd',
                            'Service': 'Billing Question',
                            'Service Detail': 'Billing Inquiry',
                            'Hover Text': 'General Billing Inquiry',
                            'display_template': '/app/feedsupport/forms/billing_default.html',
                            'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name'],
                            'unique_case': 'billing',
                            'hidden': true

                        },
                    ]
                },
                {
                    'name': 'data_aggregator',
                    'display_name': 'Data Aggregator',
                    'tooltip': 'Data aggregator services are services where we take multiple inputs, normalize, and send to a single data platform',
                    'hide': true,
                    'buttons': [
                        {
                            'name': 'data-aggregator-question',
                            'title': 'Data Aggregator Question',
                            'description': 'Data Aggregator Inquiry',
                            'glyphicon': 'glyphicon-usd',
                            'Service': 'Data Aggregator',
                            'Service Detail': 'Data Aggregator',
                            'Hover Text': 'General Data Aggregator Inquiry',
                            'display_template': '/app/feedsupport/forms/data_aggregator.html',
                            'relevant_entities': ['import_id', 'Credentials', 'db_group_id', 'Store', 'db_id', 'Database Name', 'Feed Language', 'Marketing Channel', 'Marketing Channel Country', 'Platform'],
                            'hidden': true

                        },
                    ]
                },
                {
                    'name': 'analyst_ticket_portal',
                    'display_name': 'Feedonomics FlexiTicket',
                    'tooltip': 'Analyst only form to enter tickets directly - manually selecting Service and Service Detail.',
                    'hide': true,
                    'buttons': [
                        {
                            'name': 'analyst-ticket-portal-provide-details',
                            'title': 'Provide Details',
                            'description': 'Analyst Only Ticket Entry',
                            'glyphicon': 'glyphicon-question-sign',
                            'Service': '',
                            'Service Detail': '',
                            'Hover Text': 'Analyst Only Ticket Entry',
                            'display_template': '/app/feedsupport/forms/analyst_ticket_portal.html',
                            'relevant_entities': [
                                'Store',
                                'db_group_id',
                                'db_id',
                                'Database Name',
                                'Feed Language',
                                'Marketing Channel',
                                'Marketing Channel Country',
                                'Credentials',
                                'Platform',
                                'Billing Premise', //'cf_billing_premise',
                                'Recurring Fee Amount - Monthly', //'cf_amount', //'cf_recurring_fee_amount_-_monthly',
                                'One-time Setup Fee Amount', //'cf_setup_fee', //'cf_one-time_setup_fee_amount',
                                'First month prorated', //'cf_first_month_pro_rated', //'cf_first_month_prorated',
                                'Billing Notes', //'cf_billing_notes',
                                'Contract length - months', //'cf_contract_length_months', //'cf_contract_length_-_months',
                                'Bill From', //'cf_bill_from',
                                'HS Deal ID', //'cf_hs_deal_id'
                                'assigneeId',
                                'Office', //cf_office

                            ],
                            'unique_case': 'flexiticket',
                            'hidden': true
                        },
                    ]
                },
            ]
        };

        $scope.feed_support = {
            'selected': undefined,
            'options': [
                {
                    'name': 'Google/Bing Promotions',
                    'value': 'promotions',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Promotions',
                    'display_template': '/app/feedsupport/forms/promotions.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Manage your Google and Bing Promotions'
                },
                {
                    'name': 'Add/Exclude Products',
                    'value': 'add_exclude_products',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Add/Exclude Products',
                    'display_template': '/app/feedsupport/forms/add_exclude_products.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Exclude or re-include products in your feeds exports'
                },
                {
                    'name': 'Custom Labels',
                    'value': 'custom_labels',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Custom Label',
                    'display_template': '/app/feedsupport/forms/custom_label.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Manage your custom labels'
                },
                {
                    'name': 'Export Troubleshooting',
                    'value': 'export_troubleshooting',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Export Troubleshooting',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Having export issues, let us help'
                },
                {
                    'name': 'Dynamic Text Ads - Support',
                    'value': 'dynamic_text_ads_support',
                    'grouping': 'Troubleshooting',
                    'Service': 'Dynamic Text Ads',
                    'Service Detail': 'Dynamic Text Ad - Support',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Support for dynamic text ads'
                },
                {
                    'name': 'Feed Audit - Request',
                    'value': 'feed_audit_request',
                    'grouping': 'General',
                    'Service': 'Feed Audit',
                    'Service Detail': 'Feed Audit - Request',
                    'display_template': '/app/feedsupport/forms/feed_support_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Request a review of your feed'
                },
                {
                    'name': 'A/B Testing',
                    'value': 'a_b_testing',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'A/B Testing',
                    'display_template': '/app/feedsupport/forms/feed_support_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Split test products in your feed'
                },
                {
                    'name': 'Account Suspension',
                    'value': 'account_suspension',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Acc Suspension',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Need help with an account suspension?'
                },
                {
                    'name': 'Channel Specific Errors',
                    'value': 'channel_specific_errors',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Channel Specific Errors',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Need help with errors on a specific channel?'
                },
                {
                    'name': 'Data Governance',
                    'value': 'data_governance',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Data Governance',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Data Governance are the rules that protect your exports'
                },
                {
                    'name': 'GTINs Related Issues',
                    'value': 'gtins_related_issues',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'GTINs Related Issues',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Report errors around GTIN\'s'
                },
                {
                    'name': 'ID Tracking Issues',
                    'value': 'id_tracking_issues',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'ID Tracking Issues',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'UTM or other product id tracking issues'
                },
                {
                    'name': 'Import Change',
                    'value': 'import_change',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Import Change',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Need to change your import configuration?'
                },
                {
                    'name': 'Import Troubleshooting',
                    'value': 'import_troubleshooting',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Import Troubleshooting',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Having import issues, let us help'
                },
                {
                    'name': 'Inquiry',
                    'value': 'inquiry',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Inquiry',
                    'display_template': '/app/feedsupport/forms/feed_support_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'General question unrelated to any of these topics'
                },
                {
                    'name': 'Link or Image Issues',
                    'value': 'link_or_image_issues',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Link or Image Issues',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Report any link or image link issues'
                },
                {
                    'name': 'Populate Missing Or Update Categories',
                    'value': 'populate_missing_or_update_categories',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Populate Missing Or Update Categories',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Get support on categorization issues'
                },
                {
                    'name': 'Price/Availability Errors',
                    'value': 'price_availability_errors',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Price/Availability Errors',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Report price or availability errors'
                },
                {
                    'name': 'Product Ratings',
                    'value': 'product_ratings',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Product Ratings',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Request support for product ratings feed'
                },
                {
                    'name': 'Re-optimization',
                    'value': 're_optimization',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Re-optimization',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Have a need to re-optimize your data?'
                },
                {
                    'name': 'Setup Google Merchant Center Alerts',
                    'value': 'setup_google_merchant_center_alerts',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Setup Google Merchant Center Alerts',
                    'display_template': '/app/feedsupport/forms/feed_support_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Setup Google Merchant Center Alerts'
                },
                {
                    'name': 'Tax/Shipping Errors',
                    'value': 'tax_shipping_errors',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Tax/Shipping Errors',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Get help with Tax & Shipping'
                },
                {
                    'name': 'Title/Description Optimization',
                    'value': 'title_description_optimization',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Title/Description Optimization',
                    'display_template': '/app/feedsupport/forms/feed_support_multi_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Request support on title or description optimizations'
                },
                {
                    'name': 'Local Inventory Ads Support',
                    'value': 'local_inventory_ads_support',
                    'grouping': 'Troubleshooting',
                    'Service': 'Local Inventory Ads',
                    'Service Detail': 'Local Inventory Ad - Support',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Get support for your Local Inventory Ad'
                },
                {
                    'name': 'Marketplace Promotions',
                    'value': 'marketplace_promotions',
                    'grouping': 'Feed Adjustments',
                    'Service': 'Marketplace - Support',
                    'Service Detail': 'Marketplace Promotion',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Add or change promotions for marketplaces',
                    'marketplace_only': 'true',
                },
                {
                    'name': 'Marketplace Listing Errors',
                    'value': 'marketplace_listing_errors',
                    'grouping': 'Troubleshooting',
                    'Service': 'Marketplace - Support',
                    'Service Detail': 'Marketplace Listing Errors',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Support on product listing errors',
                    'marketplace_only': 'true',
                },
                {
                    'name': 'Replatform Feed Build',
                    'value': 'replatform_feed_build',
                    'grouping': 'Feed Adjustments',
                    'Service': 'Onboard New Feed',
                    'Service Detail': 'Replatform Feed Build',
                    'display_template': '/app/feedsupport/forms/feed_support_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Let us know if you are planning to migrate platforms or upgrade your site.'
                },
                {
                    'name': 'Set Up Order Automation',
                    'value': 'set_up_order_automation',
                    'grouping': 'General',
                    'Service': 'Set Up Order Automation',
                    'Service Detail': 'Set Up Order Automation',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Set up order automation for marketplaces.',
                    'marketplace_only': 'true',
                },
                {
                    'name': 'Order Automation Support',
                    'value': 'order_automation_support',
                    'grouping': 'Troubleshooting',
                    'Service': 'Order Automation Support',
                    'Service Detail': 'Order errors',
                    'display_template': '/app/feedsupport/forms/feed_support_export_channel.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Get help with order automation issues.',
                    'marketplace_only': 'true',
                },
                {
                    'name': 'Request Feedonomics API Access',
                    'value': 'api_access',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Request Feedonomics API Access',
                    'display_template': '/app/feedsupport/forms/api_dev_access.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Request ability to make API calls for Feedonomics resources, such as FeedAMP orders, EDRTS, Content API, etc.'
                }
            ]
        };

        $scope.feed_support_channels = [
            {
                'display_name': 'AvantLink',
                'group': 'Affiliate',
                'value': 'AvantLink',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'AWIN',
                'group': 'Affiliate',
                'value': 'AWIN',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'CJ Commission Junction',
                'group': 'Affiliate',
                'value': 'CJ Commission Junction',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'LinkConnector',
                'group': 'Affiliate',
                'value': 'LinkConnector',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pepperjam',
                'group': 'Affiliate',
                'value': 'Pepperjam',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pronto',
                'group': 'Affiliate',
                'value': 'Pronto',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'ShareASale',
                'group': 'Affiliate',
                'value': 'ShareASale',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Facebook Automotive Inventory Ads',
                'group': 'Automotive Ads',
                'value': 'Facebook Automotive Inventory Ads',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Google Vehicle Ads',
                'group': 'Automotive Ads',
                'value': 'Google Vehicle Ads',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Microsoft Automotive Ads',
                'group': 'Automotive Ads',
                'value': 'Microsoft Automotive Ads',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Become',
                'group': 'Comparison Shopping Engine',
                'value': 'Become',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Bing Shopping',
                'group': 'Comparison Shopping Engine',
                'value': 'Bing Shopping',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Google Manufacturer Center',
                'group': 'Comparison Shopping Engine',
                'value': 'Google Manufacturer Center',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Google Shopping Product Ads',
                'group': 'Comparison Shopping Engine',
                'value': 'Google Shopping (PLA)',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Nextag',
                'group': 'Comparison Shopping Engine',
                'value': 'Nextag',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pricesearcher',
                'group': 'Comparison Shopping Engine',
                'value': 'Pricesearcher',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'ShopStyle',
                'group': 'Comparison Shopping Engine',
                'value': 'ShopStyle',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Shopzilla Connexity',
                'group': 'Comparison Shopping Engine',
                'value': 'Shopzilla Connexity',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Tik Tok Display Ads',
                'group': 'Comparison Shopping Engine',
                'value': 'Tik Tok Display Ads',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Google Dynamic Search Ads Page Feeds',
                'group': 'Dynamic Search Ads Page Feeds',
                'value': 'Google Dynamic Search Ads Page Feeds',
                'Service': 'Add Export - PPC Channe',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Microsoft Dynamic Search Ads Page Feeds',
                'group': 'Dynamic Search Ads Page Feeds',
                'value': 'Microsoft Dynamic Search Ads Page Feeds',
                'Service': 'Add Export - PPC Channe',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Google Dynamic Text Ads',
                'group': 'Dynamic Text Ads',
                'value': 'Google Dynamic Text Ads',
                'Service': 'Dynamic Text Ads',
                'Service Detail': 'New Dynamic Text Ad'
            },
            {
                'display_name': 'Google Local Inventory Ads',
                'group': 'Local Inventory Ads',
                'value': 'Google Local Inventory Ads',
                'Service': 'Local Inventory Ads',
                'Service Detail': 'New Local Inventory Ad'
            },
            {
                'display_name': '1-800-Flowers Marketplace ',
                'group': 'Marketplace',
                'value': '1-800-Flowers Marketplace ',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Amazon Seller Central',
                'group': 'Marketplace',
                'value': 'Amazon Seller Central',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Amazon Vendor Central',
                'group': 'Marketplace',
                'value': 'Amazon Vendor Central',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'B & Q',
                'group': 'Marketplace',
                'value': 'B and Q',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Bed Bath and Beyond Marketplace',
                'group': 'Marketplace',
                'value': 'Bed Bath and Beyond Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Belk Marketplace',
                'group': 'Marketplace',
                'value': 'Belk Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'BestBuy CA Marketplace',
                'group': 'Marketplace',
                'value': 'BestBuy CA Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'BigCommerce',
                'group': 'Marketplace',
                'value': 'BigCommerce',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Bloomingdales',
                'group': 'Marketplace',
                'value': 'Bloomingdales',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Buy Buy Baby Marketplace',
                'group': 'Marketplace',
                'value': 'Buy Buy Baby Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Debenhams',
                'group': 'Marketplace',
                'value': 'Debenhams',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Dia & Co',
                'group': 'Marketplace',
                'value': 'Dia and Co',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'eBay Commerce Network',
                'group': 'Marketplace',
                'value': 'eBay Commerce Network',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'eBay Marketplace',
                'group': 'Marketplace',
                'value': 'eBay Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Etsy Marketplace',
                'group': 'Marketplace',
                'value': 'Etsy Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'French Tennis Federation (FFT)',
                'group': 'Marketplace',
                'value': 'French Tennis Federation (FFT)',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Fruugo Marketplace',
                'group': 'Marketplace',
                'value': 'Fruugo Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Giant Eagle Marketplace',
                'group': 'Marketplace',
                'value': 'Giant Eagle Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Google Shopping Actions',
                'group': 'Marketplace',
                'value': 'Google Shopping Actions',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Holt Renfrew Marketplace',
                'group': 'Marketplace',
                'value': 'Holt Renfrew Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Houzz Marketplace',
                'group': 'Marketplace',
                'value': 'Houzz Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'HSN',
                'group': 'Marketplace',
                'value': 'HSN',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Hudson\'s Bay Marketplace',
                'group': 'Marketplace',
                'value': 'Hudsons Bay Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Kohl\'s Marketplace',
                'group': 'Marketplace',
                'value': 'Kohls Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Kroger Marketplace',
                'group': 'Marketplace',
                'value': 'Kroger Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'La Redoute Marketplace',
                'group': 'Marketplace',
                'value': 'La Redoute Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Liverpool',
                'group': 'Marketplace',
                'value': 'Liverpool',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Macy\'s Marketplace',
                'group': 'Marketplace',
                'value': 'Macys Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Mercado Libre Marketplace',
                'group': 'Marketplace',
                'value': 'Mercado Libre Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Meta Shop (Facebook and Instagram) ',
                'group': 'Marketplace',
                'value': 'Meta Shop (Facebook and Instagram) ',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'NewEgg Marketplace',
                'group': 'Marketplace',
                'value': 'NewEgg Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Oriental Trading Company Marketplace',
                'group': 'Marketplace',
                'value': 'Oriental Trading Company Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Overstock Marketplace',
                'group': 'Marketplace',
                'value': 'Overstock Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Partstown',
                'group': 'Marketplace',
                'value': 'Parts Town Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Printemp',
                'group': 'Marketplace',
                'value': 'Printemp',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Reitmans',
                'group': 'Marketplace',
                'value': 'Reitmans',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Saks Marketplace',
                'group': 'Marketplace',
                'value': 'Saks Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'SalonCentric',
                'group': 'Marketplace',
                'value': 'SalonCentric',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Sears Marketplace',
                'group': 'Marketplace',
                'value': 'Sears Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'SHEIN',
                'group': 'Marketplace',
                'value': 'SHEIN',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Shop Premium Outlets',
                'group': 'Marketplace',
                'value': 'Shop Premium Outlets',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Shopify Marketplace',
                'group': 'Marketplace',
                'value': 'Shopify Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Sysco',
                'group': 'Marketplace',
                'value': 'Sysco',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Target Plus Marketplace',
                'group': 'Marketplace',
                'value': 'Target Plus Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Tiendamia',
                'group': 'Marketplace',
                'value': 'Tiendamia',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Tik Tok Marketplace',
                'group': 'Marketplace',
                'value': 'Tik Tok Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Trademe Marketplace',
                'group': 'Marketplace',
                'value': 'Trademe Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Urban Outfitters Marketplace',
                'group': 'Marketplace',
                'value': 'Urban Outfitters Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Vivino',
                'group': 'Marketplace',
                'value': 'Vivino',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Walmart Marketplace',
                'group': 'Marketplace',
                'value': 'Walmart Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Wish Marketplace',
                'group': 'Marketplace',
                'value': 'Wish Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Zalando Marketplace',
                'group': 'Marketplace',
                'value': 'Zalando Marketplace',
                'Service': 'Marketplace - New Integration',
                'Service Detail': 'Product catalog and order integration'
            },
            {
                'display_name': 'Adacado',
                'group': 'Other Marketing Platforms',
                'value': 'Adacado',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Better Homes and Gardens',
                'group': 'Other Marketing Platforms',
                'value': 'Better Homes and Gardens',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'ClickBounce',
                'group': 'Other Marketing Platforms',
                'value': 'ClickBounce',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Curalate',
                'group': 'Other Marketing Platforms',
                'value': 'Curalate',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'EmarSys',
                'group': 'Other Marketing Platforms',
                'value': 'EmarSys',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Gilt',
                'group': 'Other Marketing Platforms',
                'value': 'Gilt',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Impact Radius',
                'group': 'Other Marketing Platforms',
                'value': 'Impact Radius',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Instacart',
                'group': 'Other Marketing Platforms',
                'value': 'Instacart',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Kenshoo',
                'group': 'Other Marketing Platforms',
                'value': 'Kenshoo',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Kijiji',
                'group': 'Other Marketing Platforms',
                'value': 'Kijiji',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Listrak',
                'group': 'Other Marketing Platforms',
                'value': 'Listrak',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Multiple',
                'group': 'Other Marketing Platforms',
                'value': 'Multiple',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Narrativ',
                'group': 'Other Marketing Platforms',
                'value': 'Narrativ',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Oath',
                'group': 'Other Marketing Platforms',
                'value': 'Oath',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Oracle Responsys',
                'group': 'Other Marketing Platforms',
                'value': 'Oracle Responsys',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'OrderGroove',
                'group': 'Other Marketing Platforms',
                'value': 'OrderGroove',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pepperjam',
                'group': 'Other Marketing Platforms',
                'value': 'Pepperjam',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Polyvore Marketplace',
                'group': 'Other Marketing Platforms',
                'value': 'Polyvore Marketplace',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pricegrabber',
                'group': 'Other Marketing Platforms',
                'value': 'Pricegrabber',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Rakuten Linkshare',
                'group': 'Other Marketing Platforms',
                'value': 'Rakuten Linkshare',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Sociomantic',
                'group': 'Other Marketing Platforms',
                'value': 'Sociomantic',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'StitcherAds',
                'group': 'Other Marketing Platforms',
                'value': 'StitcherAds',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Wholesale Central',
                'group': 'Other Marketing Platforms',
                'value': 'Wholesale Central',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Windsor Circle',
                'group': 'Other Marketing Platforms',
                'value': 'Windsor Circle',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Yahoo Gemini',
                'group': 'Other Marketing Platforms',
                'value': 'Yahoo Gemini',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Other',
                'group': 'Other',
                'value': 'Other',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'BazaarVoice',
                'group': 'Personalization',
                'value': 'BazaarVoice',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Bronto',
                'group': 'Personalization',
                'value': 'Bronto',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Certona',
                'group': 'Personalization',
                'value': 'Certona',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Monetate',
                'group': 'Personalization',
                'value': 'Monetate',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Reflektion',
                'group': 'Personalization',
                'value': 'Reflektion',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'AdRoll',
                'group': 'Remarketing',
                'value': 'AdRoll',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Criteo',
                'group': 'Remarketing',
                'value': 'Criteo',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Facebook Dynamic Remarketing Product Ads',
                'group': 'Remarketing',
                'value': 'Facebook Dynamic Remarketing Product Ads',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Snapchat',
                'group': 'Remarketing',
                'value': 'Snapchat',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'BazaarVoice',
                'group': 'Reviews',
                'value': 'BazaarVoice',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Instagram',
                'group': 'Social',
                'value': 'Instagram',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Olapic',
                'group': 'Social',
                'value': 'Olapic',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            },
            {
                'display_name': 'Pinterest',
                'group': 'Social',
                'value': 'Pinterest',
                'Service': 'Add Export - PPC Channel',
                'Service Detail': 'Comparison Shopping Engine'
            }
        ];


        $scope.countries = [
            { 'display_name': 'United States', 'value': 'United States' },
            { 'display_name': 'Argentina', 'value': 'Argentina' },
            { 'display_name': 'Australia', 'value': 'Australia' },
            { 'display_name': 'Austria', 'value': 'Austria' },
            { 'display_name': 'Belgium', 'value': 'Belgium' },
            { 'display_name': 'Brazil', 'value': 'Brazil' },
            { 'display_name': 'Canada', 'value': 'Canada' },
            { 'display_name': 'Chile', 'value': 'Chile' },
            { 'display_name': 'China', 'value': 'China' },
            { 'display_name': 'Colombia', 'value': 'Colombia' },
            { 'display_name': 'Czechia', 'value': 'Czechia' },
            { 'display_name': 'Denmark', 'value': 'Denmark' },
            { 'display_name': 'Finland', 'value': 'Finland' },
            { 'display_name': 'France', 'value': 'France' },
            { 'display_name': 'Germany', 'value': 'Germany' },
            { 'display_name': 'Hong Kong', 'value': 'Hong Kong' },
            { 'display_name': 'India', 'value': 'India' },
            { 'display_name': 'Indonesia', 'value': 'Indonesia' },
            { 'display_name': 'Ireland', 'value': 'Ireland' },
            { 'display_name': 'Italy', 'value': 'Italy' },
            { 'display_name': 'Japan', 'value': 'Japan' },
            { 'display_name': 'Malaysia', 'value': 'Malaysia' },
            { 'display_name': 'Mexico', 'value': 'Mexico' },
            { 'display_name': 'Netherlands', 'value': 'Netherlands' },
            { 'display_name': 'New Zealand', 'value': 'New Zealand' },
            { 'display_name': 'Norway', 'value': 'Norway' },
            { 'display_name': 'Peru', 'value': 'Peru' },
            { 'display_name': 'Philippines', 'value': 'Philippines' },
            { 'display_name': 'Poland', 'value': 'Poland' },
            { 'display_name': 'Portugal', 'value': 'Portugal' },
            { 'display_name': 'Russia', 'value': 'Russia' },
            { 'display_name': 'Singapore', 'value': 'Singapore' },
            { 'display_name': 'South Africa', 'value': 'South Africa' },
            { 'display_name': 'South Korea', 'value': 'South Korea' },
            { 'display_name': 'Spain', 'value': 'Spain' },
            { 'display_name': 'Sweden', 'value': 'Sweden' },
            { 'display_name': 'Switzerland', 'value': 'Switzerland' },
            { 'display_name': 'Taiwan', 'value': 'Taiwan' },
            { 'display_name': 'Turkey', 'value': 'Turkey' },
            { 'display_name': 'United Arab Emirates', 'value': 'United Arab Emirates' },
            { 'display_name': 'United Kingdom', 'value': 'United Kingdom' },
            { 'display_name': 'Uruguay', 'value': 'Uruguay' },
        ];

        $scope.languages = [
            { 'display_name': 'English', 'value': 'English' },
            { 'display_name': 'Arabic', 'value': 'Arabic' },
            { 'display_name': 'Chinese (Simplified)', 'value': 'Chinese (Simplified)' },
            { 'display_name': 'Chinese (Traditional)', 'value': 'Chinese (Traditional)' },
            { 'display_name': 'Czech', 'value': 'Czech' },
            { 'display_name': 'Dutch', 'value': 'Dutch' },
            { 'display_name': 'Finish', 'value': 'Finish' },
            { 'display_name': 'Finnish', 'value': 'Finnish' },
            { 'display_name': 'French', 'value': 'French' },
            { 'display_name': 'German', 'value': 'German' },
            { 'display_name': 'Greek', 'value': 'Greek' },
            { 'display_name': 'Hebrew', 'value': 'Hebrew' },
            { 'display_name': 'Hindi', 'value': 'Hindi' },
            { 'display_name': 'Hungarian', 'value': 'Hungarian' },
            { 'display_name': 'Indonesian', 'value': 'Indonesian' },
            { 'display_name': 'Italian', 'value': 'Italian' },
            { 'display_name': 'Japanese', 'value': 'Japanese' },
            { 'display_name': 'Korean', 'value': 'Korean' },
            { 'display_name': 'Norwegian', 'value': 'Norwegian' },
            { 'display_name': 'Polish', 'value': 'Polish' },
            { 'display_name': 'Portuguese', 'value': 'Portuguese' },
            { 'display_name': 'Romanian', 'value': 'Romanian' },
            { 'display_name': 'Russian', 'value': 'Russian' },
            { 'display_name': 'Slovak', 'value': 'Slovak' },
            { 'display_name': 'Spanish', 'value': 'Spanish' },
            { 'display_name': 'Swedish', 'value': 'Swedish' },
            { 'display_name': 'Thai', 'value': 'Thai' },
            { 'display_name': 'Turkish', 'value': 'Turkish' },
            { 'display_name': 'Ukrainian', 'value': 'Ukrainian' },
            { 'display_name': 'Vietnamese', 'value': 'Vietnamese' },
        ];

        $scope.priorities = [
            { 'value': 'Standard', 'display_name': 'Standard' },
            { 'value': 'Urgent', 'display_name': 'Urgent' }
        ];

        $scope.statuses = [
            { 'value': '', 'display_name': 'All' },
            { 'value': 'Open', 'display_name': 'Open' },
            { 'value': 'On Hold', 'display_name': 'On Hold' },
            { 'value': 'Closed', 'display_name': 'Closed' },
            { 'value': 'Action Required', 'display_name': 'Action Required' },
            { 'value': 'Build In Progress', 'display_name': 'Build In Progress' },
            { 'value': 'Data Review In Progress', 'display_name': 'Data Review In Progress' },
            { 'value': 'Delayed By Client', 'display_name': 'Delayed By Client' },
            { 'value': 'Escalated', 'display_name': 'Escalated' },
            { 'value': 'Handed Off', 'display_name': 'Handed Off' },
            { 'value': 'Import Setup', 'display_name': 'Import Setup' },
            { 'value': 'Information Needed', 'display_name': 'Information Needed' },
            { 'value': 'Internal Escalation', 'display_name': 'Internal Escalation' },
            { 'value': 'Live - Under Review', 'display_name': 'Live - Under Review' },
            { 'value': 'Monitoring', 'display_name': 'Monitoring' },
            { 'value': 'Onboarding In Progress', 'display_name': 'Onboarding In Progress' },
            { 'value': 'Onboarding Started', 'display_name': 'Onboarding Started' },
            { 'value': 'Post-QA Optimizations', 'display_name': 'Post-QA Optimizations' },
            { 'value': 'QA In Progress', 'display_name': 'QA In Progress' },
            { 'value': 'Ready for QA', 'display_name': 'Ready for QA' },
            { 'value': 'Ready to Go Live', 'display_name': 'Ready to Go Live' },
            { 'value': 'Reopened', 'display_name': 'Reopened' },
            { 'value': 'Support in Progress', 'display_name': 'Support in Progress' },
            { 'value': 'Waiting for Confirmation', 'display_name': 'Waiting for Confirmation' }
        ];

        $scope.product_options = [
            { 'value': 'Set Up', 'display_name': 'Set Up %s' },
            { 'value': 'Support', 'display_name': '%s Support' },
        ];

        $scope.cancellation_services = [
            { 'value': 'Marketplaces', 'display_name': 'Marketplaces' },
            { 'value': 'PPC Channels', 'display_name': 'PPC Channels' },
            { 'value': 'Local Inventory Ads', 'display_name': 'Local Inventory Ads' },
            { 'value': 'FeedSPI', 'display_name': 'FeedSPI' },
            { 'value': 'FeedTelligence', 'display_name': 'FeedTelligence' },
            { 'value': 'Dynamic Text Ads', 'display_name': 'Dynamic Text Ads' },
            { 'value': 'Image Padding', 'display_name': 'Image Padding' },
            { 'value': 'All Services', 'display_name': 'All Services' },
            { 'value': 'Other', 'display_name': 'Other' },
        ];

        $scope.cancellation_reasons = [
            { 'value': 'In-House IT', 'display_name': 'In-House IT' },
            { 'value': 'Using eCommerce Platform Integration', 'display_name': 'Using eCommerce Platform Integration' },
            { 'value': 'Eliminated eCommerce Advertising', 'display_name': 'Eliminated eCommerce Advertising' },
            { 'value': 'Policy Violation', 'display_name': 'Policy Violation' },
            { 'value': 'Poor Service by Feedonomics', 'display_name': 'Poor Service by Feedonomics' },
            { 'value': 'Not Enough Return to Justify Price', 'display_name': 'Not Enough Return to Justify Price' },
            { 'value': 'I have another feed solution', 'display_name': 'I have another feed solution' },
            { 'value': 'Consolidating Brands', 'display_name': 'Consolidating Brands' },
            { 'value': 'Seasonal', 'display_name': 'Seasonal' },
            { 'value': 'COVID-19', 'display_name': 'COVID-19' },
            { 'value': 'Account Suspension', 'display_name': 'Account Suspension' },
            { 'value': 'Going Out of Business', 'display_name': 'Going Out of Business' },
            { 'value': 'Other', 'display_name': 'Other' },
        ];

        $scope.feedonomics_recommendations = [
            { 'value': 'Very Likely', 'display_name': 'Very Likely' },
            { 'value': 'Likely', 'display_name': 'Likely' },
            { 'value': 'Not Likely', 'display_name': 'Not Likely' },
        ];

        $scope.adtaxi_market_region = [
            { 'value': 'Adtaxi 2/3', 'display_name': 'Adtaxi 2/3' },
            { 'value': 'BANG', 'display_name': 'BANG' },
            { 'value': 'CT', 'display_name': 'CT' },
            { 'value': 'Denver', 'display_name': 'Denver' },
            { 'value': 'MA', 'display_name': 'MA' },
            { 'value': 'MI', 'display_name': 'MI' },
            { 'value': 'NorCal', 'display_name': 'NorCal' },
            { 'value': 'NY', 'display_name': 'NY' },
            { 'value': 'OH', 'display_name': 'OH' },
            { 'value': 'PA', 'display_name': 'PA' },
            { 'value': 'PMP', 'display_name': 'PMP' },
            { 'value': 'SCNG', 'display_name': 'SCNG' },
            { 'value': 'Twin Cities', 'display_name': 'Twin Cities' },
            { 'value': 'Other', 'display_name': 'Other' },
        ];

        // Custom Data Aggregator Maintenance Options


        $scope.cda_maintenance = {
            'selected': undefined,
            'options': [
                {
                    'name': 'Inquiry',
                    'value': 'inquiry',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Inquiry',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'General question unrelated to any of these topics',
                },
                {
                    'name': 'Add/Exclude Rows',
                    'value': 'add_exclude_rows',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Add/Exclude Products',
                    'display_template': '/app/feedsupport/forms/cdam_add_exclude_rows.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Exclude or re-include products in your feeds exports'
                },
                {
                    'name': 'Populate Missing Or Update Attributes',
                    'value': 'populate_missing_or_update_attributes',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Populate Missing Or Update Categories',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Get support on categorization issues'
                },
                {
                    'name': 'Link or Image Issues',
                    'value': 'link_or_image_issues',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Link or Image Issues',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Report any link or image link issues'
                },
                {
                    'name': 'Import Troubleshooting',
                    'value': 'import_troubleshooting',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Import Troubleshooting',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Having import issues, let us help'
                },
                {
                    'name': 'Export Troubleshooting',
                    'value': 'export_troubleshooting',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Export Troubleshooting',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Having export issues, let us help'
                },
                {
                    'name': 'Import Change',
                    'value': 'import_change',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Import Change',
                    'display_template': '/app/feedsupport/forms/cdam_import_change.html', // cda_default
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name', 'Credentials'],
                    'tooltip': 'Need to change your import configuration?'
                },
                {
                    'name': 'Price/Availability Errors',
                    'value': 'price_availability_errors',
                    'grouping': 'Troubleshooting',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Price/Availability Errors',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Report price or availability errors'
                },
                {
                    'name': 'Custom Labels',
                    'value': 'custom_labels',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Custom Label',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Manage your custom labels'
                },
                {
                    'name': 'Data Governance',
                    'value': 'data_governance',
                    'grouping': 'Feed Adjustments',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Data Governance',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'Data Governance are the rules that protect your exports'
                },
            ]
        };


        $scope.cda_maintenance_general = {
            'selected': undefined,
            'options': [
                {
                    'name': 'Inquiry',
                    'value': 'inquiry',
                    'grouping': 'General',
                    'Service': 'General or Maintenance',
                    'Service Detail': 'Inquiry',
                    'display_template': '/app/feedsupport/forms/cdam_default.html',
                    'relevant_entities': ['db_group_id', 'Store', 'db_id', 'Database Name',],
                    'tooltip': 'General question unrelated to any of these topics',
                },
            ]
        };

        $scope.templates = {

            'current': undefined,
            'options': [
                {
                    'display_name': '-None-'
                },
                {
                    'display_name': 'Transfer Feed',
                    'Service': 'Internal Use',
                    'Service Detail': 'Transfer Feed',
                    'Subject': 'Transfer Details',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<span style=\"font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span style=\"color: rgb(255, 0, 0)\" class=\"colour\">Please make sure you have created a cancellation ticket for the old agency/end-client and a corresponding onboard new feed ticket (<strong>service detail as 'Transfer Feed</strong>') for the new agency/end-client if the billing entity is changing.&nbsp;</span><br></span>"
                        + "<span style=\"font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span style=\"color: rgb(56, 118, 29)\" class=\"colour\"><strong></strong></span><br></span>"
                        + "<span style=\"font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span style=\"color: rgb(56, 118, 29)\" class=\"colour\"><strong>Please NOTE the agreement, if required, should be in place before you submit the transfer request.&nbsp;</strong></span><br></span>"
                        + "<span style=\"font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span style=\"color: rgb(56, 118, 29)\" class=\"colour\"><strong></strong></span><br></span>"
                        //+"<span>"
                        + "<span style=\"font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span style=\"color: rgb(56, 118, 29)\" class=\"colour\"><strong>Information Requirements:</strong></span><br></span>"
                        //+"<span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1. Feedonomics account names (username)</strong><br><br></span>"
                        //+"<span>"
                        + "<span style=\"color: rgb(34, 34, 34)\">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;<strong><span>From : </span></strong><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;<strong><span>To : </span></strong><br></span>"
                        //+"</span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2. Feed name&nbsp;:</strong><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3. Transfer<span style=\"font-size: 12.8px\" class=\"size\">&nbsp;</span><span style=\"font-size: 12.8px\" class=\"size\">effective date&nbsp;:</span></strong><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><span style=\"font-size: 12.8px\" class=\"size\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;4. Is the billing entity <i>changing</i>? </strong>(required for completing the transfer) :&nbsp;</span><br></span>"
                        //+"<span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><span style=\"font-size: 12.8px\" class=\"size\">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;If yes, mention the ticket number of the 'Cancel Feed' ticket :</span><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&bull;&nbsp;If yes, mention the ticket number of the 'Onboard New Feed' ticket :&nbsp;<br></span></span>"
                        //+"</span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;5. Confirm old account will pay current month &amp; new account will pay the following&nbsp;month:</strong><br></span>"
                        + "<span style=\" \"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;6. A signed deal exists in Hubspot (Yes/No):</strong><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;7. Provide the updated GMC Feed Alert e-mail for the new point of contact:&nbsp;</strong><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><span style=\"font-size: 12.8px\" class=\"size\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;8. Provide the Feedonomics Master GMC username for which client granted GMC access to:</strong></span><br></span>"
                        + "<span style=\"color: rgb(34, 34, 34)\"><span style=\"font-size: 12.8px\" class=\"size\"><strong>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;9. Requested by :&nbsp;</strong></span><br></span>"
                        + "</span> "
                        //+"</span>"
                        // +"<span>"
                        //  +"<span style=\"font-size: 12.8px\" class=\"size\"><span style=\"color: rgb(34, 34, 34)\" class=\"colour\"></span><br></span>"
                        // +"</span>"
                        + "<span style=\"color: rgb(34, 34, 34); font-family: arial, sans-serif; font-size: 12.8px; background-color: rgb(255, 255, 255)\"><span><span style=\"font-size: 12.8px\" class=\"size\"></span><br></span></span>"
                },
                {
                    'display_name': 'Malakye Requests',
                    'Service': 'Internal Use',
                    'Service Detail': 'Dev - Job Board Crawl New Request',
                    'Subject': 'New Job Board Crawl',
                    'Status': 'Open',
                    'Priority': 'Medium',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Account:</strong>&nbsp;</span><br></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\">malakye</span><br></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong><br></strong></span></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Database Name or Link:</strong></span><br></span>"
                        + "<span><br></span>"
                        + "<span><br style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><br><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Source Link:</strong></span></span>"
                        + "<span><br></span>"
                        + "<span><br></span>"
                        + "<span><strong>ATS:</strong></span>"
                        + "<span><br style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"></span>"
                        + "<span><br></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Import URL (For Adjustment or Maintenance):</strong></span><br></span>"
                        + "<span><br></span>"
                        + "<span><br></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Request Details:</strong></span></span>"
                        + "<span>Title, location, description, link<br></span>"
                        + "<span><br></span>"
                        + "<span><br style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><br><strong>Expected Job Count</strong><br><br><br style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"></span>"
                        + "<span><br></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong>Requested By:</strong></span></span>"
                        + "<span><span style=\"font-family: ProximaNovaRegular, Arial, Helvetica, sans-serif; font-size: 14px; letter-spacing: 0.14px; background-color: rgb(255, 255, 255)\"><strong><br></strong></span></span>"
                },
                {
                    'display_name': 'Dev FeedAMP Support',
                    'Service': 'Internal Use',
                    'Service Detail': 'Dev FeedAMP Support',
                    'Subject': 'Dev FeedAMP Support',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>Dev FeedAMP Support</em></u><br>"
                        + ""
                        + "<strong>What is the issue?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>What is the impact?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is there a marketplace support ticket and what was their input?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Does this affect one client or many?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Are there any commonalities between the affected clients?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Did this work at some point?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Did we make any changes right before or in a short period of time before this stopped working?:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>How is this client different from a client that this has worked for?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Additional details (client platform, order number, error code, etc.)</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': 'Product Catalog Crawl Request',
                    'Service': 'Internal Use',
                    'Service Detail': 'Product Catalog Crawl Request',
                    'Subject': 'Product Catalog Crawl Request',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>Product Catalog Crawl Request</em></u><br>"
                        + ""
                        + "<strong>Link to database:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Has the client approved the crawl fee (please ensure 'Bill From' field above is correct)? If not, why?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Account Billing Contact (for new accounts):</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Have we made an effort to get this data without crawling? If so, how?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Web url of a sample product:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Web url of a sample sale product:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Do you require parents & variants, parents only, variants only?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Expected total number of skus:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Reference (Sitemap or other dynamic reference for the crawl):</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>List the fields you need in the crawl (what you don't list will not be crawled, so be specific):</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>What is the current impact until this crawl is ready (Examples - price/availability not updating correctly is urgent; old products not deleted is urgent, especially for marketplaces; new products not being added is very rarely urgent)?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>What other details should I be aware of, (import name, export name, FTP credentials etc.? Please supply everything needed to get this job done:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Associated / Referring Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Requested by:</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': '404 Checker Request',
                    'Service': 'Internal Use',
                    'Service Detail': '404 Checker Request',
                    'Subject': '404 Checker Request',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<u><em>404 Checker Request</em></u><br>'
                        + ''
                        + '<strong>Account (username):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Database Link:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What steps have we taken to fix the link issue? (404 checkers should be a last resort) </strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a link to the Zoho ticket with the client communication:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>If this is for a Lite client, has the AM approved billing?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a query link that shows products with link issues:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>How many links need to be checked? (limit is 10,000 after dedupe):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a link to a link list export, with only the IDs and links we need to crawl</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What is the current impact of not having the 404 checker? </strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What is the urgency of this request?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Please provide any other info need to complete this request:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Requested by:</strong>'
                        + '<ul><li></li></ul><br>'
                },
                {
                    'display_name': 'Attribute Crawl Request',
                    'Service': 'Internal Use',
                    'Service Detail': 'Attribute Crawl Request',
                    'Subject': 'Attribute Crawl Request',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<u><em>Attribute Crawl Request</em></u><br>'
                        + ''
                        + '<strong>Account (username):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Database Link:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What steps have we taken to get the missing data another way? (crawls should be a last resort)</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a link to the Zoho ticket with the client communication:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>If this is for a Lite client, has the AM approved billing?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>How many links need to be checked? (limit is 10,000 after dedupe):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a URL to a sample product page:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What attributes should be crawled from the pages? (screenshots are appreciated!):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a link to the link list export with only the IDs and links we need to crawl (include export conditions)</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What is the urgency of this request?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What is the current impact of not having the attribute crawl?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Please provide any other info need to complete this request:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Requested by:</strong>'
                        + '<ul><li></li></ul><br>'
                },
                {
                    'display_name': 'Crawl Maintenance',
                    'Service': 'Internal Use',
                    'Service Detail': 'Crawl Maintenance',
                    'Subject': 'Crawl Maintenance',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>Crawl Maintenance</em></u><br>"
                        + ""
                        + "<strong>Database Link:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Script in question: ​</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Describe the problem you are looking to overcome:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>If something is \"broken\", what may have changed immediately prior to that?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Any other details? (import name/url, export name, FTP credentials etc.) Please supply everything needed to get this job done:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Associated / Referring Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Requested by:</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': 'Dev Scope Request',
                    'Service': 'Internal Use',
                    'Service Detail': 'Dev Scope Request',
                    'Subject': 'Dev Scope Request',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>Dev Scope Request</em></u><br>"
                        + ""
                        + "<strong>Is this for an Enterprise account or opportunity?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is this for a pre-signed account or opportunity? (If yes, the account and database information below are required.)</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>What is the client's MRR?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Account (username):</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Database Link:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Describe the problem you are looking to overcome:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is there a current workaround? If so, please describe:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Any other details? (import name, export name, FTP credentials etc.) Please supply everything needed to get this job done:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Associated / Referring Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is there a deadline? If so, please specify the reasoning for the deadline:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Requested by:</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': 'Dev Action',
                    'Service': 'Internal Use',
                    'Service Detail': 'Dev Action',
                    'Subject': 'Dev Action',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>Dev Action</em></u><br>"
                        + ""
                        + "<strong>Has approval from AM or EPFM to submit this to dev been obtained?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is this for an Enterprise account or opportunity?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is this for a pre-signed account or opportunity? (If yes, the account and database information below are required.)</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Account (username):</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Database Link:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Describe the problem you are looking to overcome:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is there a current workaround? If so, please describe:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Link to Custom Dev Scoping Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Any other details? (import name, export name, FTP credentials etc.) Please supply everything needed to get this job done:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Associated / Referring Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Is there a deadline? If so, please specify the reasoning for the deadline:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Requested by:</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': 'Feedonomics Platform Bug',
                    'Service': 'Internal Use',
                    'Service Detail': 'Feedonomics Platform Bug',
                    'Subject': 'Feedonomics Platform Bug',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<u><em>Feedonomics Platform Bug</em></u><br>'
                        + ''
                        + '<strong>Link to Database:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Describe the platform bug:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide detailed steps to recreate the issue (screenshots or videos help!):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>If something is "broken", what may have changed immediately prior to that?</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Any other details? (import name/url, export name, FTP credentials etc.) Please supply everything needed to get this job done:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Link to Associated / Referring Ticket:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Requested by:</strong>'
                        + '<ul><li></li></ul><br>'
                },
                {
                    'display_name': 'Solutionist Request',
                    'Service': 'Internal Use',
                    'Service Detail': 'Solutionist',
                    'Subject': 'Solutionist Request',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<u><em>Solutionist Request</em></u>'
                        + ''
                        + '<span style=\"font-size: 12.8px; font-weight:900;\"><span style=\"color: rgb(0, 0, 0);font-weight:900;line-height:1.5;margin-bottom:10px;\" class=\"colour\">*Please complete all fields and leave \"N/A\" where applicable.&nbsp;</span></span><br>'
                        + ''
                        + '<strong>Account (username):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Database URL:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Describe what the problem is:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Provide a database query (URL) showing impacted products or a list of example products if products are missing:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Describe what the desired outcome should look like if we need something output in a certain way:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Any other details that will help resolve the issue? (Links to documentation, related tickets, examples of previous set ups, similar situations, etc):</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>What is the urgency of this request (Low/Medium/High)? If High, please also set the Priority field below to “Urgent”:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>If urgent, what is the impact of not having this resolved?:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Associated / Referring Ticket:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Team Lead:</strong>'
                        + '<ul><li></li></ul><br>'
                        + '<strong>Requested by:</strong>'
                        + '<ul><li></li></ul><br>'
                },
                {
                    'display_name': 'MP Webhook Maintenance',
                    'Service': 'Internal Use',
                    'Service Detail': 'MP Webhook Maintenance',
                    'Subject': 'MP Webhook Maintenance',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ""
                        + "<u><em>MP Webhook Maintenance Request</em></u><br>"
                        + ""
                        + "<strong>Link to database:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Describe the problem you are looking to overcome:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>If something is \"broken\", what may have changed immediately prior to that?</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Any other details? (import name/url, export name, FTP credentials etc.) Please supply everything needed to get this job done:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Webhook in question:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Associated / Referring Ticket:</strong>"
                        + "<ul><li></li></ul><br>"
                        + "<strong>Requested by:</strong>"
                        + "<ul><li></li></ul><br>"
                },
                {
                    'display_name': 'MP Solutionist Scoping',
                    'Service': 'Internal Use',
                    'Service Detail': 'MP Solutionist Scoping',
                    'Subject': 'MP Solutionist Scoping',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<h2><strong><u>MP Solutionist Scoping</u></strong></h2><br>'
                        + ''
                        + '<u><strong>Account (username):</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<u><strong>Database Link:</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<u><strong>Describe the problem:</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Database query illustrating issue:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Associated / Referring Ticket:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Team Lead:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Requested by:</u></strong><br>'
                        + '<ul><li></li></ul><br>'

                },
                {
                    'display_name': 'MP Solutionist Action',
                    'Service': 'Internal Use',
                    'Service Detail': 'MP Solutionist Action',
                    'Subject': 'MP Solutionist Action',
                    'Status': 'Open',
                    'Priority': 'Standard',
                    'Channel': 'Agent',
                    'Office': 'Los Angeles',
                    'Description': ''
                        + '<h2><strong><u>MP Solutionist Action</u></strong></h2><br>'
                        + ''
                        + '<u><strong>Account (username):</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<u><strong>Database Link:</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<u><strong>Describe the problem:</strong></u><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Database query illustrating issue:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Describe what the desired outcome should look like if we need something output in a certain way:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Any other details that will help resolve the issue? (Links to documentation, related tickets, examples of previous set ups, similar situations, etc):</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Associated / Referring Ticket:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Team Lead:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                        + '<strong><u>Requested by:</u></strong><br>'
                        + '<ul><li></li></ul><br>'
                }
            ]
        }

        $scope.custom_category_access = function () {

            var access_string = '';

            // Most accounts will not have a custom feedsupport category string- check if its defined
            var custom_feature_flag =
                typeof AppStateService.getAccount().features !== 'undefined'
                && typeof AppStateService.getAccount().features.feedsupport_categories !== 'undefined';


            if (custom_feature_flag) {
                access_string = AppStateService.getAccount().features.feedsupport_categories;
            }

            // If access_string actually got data- we still need to be sure its valid JSON or this code splodes
            if (access_string !== '' && $scope.checkJSON(access_string)) {
                var access = JSON.parse(access_string);
            }
            else {
                access_string = '';
            }

            // In the event that the JSON is valid- we can process its two pieces to hide public categories or show hidden ones.
            if (typeof access_string !== 'undefined' && access_string !== '') {

                // Hide categories
                for (var access_level in access['hide_categories']) {
                    var hide_category = access['hide_categories'][access_level];
                    for (var j = 0; j < $scope.forms.accordions.length; j++) {
                        if ($scope.forms.accordions[j].name == hide_category) {
                            $scope.forms.accordions[j].hide = true;
                        }
                    }
                }

                // Show categories
                for (const access_level in access['show_categories']) {
                    var show_category = access['show_categories'][access_level];
                    for (let j = 0; j < $scope.forms.accordions.length; j++) {
                        if ($scope.forms.accordions[j].name == show_category) {
                            $scope.forms.accordions[j].hide = false;
                            if ('logo' in $scope.forms.accordions[j]) {
                                $scope.logo = $scope.forms.accordions[j].logo;
                            }
                        }
                        // Any category which requires imports should be flagged here.
                        if (show_category == 'data_aggregator') {
                            // Not currently implemented
                            // $scope.populate_imports();
                        }

                        // If we have Vivino we should add the Low Priority option
                        if (show_category == 'vivino') {
                            var vivino_flag = false;
                            for (let i_vivino = 0; i_vivino < $scope.priorities.length; i_vivino++) {
                                if ($scope.priorities[i_vivino].value == 'Low') {
                                    vivino_flag = true;
                                }
                            }

                            if (!vivino_flag) {
                                $scope.priorities.push({ 'value': 'Low', 'display_name': 'Low' });
                            }
                        }
                    }
                }


                // Custom Data Aggregator
                if (typeof access['cda'] !== 'undefined') {
                    for (let j = 0; j < $scope.forms.accordions.length; j++) {
                        if ($scope.forms.accordions[j].name == "custom_data_aggregator") {
                            for (const access_level in access['cda']) {
                                var hide_button = access['cda'][access_level];
                                for (let k = 0; k < $scope.forms.accordions[j].buttons.length; k++) {
                                    if ($scope.forms.accordions[j].buttons[k].title == hide_button) {
                                        $scope.forms.accordions[j].buttons[k].hidden = true;
                                    }
                                }
                            }
                        }
                    }
                }

                // Custom Zip Recruiter "Add a Store" Button (Request a Crawl)
                if ($scope.is_zip_recruiter()) {
                    for (let j = 0; j < $scope.forms.accordions.length; j++) {
                        const accordion = $scope.forms.accordions[j];
                        if (accordion.name === 'custom_data_aggregator') {
                            for (let k = 0; k < accordion.buttons.length; k++) {
                                const title = accordion.buttons[k].title;
                                if (title === 'Add a Catalog') {
                                    $scope.forms.accordions[j].buttons[k].display_template = '/app/feedsupport/forms/add_crawl.html';
                                }
                            }
                        }
                    }
                }


                if (typeof access['unique_options'] !== 'undefined') {
                    for (const access_level in access['unique_options']) {
                        //if(access['unique_options'][access_level] == 'hide_magento'){
                        $scope.unique_options[access['unique_options'][access_level]] = true;
                        //}

                    }
                }

                // If there IS a custom store definition BUT that defintion is default:
                var custom_store_definition_flag = typeof access['custom_store_definition'] !== 'undefined'
                    && access['custom_store_definition'] !== 'default';
                if (custom_store_definition_flag) {
                    $scope.store_definition = access['custom_store_definition'];
                }
                else {
                    $scope.check_store_definition_default();
                }

                $scope.custom_user_experience = [];
                // Flag for custom user experience ie adtaxi
                if (typeof access['custom_user_experience'] !== 'undefined') {
                    for (const custom_ux in access['custom_user_experience']) {
                        $scope.custom_user_experience.push(access['custom_user_experience'][custom_ux]);
                    }
                }

                $scope.display_categories = true;
            }
            // Upon successfully finding and processing custom categories or recognizing we have successfully pulled account features and no such property exists- show categories
            else {
                // If we don't have a custom Store Definition we should see if No DB Value
                $scope.check_store_definition_default();
                $scope.display_categories = true;
            }

            // Check if we are honoring the Ebay Minimum UI

            if (!AppStateService.getAccount().hasAccountLevelAccess() && !$scope.analyst_role) {
                $scope.ebay_minimal_ui.flag = fdxUI.showMinimalEbayUI();
            }

            if ($scope.ebay_minimal_ui.flag) {
                $scope.ebay_minimal_ui_auto_open();
            }

        }

        $scope.check_store_definition_default = function () {
            var fsPromise = FeedSupportStateService.getStoreDefinition($scope.account_id).then((definition) => {
                $scope.store_definition = definition;
            });
        }

        $scope.is_zip_recruiter = () => {
            return AppStateService.getAccount().account_name === 'zip_recruiter';
        };

        $scope.file_stringify = function (obj) {
            const replacer = [];
            for (const key in obj) {
                replacer.push(key);
            }
            return JSON.stringify(obj, replacer);
        }

        if ($scope.analyst_role == true) {
            $scope.atp_initialize();
            $scope.load_users();
        }

        $scope.custom_category_access();

        function processFormParams() {

            const form = $stateParams['form'];

            if (!form) {
                return;
            }

            $scope.forms.accordions.forEach((accordion) => {
                for (const button of accordion.buttons) {
                    if (button.name === form) {
                        $scope.form = button;
                        $scope.display_form(button, accordion, button.title);
                        break;
                    }
                }
            });

            const topic = $stateParams['topic'];

            const ticket = FEED_SUPPORT_TICKETS_ALL.concat([FLEXITICKET_PORTAL]).find((entry) => {

                if (topic) {
                    return entry.link?.form === form && entry.link?.topic === topic;
                }

                return entry.link?.form === form;

            });

            if (ticket) {

                $scope.label = ticket.label;

                if (['a', 'e', 'i', 'o', 'u'].includes(ticket.title.toLowerCase().charAt(0))) {
                    $scope.article = 'an';
                }
            }

            if (!topic) {
                return;
            }

            const option = $scope.feed_support.options.find((entry) => {
                return entry.value === topic;
            });

            if (option) {

                // <ui-select class="select-form-dropdown"

                // this simulates:
                // ng-model="feed_support.selected"
                $scope.feed_support.selected = option;

                // this simulates
                // ng-change="initialize_form(forms.selected,feed_support.selected); clear_above(forms.selected,1);"
                $scope.initialize_form($scope.forms.selected, $scope.feed_support.selected);
                $scope.clear_above($scope.forms.selected, 1);

            }

        }

        processFormParams();

        $scope.breadcrumbs = [
            {
                title: 'FeedSupport™',
                route: ['/', $scope.account_id, 'feedsupport-new']
            }
        ];

        $scope.currentPageTitle = `Submit ${$scope.article} ${$scope.label} ticket`;

    }]);
