/* eslint-disable no-case-declarations */
/* eslint-disable complexity */
/* eslint-disable no-unused-vars */
import { deepCopy } from '@app/core/functions/deepCopy';
import { isEmpty } from '@app/core/functions/isEmpty';
import { prettyJSON } from '@app/core/functions/prettyJSON';
import { LogsInfoModalComponent } from '@app/logs/components/logs-info-modal/logs-info-modal.component';
import { HUMAN_FRIENDLY_ERRORS_MAP, LOGS_API_REQUESTS_MAP, OPERATION_NAMES_MAP } from '@app/logs/constants/logs.constants';
import { EmptyStateBodyTextSize, EmptyStateImageType } from '@feedonomics/frontend-components';
import * as angular from 'angular';
import * as moment from 'moment';
import { MODULE_NAME } from '../config/constants';

angular.module(MODULE_NAME).controller('LogsController', [
    '$scope',
    '$http',
    '$stateParams',
    '$location',
    '$filter',
    '$q',
    '$window',
    'CSVService',
    'ngToast',
    'AppStateService',
    'fdxUI',
    'NgbModalService',
    function(
        $scope,
        $http,
        $stateParams,
        $location,
        $filter,
        $q,
        $window,
        CSVService,
        ngToast,
        AppStateService,
        fdxUI,
        NgbModalService
    ) {

    fdxUI.setTitle('Logs');
    fdxUI.setActiveTab('');
    $scope.filter_start_date = '';
    $scope.filter_end_date = '';
    $scope.loading = false;

    $scope.emptyStateImageType = EmptyStateImageType;
    $scope.emptyStateBodyTextSize = EmptyStateBodyTextSize;

    const databaseId = AppStateService.getDatabaseId();
    const logType = $stateParams.log_type;
    $scope.oldLogsAlert = false;

    $scope.current_db = AppStateService.getDatabase();

    $scope.no_logs_text = 'Action succeeded. Log entry exceeds character limit to display.';

    $scope.is_nullish = function(item) {
        return item === null || item === 'null';
    };

    $scope.toggle = function(obj, attr, val) {
        obj[attr] = val;
    }

    $scope.collapseAll = true;
    $scope.toggleCollapse = function(buttonValue) {
        if (!$scope.logs.length) {
            return;
        }

        if ($scope.collapseAll !== buttonValue) {
            $scope.logs.forEach((log) => {
                log.expanded = Boolean($scope.collapseAll);
            });
        } else {
            $scope.logs.forEach((log) => {
                log.expanded = !($scope.collapseAll);
            });
        }
        $scope.collapseAll = buttonValue;
    };

    // expand all when user presses CTRL+F
    $scope.onKeyDown = (e) => {
            if (e.key==='f' && (e.ctrlKey || e. metaKey)) {
                $scope.toggleCollapse(false);
            }
    }
    $window.addEventListener('keydown', $scope.onKeyDown);
    $scope.$on('$destroy', () => {
        $window.removeEventListener('keydown', $scope.onKeyDown);
    });

    // These getters facilitate front end logic
    $scope.get_feed_errors = function(feed) {
        if (!feed.statistics) {
            return 0;
        }

        return feed.statistics.disapproved || 0;
    }

    $scope.get_feed_size = function(feed) {
        if (!feed.statistics) {
            feed.statistics = {};
        }

        return parseInt(feed.statistics.active || 0, 10)
            + parseInt(feed.statistics.disapproved || 0, 10)
            + parseInt(feed.statistics.expiring || 0, 10)
            + parseInt(feed.statistics.pending || 0, 10);
    }
    // Sorts one of google's item-level-issues by
    // servability [disapproved first], then number of items [greater first]
    $scope.error_sort = function (a,b) {
        const servability_map = {'unaffected' : 1, 'demoted' : 0, 'disapproved': -1}
        const servability = servability_map[a.servability] - servability_map[b.servability];
        return servability || b.numItems - a.numItems;
    };

    // Log Types
    $scope.log_types = [
        {
            value: 'imports_exports',
            display_name: 'Import / Export Logs'
        },
        {
            value: 'transformers',
            display_name: 'Transformer Logs'
        },
        {
            value: 'feed_alerts',
            display_name: 'Google Merchant Center Logs'
        },
        {
            value: 'change_logs',
            display_name: 'Change Logs'
        },
        {
            value: 'export_event_queue',
            display_name: 'Export Event Queue'
        },
        {
            value: 'ebs_logs',
            display_name: 'EBS Logs'
        }
    ];

    // Api log Filters
    $scope.api_log_filters = [
        {
            value: 'all',
            display_name: 'Everything'
        },
        {
            value: 'imports',
            display_name: 'Import Logs'
        },
        {
            value: 'exports',
            display_name: 'Export Logs'
        },
        {
            value: 'transformers',
            display_name: 'Transformer Logs'
        }
    ];

    // Import Log Filters
    $scope.import_log_filters = [
        {
            value: 'all',
            display_name: 'Everything'
        },
        {
            value: 'imports',
            display_name: 'Import Logs'
        },
        {
            value: 'exports',
            display_name: 'Export Logs'
        },
        {
            value: 'transformers',
            display_name: 'Transformer Logs'
        }
    ];

    $scope.init_db_filters = function(){

        if( !$scope.db_filter ) {
            $scope.db_filter = {};
        }

        $scope.db_filter.start_date = moment().subtract(1, 'month').toDate();
        $scope.db_filter.end_date = moment().endOf('day').toDate();

        $scope.db_filter.min_date = moment().subtract(1, 'year').toDate();
        $scope.db_filter.max_date = moment().endOf('day').toDate();
        $scope.db_filter.keyword = '';

        $scope.db_filter.date_range = moment().subtract(1, 'month').format('MM/DD/YYYY') + ' to ' + moment().endOf('day').format('MM/DD/YYYY');

        $scope.db_filter.date_range_config = {
            mode: 'range',
            minDate: $scope.db_filter.min_date,
            maxDate: $scope.db_filter.max_date,
            onChange: [function(selectedDates, dateStr, instance) {
                const start_end_dates = dateStr.split(' to ');
                if(start_end_dates.length === 2) {
                    $scope.db_filter.start_date = moment(start_end_dates[0]).toDate();
                    $scope.db_filter.end_date = moment(start_end_dates[1]).endOf('day').toDate();
                    $scope.change_log($scope.log_type);
                }
                else if(start_end_dates.length === 1 && $('.flatpickr-calendar')[0].className.indexOf('open') === -1) {
                    $scope.db_filter.start_date = moment(start_end_dates[0]).toDate();
                    $scope.db_filter.end_date = moment(start_end_dates[0]).endOf('day').toDate();
                    $scope.change_log($scope.log_type);
                }
            }]
        };

        $scope.db_filter.action = 'All';
        $scope.db_filter.entity = 'All';
        $scope.db_filter.user = 'All';
        $scope.db_filter.field = 'All';
        $scope.db_filter.status = 'All';
        $scope.db_filter.actions = [
            {
                'value':'All',
                'display_name':'All'
            }
        ];
        $scope.db_filter.entities = [
            {
                'value':'All',
                'display_name':'All'
            }
        ];
        $scope.db_filter.users = [
            {
                'value':'All',
                'display_name':'All'
            }
        ];
        $scope.db_filter.fields = [
            {
                'value':'All',
                'display_name':'All'
            }
        ];
        $scope.db_filter.statuses = [
            {
                'value':'All',
                'display_name':'All'
            }
        ];

        $scope.db_filter.operation = [];
        $scope.db_filter.operations = Array.from(OPERATION_NAMES_MAP.entries()).map(
            (mapPair) => ({
                    value: mapPair[0],
                    display_name: mapPair[1]
                })
        ).sort((a, b) => {
            return a.display_name.toLowerCase() < b.display_name.toLowerCase() ? -1 : 1;
        });

        $scope.db_filter.importsListSelected = [];
        $scope.db_filter.importIDs = [];

        $scope.db_filter.exportsListSelected = [];
        $scope.db_filter.exportIDs = [];

    };
    $scope.init_db_filters();

    $scope.change_log_filters = {
        'diff': false
    };

    // Make the log display templates.
    const get_template_cb = {
        db_import: (log) => {
            let message = 'Finished Importing <strong>'+$filter('number')(log.data.sku_count,0)+'</strong> Rows';
            if (log.data.run_duration) {
                message += ' in ' + $filter('formatSeconds')(log.data.run_duration);
            }
            return message;
        },
        download: (_log) => {
            return 'Finished Downloading Import File';
        },
        download_limit_uncompressed: (log) => {
            return '<span class="text-danger">The uncompressed import file size (' + $filter('filesize')(log.data.uncompressed_size) + ') exceeded the download file size limit (' + $filter('filesize')(log.data.download_limit) + ')</span>';
        },
        source_file_field_missing: (log) => {
            return '<span class="text-danger">The import file was expected to have the following source field:\'' + log.data.header_field_name +'\', but it was missing.</span>';
        },
        no_fields_for_tmp_table: (_log) => {
            return '<span class="text-danger">Feedonomics could not infer any shared characteristics between this join import and any other import in this database.</span>';
        },
        summary_governance_no_primary_key: (_log) => {
            return '<span class="text-danger">This database has a summary governance but is not configured with a primary key.<br>Set one on the field execution page.</span>';
        },
        bad_http_status_code: (log) => {
            return '<div class="text-truncate" style="width:500px;" title="' + log.data.url + '">' +
                log.data.url +
                '</div><br>' +
                '<span class="text-danger">' +
                'Returned with a status code of: ' + log.data.status_code + '<br>' +
                'Ensure the link is still live, and that you\'re authorized to access it.<br>' +
                'Otherwise, if this issue does not resolve itself, please contact a developer.<br>' +
                '</span>';
        },
        xml_to_csv: (log) => {
            return '<span class="text-danger">' +
                'The XML file for this import was invalid<br>' +
                'The first encountered error:<br>' +
                '<pre>' +
                log.data.first_xml_error +
                '</pre>' +
                '</span>';
        },
        missing_item_group_id: (_log) => {
            return '<span class="text-danger">' +
                'This export is misconfigured<br>' +
                'Please set the item group id on the export page' +
                '</span>';
        },
        xml_to_csv_download: (_log) => {
            return '<span class="text-danger">The XML Parsing Service Was Temporarily Unavailable, so the import was stopped.</span>';
        },
        import_missing_header: (_log) => {
            return '<span class="text-danger">The import file is missing a header row.</span>';
        },
        export: (log) => {
            let message = 'Finished Exporting <strong>'+$filter('number')(log.data.total_count,0)+'</strong> Rows'
            if (log.data.run_duration) {
                message += ' in ' + $filter('formatSeconds')(log.data.run_duration);
            }
            if (log.data?.rad_errors) {
                const radErrors = {...log.data?.rad_errors};
                radErrors.dupeProductID = radErrors.dupeAfn;
                delete radErrors.dupeAfn;
                radErrors.noURL = radErrors.noUri;
                delete radErrors.noUri;
                message += '<br /><strong>RAD Errors:</strong>';
                message += '<div class="form-control" style="height: auto; white-space: pre-wrap">' + $filter('prettyJSON')(radErrors) + '</div>';
            }
            if (log.data?.records_summary) {
                message += '<br /><strong>RAD Records Summary:</strong>';
                message += '<div class="form-control" style="height: auto; white-space: pre-wrap">' + $filter('prettyJSON')(log.data.records_summary) + '</div>';
            }
            return message;
        },
        amazon_api_error: (_log) => {
            return '<span class="text-danger">There was an issue with the Amazon API.</span>'
        },
        summary_governance_configuration_error: (_log) => {
            return '<span class="text-danger">Please contact support.</span>';
        },
        summary_governance_internal_gm_error: (_log) => {
            return '<span class="text-danger">Please contact support.</span>';
        },
        export_local: (log) => {
            let message = 'Ran Local Export with <strong>'+$filter('number')(log.data.total_count,0)+'</strong> Rows';
            if (log.data.duration) {
                message += ' in ' + $filter('formatSeconds')(log.data.duration);
            }

            if(log.data.summary && typeof log.data.summary.result !== 'undefined') {
                message += '<div class="form-control" style="height: auto; white-space: pre-wrap">' + $filter('prettyJSON')(JSON.parse(JSON.stringify(log.data.summary.result))) + '</div>'
            }
            return message;
        },
        data_governance_prevent_export: (log) => {
            let return_message = '<span class="text-danger">A data governance query has prevented your export<br><br>';
            Object.keys(log.data.data_governance_queries).forEach((key) => {
                if (log.data.data_governance_queries[key].criteria_met) {
                    return_message
                        += '<code style="display:block;white-space:pre-wrap;">'
                        + log.data.data_governance_queries[key].selector
                        + '</code> had ';
                    if (log.data.data_governance_queries[key].comparison_operator === '>') {
                        return_message += 'more';
                    } else {
                        return_message += 'less';
                    }
                    return_message
                        += ' than '
                        + $filter('number')(log.data.data_governance_queries[key].threshold_count,0);
                    if (log.data.data_governance_queries[key].threshold_type === 'percent') {
                        return_message += '% of';
                    }
                    return_message += ' rows, so exporting was halted.<br><br>';
                }
            });
            return return_message;
        },
        sftp_permissions_error: (log) => {
            const return_message = '<span class="text-danger">We could not push the export to the receiving server.<br><br>'
                + log.data.action_message + '</span>';
            return return_message;
        },
        sftp_operations_error: (log) => {
            const return_message = '<span class="text-danger">We could not push the export to the receiving server.<br><br>'
                + log.data.action_message + '</span>';
            return return_message;
        },
        // sftp_permissions_error: (log) => {
        //     var return_message = '<span class="text-danger">We could not push the export to the receiving server.<br><br>'
        //                                              + log.data.action_message + '</span>';
        //     return return_message;
        // },
        data_governance_alert: (log) => {
            let return_message = '<span class="text-warning">A data governance query was triggered<br><br>';
            Object.keys(log.data.data_governance_queries).forEach((key) => {
                if (log.data.data_governance_queries[key].criteria_met) {
                    return_message
                        += '<code class="text-warning" style="display:block;white-space:pre-wrap;">'
                        + log.data.data_governance_queries[key].selector
                        + '</code> had ';
                    if (log.data.data_governance_queries[key].comparison_operator === '>') {
                        return_message += 'more';
                    } else {
                        return_message += 'less';
                    }
                    return_message
                        += ' than '
                        + $filter('number')(log.data.data_governance_queries[key].threshold_count,0);
                    if (log.data.data_governance_queries[key].threshold_type === 'percent') {
                        return_message += '% of';
                    }
                    return_message += ' rows.<br><br>';
                }
            });
            return return_message;
        },
        summary_governance_prevent_export: (log) => {
            let return_message = '<span class="text-danger">A summary governance threshold failed. The export was halted.<br><br>';
            if(typeof log.data.thresholds !== 'undefined') {
                log.data.thresholds.forEach((threshold) => {
                    const count_message = threshold.count_type === 'abs' ? threshold.count : (threshold.count + '%');
                    const threshold_message = threshold.count_type === 'abs' ? threshold.threshold : (threshold.threshold + '%')

                    return_message += threshold.comparison_operator + ' ' + threshold_message + ' row(s) were ' + threshold.threshold_type + ' for the field <strong>' + threshold.field + '</strong>';
                    return_message += '<br/>' +
                        '<strong>'+threshold.threshold_type+ ' : ' + count_message + '</strong><br/>'
                });

                return_message += '<span/>';
            }

            return return_message;
        },
        summary_governance_prevent_import: (log) => {
            let return_message = '<span class="text-danger">A summary governance threshold failed. The import was halted.<br><br>';
            if(typeof log.data.thresholds !== 'undefined') {
                log.data.thresholds.forEach((threshold) => {
                    const count_message = threshold.count_type === 'abs' ? threshold.count : (threshold.count + '%');
                    const threshold_message = threshold.count_type === 'abs' ? threshold.threshold : (threshold.threshold + '%')

                    return_message += threshold.comparison_operator + ' ' + threshold_message + ' row(s) were ' + threshold.threshold_type + ' for the field <strong>' + threshold.field + '</strong>';
                    return_message += '<br/>' +
                        '<strong>'+threshold.threshold_type+ ' : ' + count_message + '</strong><br/>'
                });

                return_message += '<span/>';
            }

            return return_message;
        },
        summary_governance_alert : (log) => {
            let return_message = '<span class="text-warning">A summary governance threshold failed. The alert triggered due to the summary governance setting:<br><br>';
            if(typeof log.data.thresholds !== 'undefined') {
                log.data.thresholds.forEach((threshold) => {
                    const count_message = threshold.count_type === 'abs' ? threshold.count : (threshold.count + '%');
                    const threshold_message = threshold.count_type === 'abs' ? threshold.threshold : (threshold.threshold + '%')

                    return_message += threshold.comparison_operator + ' ' + threshold_message + ' row(s) were ' + threshold.threshold_type + ' for the field <strong>' + threshold.field + '</strong>';
                    return_message += '<br/>' +
                        '<strong>'+threshold.threshold_type+ ' : ' + count_message + '</strong><br/>'
                });

                return_message += '<span/>';
            }
            return return_message;
        },
        data_override_unique_violation: (log) => {
            return '<span class="text-danger">Override violation : `' + log.data.field + '` is not a unique field</span>';
        },
        export_threshold_violation: (log) => {
            return `<span class='text-danger'>We expected more than ${$filter('number')(log.data.threshold,0)} rows, but detected only ${log.data.total_count} rows.<br></span>`;
        },
        export_empty_violation: (_log) => {
            return '<span class="text-danger">Empty Summary Error: There are 0 rows to summarize/calculate deltas.</span><br/>';
        },
        update_threshold_violation: (log) => {
            return '<span class="text-danger">We expected to update at least ' + $filter('number')(log.data.update_threshold,0) + ' rows, but updated only ' + log.data.sku_count + ' rows.<br></span>';
        },
        load_threshold_violation: (log) => {
            return '<span class="text-danger">We expected to load at least ' + log.data.load_threshold + ' rows, but loaded only ' + log.data.sku_count + ' rows<br></span>';
        },
        load_threshold_percent_violation: (log) => {
            return '<span class="text-danger">We expected to load at least ' + log.data.load_threshold + ' rows, but loaded only ' + log.data.sku_count + ' rows<br></span>';
        },
        invalid_xml_path: (log) => {
            return '<span class="text-danger">There was an invalid XML path: `' + log.data.export_field.export_field_name + '`</span>';
        },
        transformer_fail: (log) => {
            let return_message =
                '<span class="text-danger">Invalid transformation: '
                + log.data.transformer.field_name + '<br>'
                + 'if: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.selector + '</code>'
                + 'then: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.transformer + '</code>';
            if(typeof(log.data.error_details) !== 'undefined') {
                return_message += '<br>Specific Error: ' + log.data.error_details;
            }
            return_message += '</span>';
            return return_message;
        },
        transformer_lex: (log) => {
            const return_message =
                '<span class="text-danger">There was an issue lexing a transformer for field `'
                + log.data.transformer.field_name + '`<br>'
                + 'if: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.selector + '<br>'
                + 'then: ' + log.data.transformer.transformer
                + '</span>';
            return return_message;
        },
        transformer_parse: (log) => {
            const return_message =
                '<span class="text-danger">There was an issue parsing a transformer for field \'' + log.data.transformer.field_name + '\' <br>'
                + 'if: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.selector + '</code>'
                + 'then: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.transformer + '</code>'
                + 'error: <code style="display:block;white-space:pre-wrap;">' + log.data.parse.value + '</code>'
                + '</span>';
            return return_message;
        },
        transformer_create: (log) => {
            const return_message =
                '<span class="text-danger">There was an issue creating a transformer for field \'' + log.data.transformer.field_name + '\' <br>'
                + 'if: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.selector + '</code>'
                + 'then: <code style="display:block;white-space:pre-wrap;">' + log.data.transformer.transformer + '</code>'
                + '</span>';
            return return_message;
        },
        curl_error: (log) => {
            let return_message = '<span class="text-danger">';
            if(log.import_id === 0) {
                return_message +=
                    'We could not push the export to the receiving server.<br>';
            } else {
                let import_url = '';
                if(typeof($scope.imports[log.import_id]) !== 'undefined') {

                    if ($scope.imports[log.import_id].file_location === 'url'
                    || $scope.imports[log.import_id].file_location === 'preprocess_script') {
                        import_url = $scope.imports[log.import_id].import_info.url;
                    }
                    else if ($scope.imports[log.import_id].file_location === 'ftp') {
                        import_url =
                            $scope.imports[log.import_id].import_info.host
                            + '/' + $scope.imports[log.import_id].import_info.file_name
                        ;
                    }
                }
                else {
                    import_url = 'A deleted import';
                }
                return_message
                    += import_url + '<br>'
                    + 'Could not be loaded.<br>';
            }
            return_message +=
                '<br>' + log.data.curl_readable_messages.short_message + '<br>'
                + log.data.curl_readable_messages.action_message + '<br><br>';
            return_message +=
                'The specific error was:<br>'
                + '(' + log.data.curl_errno + ') ' + log.data.curl_error + '</span>';
            return return_message;
        },
        invalid_url: (log) => {
            return '<span class="text-danger">The import download request could not be completed because of an invalid URL: ' + log.data.url + '</span>';
        },
        export_webhook_error: (log) => {
            const return_message = '' +
                '<span class="text-danger">' +
                '  <strong>Export:</strong> <a href="/app/#/'+ log.data.export.db_id +'/exports?export_id='+ log.data.export.id +'">'+ log.data.export.name +'</a>' +
                '  <br />' +
                '  <strong>Error:</strong> <span style="white-space: pre-wrap">'+ (log.data.message ? log.data.message.trim() : 'N/A') +'</span>' +
                '</span>';

            return return_message;
        },
        export_missing_rad_association: (log) => {
            return '<span class="text-danger">' + log.data.message + '</span>';
        },
        export_webhook_log: (log) => {
            const payloads = [];

            if (!log.data || !Array.isArray(log.data)) {
                log.data = [];
            }

            log.data.forEach((row) => {
                const payload = JSON.parse(row.payload);
                // as far as we know, "body" and "message" could include HTML but not limited to them.
                for (const [key, val] of Object.entries(payload.data)) {
                    if (typeof val === 'string' || val instanceof String) {
                        payload.data[key] = $filter('escapeHtml')(val, true);
                    }
                }
                payloads.push('<div class="form-control" style="height: auto; white-space: pre-wrap">' + $filter('prettyJSON')(payload) + '</div>');
            });

            if (log.data.length === 0) {
                log.expanded = true;
                return 'Pending response from Export';
            }

            const return_message = payloads.join('<br>');

            return return_message;
        },
        download_limit: (log) => {
            // 'The import file exceeded the download file size limit of {$data["import"]["limits"]["import_file.size"]}'
            const return_message =
                '<span class="text-danger">The import file exceeded the download file size limit of '+log.data.download_limit+'<span/>'
            return return_message;
        },
        empty_file: (log) => {
            let import_name = '';
            if (typeof($scope.imports[log.import_id]) !== 'undefined') {
                import_name = $scope.imports[log.import_id].name;
            }
            const return_message =
                '<span class="text-danger">'
                + 'We attempted to download ' + import_name + '<br>'
                + 'The server responded with an empty file.<br>'
                + '</span>';
            return return_message;
        },
        gzip_fail: (log) => {
            let import_name = '';
            if (typeof($scope.imports[log.import_id]) !== 'undefined') {
                import_name = $scope.imports[log.import_id].name;
            }
            const return_message =
                '<span class="text-danger">'
                + 'The given gzip file may be corrupted.<br>'
                + '</span>';
            return return_message;
        },
        invalid_zip: (_log) => {
            const return_message =
                '<span class="text-danger">'
                + 'The file provided is either not a zip or may be corrupted.<br>'
                + '</span>';
            return return_message;
        },
        deduplicate_field_error: (log) => {
            const return_message =
                '<span class="text-danger">'
                + 'Export failed: \'' +log.data.field + '\' is not a valid export field to deduplicate on.<br>'
                + '</span>';
            return return_message;
        },
        create_temporary_table_fail: (log) => {
            return '<span class="text-danger">There was an error creating the join table:<code style="display:block;white-space:pre-wrap;">' +log.data.mysqli_error+ '</code></span>'
        },
        dgq_parse_fail: (log) => {
            return '<span class="text-danger">There was an error exporting. The data governance query failed to parse: <br><code style="display:block;white-space:pre-wrap;">' +log.data.parse?.value+ '</code></span>'
        },
        dgq_lex_fail: (log) => {
            return '<span class="text-danger">There was an error exporting. The data governance query failed to lex: <br><code style="display:block;white-space:pre-wrap;">' +log.data.lex?.value+ '</code></span>'
        },
        dgq_create_fail: (log) => {
            return '<span class="text-danger">There was an error exporting. The data governance query failed to create: <br><code style="display:block;white-space:pre-wrap;">' +log.data.data_governance_query.selector+ '</code></span>'
        },
        dgq_call_fail: (log) => {
            return '<span class="text-danger">There was an error exporting. The data governance query failed to call: <br><code style="display:block;white-space:pre-wrap;">' +log.data.data_governance_query.selector+ '</code></span>'
        },
        preprocess_error: (log) => {
            const return_message =
                '<span class="text-danger">Preprocessing failed.<br><br>The specific error was `'+log.data.preprocess_error.message+'`.<br>Message: \''+log.data.preprocess_error.display_message+'\'<span/>'
            return return_message;
        },
        invalid_credentials: (_log) => {
            return '<span class="text-danger">The authentication credentials that were provided are incorrect.</span>';
        },
        domdocument_max_size: (_log) => {
            return '<span class="text-danger">An Import file exceeded the DomDocument size limit.</span>';
        },
        create_table_error: (_log) => {
            return '<span class="text-danger">Failure creating temp table. The import cannot build right now.</span>';
        },
        ndjson_to_csv: (_log) => {
            return '<span class="text-danger">NDJSON could not be parsed. The import cannot build until this is fixed.</span>';
        },
        json_to_csv: (_log) => {
            return '<span class="text-danger">JSON could not be parsed. The import cannot build until this is fixed.</span>';
        },
        bad_input: (_log) => {
            return '<span class="text-danger">The import file contains XML or JSON syntax errors. The import cannot build until this is fixed.</span>';
        },
        export_json_field_invalid: (log) => {
            return '<span class="text-danger">' + log.data.error_details + '</span>';
        },
        aggregate_duplicate_key: (log) => {
            return '<span class="text-danger">' + log.data.error_details + '</span>';
        },
        publish_and_consume_rabbitmq: (_log) => {
            return '<span class="text-danger">We encountered an error using FeedAi, please try again or contact support</span>';
        }
    };
    $scope.api_logs = {
        'log_display':'all',
        'export_id':-1,
        'import_id':-1,
        'transformer_id':-1,
        'export_ids':[],
        'import_ids':[],
        'transformer_ids':[]
    };

    $scope.route_method_map = {
        'POST|/dbs/:db_id/imports/:import_id' : 'PUT'
    };


    // Pagination Parameters
    $scope.itemsPerPage = 100;
    $scope.currentPage = $location.search().page || 1;
    $scope.maxSize = 5;            // Maximum pages displayed in pagination
    $scope.page_load = false;
    $scope.$watch('currentPage', (new_val, old_val) => {
        if (new_val !== old_val) { // grab/update page num from url
            $location.search('page', new_val);
        }
    });

    $scope.user_timezone = '';
    const tzName_abbr_map = {
        'America/Los_Angeles': 'PST',
        'America/New_York': 'EST',
        'Pacific/Honolulu': 'HST',
        'America/Denver': 'MST',
        'America/Phoenix': 'MST',
        'America/Chicago': 'CST',
        'America/Detroit': 'EST'
    };

    // Find Timezone offset between Client Time and ET
    const et = Date.parse(new Date().toLocaleString('en-US', {timeZone: 'America/New_York'}));
    const utc = Date.parse(new Date().toLocaleString('en-US', {timeZone: 'UTC'}));
    // Timezone offset between UTC and ET in mins
    const utc_et_offset = (utc-et)/60000;
    // Timezone offset between UTC and current timezone in mins
    const current_tz_utc_offset = new Date().getTimezoneOffset();
    // Timezone offset between ET and current timezone in mins
    const current_tz_et_offset = current_tz_utc_offset - utc_et_offset;
    // Timezone name
    const tz_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
    if(typeof tz_name !== 'undefined') {
        if(typeof tzName_abbr_map[tz_name] !== 'undefined'){
            $scope.user_timezone = tzName_abbr_map[tz_name];
        } else {
            $scope.user_timezone = tz_name;
        }
    }

    $scope.getExportEventQueueLogs = function() {
        $scope.loading = true;

        let promise;

        if (!Array.isArray($scope.export_event_queue_exports) || $scope.export_event_queue_exports.length === 0) {
            promise = $http.get('/api.php/dbs/' + databaseId + '/exports', {
                'params': {
                    'log_disabled': true
                }
            }).then((response) => {
                if (response && response.data) {
                    const exports = response.data.filter((exportItem) => {
                        if (!exportItem.protocol_info?.http_body) {
                            return false;
                        }
                        const http_body = exportItem.protocol_info.http_body.
                            replace(/"?feedonomics::data_url::json"?/g, '"feedonomics::data_url::json"').
                            replace(/"?feedonomics::data_url::raw"?/g, '"feedonomics::data_url::raw"');
                        return JSON.parse(http_body).client === 'ShopifyProductFeed';
                    });

                    $scope.api_logs.export_id = null;

                    // While we have a map of exports, we need a separate array of exports
                    // This is used for the Export Event Queue's searchable dropdown of exports - it uses the filter pipe that requires an array
                    $scope.export_event_queue_exports = exports;
                }
            });
        } else {
            promise = $q((resolve) => {
                resolve(null);
            });
        }

        promise.then((_response) => {
            if (!$scope.api_logs.export_id) {
                $scope.logs = [];
                $scope.show_logs = $scope.export_event_queue_exports.length > 0;
                $scope.loading = false;
                return;
            }

            $http.get('/api.php/dbs/'+ $scope.current_db.id +'/exports/'+ $scope.api_logs.export_id +'/export_queue_logs').then((logsResponse) => {
                $scope.logs = logsResponse.data.data;
                $scope.show_logs = true;
                $scope.loading = false;
            }, (_logsResponse) => {
                ngToast.danger({
                    'content': 'Unable to get logs for the selected export'
                });
                $scope.loading = false;
            });
        }, () => {
            ngToast.danger({
                'content': 'Unable to get exports for this database'
            });
            $scope.loading = false;
        });
    }

    $scope.logs = [];
    $scope.all_logs = [];
    $scope.grouped_logs = {};
    $scope.grouped_logs_keys = [];
    $scope.show_logs = false;
    $scope.change_log = function(log_type, page = 1, download = false) {
        $scope.loading = true;
        $scope.currentPage = page;
        $scope.logs = [];
        $scope.grouped_logs = {};
        $scope.grouped_logs_keys = [];
        $scope.show_logs = false;
        $scope.log_type = log_type;
        $scope.oldLogsAlert = false;
        $scope.collapseAll = true;
        $scope.api_logs.log_display = 'all';

        // Reset api_logs import_ids, export_ids and transformer_ids lists
        $scope.api_logs.import_ids = [{import_id: -1, import_name: 'All Imports'}];
        $scope.api_logs.export_ids = [{export_id: -1, export_name: 'All Exports'}];
        $scope.api_logs.transformer_ids = [{transformer_id: -1, transformer_name: 'All Transformers'}];

        // Update the query string to reflect the user's choice.
        $location.search('log_type', log_type);

        // The export event queue logs are self-contained so handled in a separate function
        if (log_type === 'export_event_queue') {
            $scope.api_logs.export_id = null;
            $scope.getExportEventQueueLogs();
            return;
        }

        $scope.getData(log_type, page, download);

    }

    $scope.getData = (log_type, page = 1, download = false) => {

        const sd = new Date($scope.db_filter.start_date);
        const ed = new Date($scope.db_filter.end_date);

        // Call the logs
        const params = {
            'params': {
                'log_type': log_type,
                'page': $scope.currentPage,
                'sort': 'time',
                'log_disabled': true,
                'start_date': sd.getFullYear() + '-' + (sd.getMonth() + 1) + '-' + sd.getDate(),
                'end_date': ed.getFullYear() + '-' + (ed.getMonth() + 1) + '-' + ed.getDate(),
                'download': download,
                'user_timezone': Intl.DateTimeFormat().resolvedOptions().timeZone ?? $scope.user_timezone
            }
        };

        const importIds = [];

        if( !isEmpty($scope.db_filter.importsListSelected) ) {
            importIds.push(...$scope.db_filter.importsListSelected.map((entry) => entry.id));
        }

        if( !isEmpty($scope.db_filter.importIDs) ) {
            importIds.push(...$scope.db_filter.importIDs);
        }

        if( !isEmpty(importIds) ) {
            params.params.import_id = JSON.stringify(importIds);
        }

        const exportIds = [];

        if( !isEmpty($scope.db_filter.exportsListSelected) ) {
            exportIds.push(...$scope.db_filter.exportsListSelected.map((entry) => entry.id));
        }

        if( !isEmpty($scope.db_filter.exportIDs) ) {
            exportIds.push(...$scope.db_filter.exportIDs);
        }

        if( !isEmpty(exportIds) ) {
            params.params.export_id = JSON.stringify(exportIds);
        }

        if( !isEmpty($scope.db_filter.operation)) {
            params.params.message = JSON.stringify($scope.db_filter.operation);
        }

        $http.get('/api.php/dbs/' + databaseId + '/logs', params).then((response) => {
                $scope.processLogsApiResponse(response, log_type, page, download);
            },
            () => {
                ngToast.danger({
                    'content': 'Something is wrong, reach out to Dev for logs on this DB'
                });
                $scope.loading = false;
            });

    }

    $scope.processLogsApiResponse = (response, log_type, page = 1, download = false, resetFilters = true) =>{

            $scope.loading = false;

            // To track imports, exports and transformers that have been added to the filter list
            const import_ids_for_filter = {};
            const export_ids_for_filter = {};
            const transformer_ids_for_filter = {};

            // Render the logs
            $scope.logs = response.data.logs;
            if(typeof $scope.logs === 'undefined') {
                $scope.logs = [];
            }

            $scope.totalItems = response.data.total_logs;

            if( resetFilters ) {

                // reset filters
                $scope.db_filter.actions = [
                    {
                        'value': 'All',
                        'display_name': 'All'
                    }
                ];
                $scope.db_filter.entities = [
                    {
                        'value': 'All',
                        'display_name': 'All'
                    }
                ];
                $scope.db_filter.users = [
                    {
                        'value': 'All',
                        'display_name': 'All'
                    }
                ];
                $scope.db_filter.fields = [
                    {
                        'value': 'All',
                        'display_name': 'All'
                    }
                ];
                $scope.db_filter.statuses = [
                    {
                        'value': 'All',
                        'display_name': 'All'
                    }
                ];

            }

            for (let n = 0; n<$scope.logs.length; n++) {
                // Deal with grouping by date
                const t = $scope.logs[n].time.split(/[- :]/);
                const log_date_obj = new Date(t[0], t[1]-1, t[2], t[3], t[4], t[5]);

                const year = log_date_obj.getFullYear();
                let month = (1 + log_date_obj.getMonth()).toString();
                month = month.length > 1 ? month : '0' + month;
                let day = log_date_obj.getDate().toString();
                day = day.length > 1 ? day : '0' + day;

                // ET Unix Timestamp
                const log_et_linux_timestamp = Date.parse(log_date_obj);
                // Deducting client's offset with ET from ET Timestamp gives Client's Timestamp
                const log_currTz_linux_timestamp = log_et_linux_timestamp - (current_tz_et_offset * 60000);

                const log_date = log_date_obj.getFullYear().toString() + '-' + (log_date_obj.getMonth()+1).toString() + '-' + log_date_obj.getDate().toString();

                // linux timestamp will be key
                // linux_timestamp = Date.parse(log_date.replace(/\-/g,'/'));

                // Get generic timestamp for the log date (Timestamp at 00:00:00) to group logs by date
                const log_currTz_date = new Date(log_currTz_linux_timestamp);
                const log_currTz_date_formatted = log_currTz_date.getFullYear().toString() + '-' + (log_currTz_date.getMonth()+1).toString() + '-' + log_currTz_date.getDate().toString();
                const currTz_log_date_ts = Date.parse(log_currTz_date_formatted.replace(/\-/g,'/'));

                if (! (currTz_log_date_ts in $scope.grouped_logs) ) {
                    $scope.grouped_logs[currTz_log_date_ts] = [];
                }

                // ensure all logs collapsed by default
                $scope.logs[n].expanded = false;

                $scope.logs[n]['group_date'] = currTz_log_date_ts;
                $scope.logs[n]['pretty_date'] = log_currTz_linux_timestamp;
                // format time of event
                $scope.logs[n]['time'] = log_currTz_linux_timestamp;
                // modify the log messages to be human readable

                if(log_type === 'imports_exports'){
                    if($scope.logs[n].data && $scope.logs[n].data.hasOwnProperty('old_message')) {
                        $scope.logs[n].display_message = $scope.logs[n].data['old_message'];
                    }
                    else if (get_template_cb.hasOwnProperty($scope.logs[n].message)) {
                        // try-catch in case logs[n].data is null (not just string null), in which case get_template_cb
                        // function will throw an exception
                        try {
                            let log = $scope.logs[n];
                            if (log.data === 'null') {
                                log = {
                                    ...log,
                                    data: null
                                };
                            }
                            $scope.logs[n].display_message = get_template_cb[$scope.logs[n].message](log);
                        } catch(error) {
                            $scope.logs[n].display_message = `<span>${$scope.no_logs_text}</span>`;
                        }
                    } else {
                        $scope.logs[n].display_message = '<span class="text-danger">Please contact support.</span>';
                        $scope.logs[n].message = 'internal_failure';
                    }
                    $scope.logs[n].message = OPERATION_NAMES_MAP.get($scope.logs[n].message) ?? $scope.logs[n].message;

                    if($scope.logs[n].export_id !== 0) {
                        $scope.logs[n].export_name = $scope.exports[$scope.logs[n].export_id] ? $scope.exports[$scope.logs[n].export_id].name : '';
                    }
                    if($scope.logs[n].import_id !== 0) {
                        $scope.logs[n].import_name = $scope.imports[$scope.logs[n].import_id] ? $scope.imports[$scope.logs[n].import_id].name : '';
                    }

                    if ($scope.logs[n].data && $scope.logs[n].data.hasOwnProperty('imports')) {
                        Object.keys($scope.logs[n].data.imports).forEach((import_id) => {
                            if ($scope.imports.hasOwnProperty(import_id)) {
                                $scope.logs[n].data.imports[import_id].name = $scope.imports[import_id].name;
                            }
                            else {
                                $scope.logs[n].data.imports[import_id].name = 'Deleted Import';
                            }
                        });
                    }
                }
                else if (log_type === 'ebs_logs') {
                    // TODO: human_friendly_errors CatalogException messages.
                }
                else if (log_type === 'feed_alerts') {
                    const status_map = {
                        threshold_passed: 'Passed',
                        threshold_failed: 'Failed',
                        google_exception_thrown: 'Google Exception Thrown',
                        google_service_exception_thrown: 'Google Exception Thrown',
                        invalid_authentication: 'Invalid Authentication'
                    }

                    // var payload_json = JSON.parse($scope.logs[n]['payload_json']);
                    let payload_json;

                    try {
                        payload_json = JSON.parse($scope.logs[n]['payload_json']);
                    } catch (e) {
                        payload_json = {};
                    }

                    let raw_response;

                    try {
                        raw_response = JSON.parse($scope.logs[n]['raw_response']);
                    } catch (e) {
                        raw_response = {};
                    }

                    $scope.logs[n]['total_errors'] = '0';
                    if (payload_json.hasOwnProperty('overall')) {
                        $scope.logs[n]['total_errors'] = payload_json.overall.count;
                    }

                    $scope.logs[n]['exception_json'] = false;
                    if (payload_json.hasOwnProperty('error')) {
                        $scope.logs[n]['exception_json'] = payload_json.error.errors;
                        delete payload_json.error;
                    }
                    $scope.logs[n]['total_products'] = '0';
                    $scope.logs[n]['errors'] = [];
                    if (payload_json.hasOwnProperty('total_products')) {
                        $scope.logs[n]['total_products'] = payload_json['total_products']['count'];
                        Object.keys(payload_json).forEach((key) => {
                            const value = payload_json[key];
                            let human_friendly_error = key;
                            if (HUMAN_FRIENDLY_ERRORS_MAP.has(key)) {
                                human_friendly_error = HUMAN_FRIENDLY_ERRORS_MAP.get(key);
                            }
                            $scope.logs[n]['errors'].push({
                                'message': human_friendly_error,
                                'status': value.hasOwnProperty('status') ? value.status : 'unset',
                                'count': value.count
                            });
                        });
                    }

                    // If it's an old log
                    if (!raw_response.hasOwnProperty('account_status') || !raw_response.account_status.hasOwnProperty('products')) {
                        raw_response = {};
                        raw_response.account_status = {
                            'products': [
                                {
                                    'channel' : 'Unknown',
                                    'destination' : 'unknown',
                                    'country' : 'N/A',
                                    'statistics' : {
                                        'active' : $scope.logs[n]['total_products'],
                                        'disapproved' : $scope.logs[n]['total_errors'],
                                        'expiring' : 0,
                                        'pending' :    0
                                    },
                                    'itemLevelIssues' : []
                                }
                            ]
                        };
                    }
                    $scope.logs[n]['raw_response'] = raw_response;
                    $scope.logs[n]['payload_json'] = payload_json;

                    // calculate error rate here and add to log so it's keyword searchable
                    const products_keys = $scope.logs[n]['raw_response'].account_status && $scope.logs[n]['raw_response'].account_status.products ? Object.keys($scope.logs[n]['raw_response'].account_status.products) : [];
                    if (products_keys.length > 0 && typeof payload_json === 'object') {
                        products_keys.forEach((index) => {
                            const feed_errors = $scope.get_feed_errors($scope.logs[n]['raw_response'].account_status.products[index]);
                            const feed_size = $scope.get_feed_size($scope.logs[n]['raw_response'].account_status.products[index]);
                            let error_rate = 'n/a';
                            const feed_key = $scope.logs[n]['raw_response'].account_status.products[index].channel + '-' + $scope.logs[n]['raw_response'].account_status.products[index].country + '-' + $scope.logs[n]['raw_response'].account_status.products[index].destination;
                            let status = 'pass';
                            if (payload_json.hasOwnProperty(feed_key)) {
                                Object.values(payload_json[feed_key]).some((error_object) => {
                                    if (error_object.status === 'fail') {
                                        status = 'fail';
                                        return true;
                                    }
                                    return false;
                                });
                            }
                            if (feed_size != 0) {
                                error_rate = $filter('number')(feed_errors / feed_size  * 100, 1) + '%';
                            }
                            $scope.logs[n]['raw_response'].account_status.products[index].error_rate = error_rate;
                            $scope.logs[n]['raw_response'].account_status.products[index].feed_errors = feed_errors;
                            $scope.logs[n]['raw_response'].account_status.products[index].feed_size = feed_size;
                            $scope.logs[n]['raw_response'].account_status.products[index].feed_status = status;
                        });
                    }

                    // put status in the log json so it can be searched/filtered
                    if (status_map.hasOwnProperty($scope.logs[n]['message'])) {
                        $scope.logs[n]['status'] = status_map[$scope.logs[n]['message']];
                    }

                    const status_check = $scope.db_filter.statuses.filter((obj) => obj.value == $scope.logs[n].status);
                    if(status_check.length === 0) {
                        $scope.db_filter.statuses.push({'display_name':$scope.logs[n].status,value:$scope.logs[n].status});
                    }
                }
                else if(log_type=='api' || log_type == 'change_logs'){
                    // Make pretty json for displaying on UI
                    if(log_type == 'api'){
                        $scope.logs[n].pretty_request_body = prettyJSON($scope.logs[n].request_body);
                        $scope.logs[n].pretty_request_params = prettyJSON($scope.logs[n].request_params);
                        $scope.logs[n].pretty_response_body = prettyJSON($scope.logs[n].response_body);
                    }

                    const logsAPI = LOGS_API_REQUESTS_MAP.get($scope.logs[n].static_api_url);

                    // Populate action, category and display_api_request
                    if(typeof logsAPI !== 'undefined') {
                        $scope.logs[n].category = logsAPI.category;
                        $scope.logs[n].action = logsAPI.action;
                        $scope.logs[n].display_api_request = logsAPI.display;
                    } else {
                        $scope.logs[n].category = 'unknown';
                        $scope.logs[n].action = 'unknown';
                        $scope.logs[n].display_api_request = `Not found: ${$scope.logs[n].static_api_url}`;
                    }

                    // Add unique import_ids, export_ids and transformer_ids in objects to be used as filters in UI
                    if($scope.logs[n].hasOwnProperty('import_id') && $scope.logs[n].hasOwnProperty('import_name')){
                        if(typeof import_ids_for_filter[$scope.logs[n].import_id] === 'undefined') {
                            const import_obj = {import_id:$scope.logs[n].import_id,import_name:$scope.logs[n].import_name};
                            $scope.api_logs.import_ids.push(import_obj);
                            import_ids_for_filter[$scope.logs[n].import_id] = 1;
                        }
                    }
                    if($scope.logs[n].hasOwnProperty('export_id') && $scope.logs[n].hasOwnProperty('export_name')){
                        if(typeof export_ids_for_filter[$scope.logs[n].export_id] === 'undefined') {
                            const export_obj = {export_id:$scope.logs[n].export_id,export_name:$scope.logs[n].export_name};
                            $scope.api_logs.export_ids.push(export_obj);
                            export_ids_for_filter[$scope.logs[n].export_id] = 1;
                        }
                    }
                    if($scope.logs[n].hasOwnProperty('transformer_id')){
                        if(typeof transformer_ids_for_filter[$scope.logs[n].transformer_id] === 'undefined') {
                            const transformer_obj = {transformer_id:$scope.logs[n].transformer_id,transformer_name:$scope.logs[n].transformer_field+' (Sort Order: '+$scope.logs[n].transformer_sort_order+')'};
                            $scope.api_logs.transformer_ids.push(transformer_obj);
                            transformer_ids_for_filter[$scope.logs[n].transformer_id] = 1;
                        }
                    }

                    // Extra logic for change_logs
                    // Change_logs fetch same data as api logs - Displaying on UI is a little different
                    // The following logic handles that part
                    if(log_type === 'change_logs') {

                        $scope.logs[n].changed_keys = [];

                        let request_body_obj = {};
                        let request_params_obj = {};
                        let response_body_obj = {};
                        let prior_state_obj = {};

                        if($scope.logs[n].request_body != ''){
                            request_body_obj = JSON.parse($scope.logs[n].request_body);
                        }
                        if($scope.logs[n].request_params != ''){
                            request_params_obj = JSON.parse($scope.logs[n].request_params);
                        }
                        if($scope.logs[n].response_body != ''){
                            response_body_obj = JSON.parse($scope.logs[n].response_body);
                        }
                        if($scope.logs[n].prior_state != ''){
                            prior_state_obj = JSON.parse($scope.logs[n].prior_state);
                        }

                        let log_method = $scope.logs[n].method;
                        if(typeof $scope.route_method_map[$scope.logs[n].static_api_url] !== 'undefined') {
                            log_method = $scope.route_method_map[$scope.logs[n].static_api_url];
                        }
                        else if (
                            $scope.logs[n].static_api_url === 'POST|/dbs/:db_id/transformed_data_overrides/:export_id'
                            || $scope.logs[n].static_api_url === 'POST|/dbs/:db_id/transformed_data_overrides'
                        ) {
                            // hacky solution to get override logs to display correctly
                            $scope.logs[n].action = 'create';
                            if ($scope.logs[n].prior_state != '[]') {
                                log_method = 'PUT';
                                $scope.logs[n].action = 'update';
                            }
                            if ($scope.logs[n].response_body == '{\"response\":\"Resource Deleted\"}') {
                                log_method = 'DELETE';
                                $scope.logs[n].action = 'delete';
                            }
                        }
                        $scope.logs[n].log_method = log_method;

                        if(log_method === 'POST') {
                            if(response_body_obj && typeof response_body_obj === 'object') {
                                Object.keys(response_body_obj).forEach((key) => {
                                    $scope.add_changed_key($scope.logs[n], key, '', response_body_obj[key], log_method);
                                });
                            }
                        }
                        else if(log_method == 'PUT') {
                            // For all keys in response, check if they were modified or not
                            if(response_body_obj && typeof response_body_obj === 'object') {
                                Object.keys(response_body_obj).forEach((key) => {
                                    if(prior_state_obj && typeof prior_state_obj !== 'undefined' && typeof prior_state_obj[key] !== 'undefined') {
                                        // If new state is different from prior state
                                        if(prior_state_obj[key] != response_body_obj[key]){
                                            // For Update Transformer Sort Order and DB Fields Update we need to display Field name too
                                            const required_field_routes = [
                                                'PUT|/dbs/:db_id/transformers/:transformer_id/sort_order',
                                                'PUT|/dbs/:db_id/db_fields/'
                                            ];
                                            const requires_field_name = required_field_routes.indexOf($scope.logs[n].static_api_url) >= 0;
                                            if(requires_field_name) {
                                                $scope.add_changed_key($scope.logs[n], 'field_name', prior_state_obj['field_name'], response_body_obj['field_name'], log_method);
                                            }
                                            $scope.add_changed_key($scope.logs[n], key, prior_state_obj[key], response_body_obj[key], log_method);
                                        }
                                    } else {
                                        // Prior state for key not found, set it as unknown
                                        $scope.add_changed_key($scope.logs[n], key, 'Unknown', response_body_obj[key], log_method);
                                    }
                                });
                            }
                        }
                        else if(log_method == 'DELETE') {
                            if(prior_state_obj && typeof prior_state_obj === 'object') {
                                Object.keys(prior_state_obj).forEach((key) => {
                                    $scope.add_changed_key($scope.logs[n], key, prior_state_obj[key], '', log_method);
                                });
                            }
                        }

                        // populate filter options
                        const action_check = $scope.db_filter.actions.filter((obj) => obj.value === $scope.logs[n].action);
                        if(action_check.length === 0) {
                            $scope.db_filter.actions.push({'display_name':$scope.logs[n].action,value:$scope.logs[n].action});
                        }
                        const entity = !$scope.logs[n].display_api_request || $scope.logs[n].display_api_request.indexOf('Not found') === 0 ? 'Not found' : $scope.logs[n].display_api_request;
                        const entity_check = $scope.db_filter.entities.filter((obj) => obj.value === entity);
                        if(entity_check.length === 0) {
                            $scope.db_filter.entities.push({'display_name':entity,value:entity});
                        }
                        const user_check = $scope.db_filter.users.filter((obj) => obj.value === $scope.logs[n].api_user_name);
                        if(user_check.length === 0) {
                            $scope.db_filter.users.push({'display_name':$scope.logs[n].api_user_name,value:$scope.logs[n].api_user_name});
                        }
                    }
                }
                else if(log_type === 'transformers') {
                    let request_body_obj = {};
                    let response_body_obj = {};
                    let prior_state_obj = {};

                    if($scope.logs[n].request_body !== ''){
                        request_body_obj = JSON.parse($scope.logs[n].request_body);
                    }
                    if($scope.logs[n].response_body !== ''){
                        response_body_obj = JSON.parse($scope.logs[n].response_body);
                    }
                    if($scope.logs[n].prior_state !== ''){
                        prior_state_obj = JSON.parse($scope.logs[n].prior_state);
                    }

                    // Format and set transformer log data to display new and old state for each transformer
                    if($scope.logs[n].action === 'create') {
                        $scope.set_transformer_log_data('old', $scope.logs[n], null);
                        $scope.set_transformer_log_data('new', $scope.logs[n], response_body_obj);
                    }
                    else if($scope.logs[n].action === 'update') {
                        $scope.set_transformer_log_data('old', $scope.logs[n], prior_state_obj);
                        $scope.set_transformer_log_data('new', $scope.logs[n], response_body_obj);
                    }
                    else if($scope.logs[n].action === 'delete') {
                        $scope.set_transformer_log_data('old', $scope.logs[n], prior_state_obj);
                        $scope.set_transformer_log_data('new', $scope.logs[n], null);
                    }

                    // populate filter options
                    const action_check = $scope.db_filter.actions.filter((obj) => obj.value === $scope.logs[n].action);
                    if(action_check.length === 0) {
                        $scope.db_filter.actions.push({'display_name':$scope.logs[n].action,value:$scope.logs[n].action});
                    }
                    const field_check = $scope.db_filter.fields.filter((obj) => obj.value === $scope.logs[n].field_name_new);
                    if(field_check.length === 0) {
                        $scope.db_filter.fields.push({'display_name':$scope.logs[n].field_name_new,value:$scope.logs[n].field_name_new});
                    }
                    const user_check = $scope.db_filter.users.filter((obj) => obj.value === $scope.logs[n].api_user_name);
                    if(user_check.length === 0) {
                        $scope.db_filter.users.push({'display_name':$scope.logs[n].api_user_name,value:$scope.logs[n].api_user_name});
                    }
                }
                $scope.grouped_logs[currTz_log_date_ts].push($scope.logs[n]);
            }

            if(download === true && $scope.logs.length > 0) {
                const file_data = [];

                switch (log_type) {
                    case 'imports_exports':
                        file_data.push(['time','name','operation','message']);
                        $scope.logs.forEach((log) => {
                            let log_name = '';
                            if(log.import_id !== 0) {
                                if($scope.imports.hasOwnProperty(log.import_id)) {
                                    log_name = $scope.imports[log.import_id].name;
                                }
                                else {
                                    log_name = 'Deleted Import';
                                }
                            }
                            if(log.export_id !== 0) {
                                if($scope.exports.hasOwnProperty(log.export_id)) {
                                    log_name = $scope.exports[log.export_id].name;
                                }
                                else {
                                    log_name = 'Deleted Export';
                                }
                            }
                            file_data.push([new Date(log.time), log_name, log.message,log.display_message]);
                        });
                        break;
                    case 'feed_alerts':

                        const headers = Object.keys($scope.logs[0]);
                        const export_blacklist = [
                            '$$hashKey',
                            'url',
                            'static_api_url',
                            'db_id',
                            'created_resource_id',
                            'api_user_id',
                            'request_params',
                            'request_body',
                            'method',
                            'log_method',
                            'response_status',
                            'response_time',
                            'response_body',
                            'prior_state'
                        ];

                        export_blacklist.forEach((bad_key) => {
                            if(headers.indexOf(bad_key) !== -1) {
                                headers.splice(headers.indexOf(bad_key), 1);
                            }
                        });
                        file_data.push(headers);

                        $scope.logs.forEach((log) => {

                            const temp_log = angular.copy(log);
                            const temp_row = [];
                            headers.forEach((key) => {
                                if(typeof temp_log[key] === 'object') {
                                    temp_row.push(JSON.stringify(log[key]));
                                }
                                else if(
                                    key.indexOf('date') !== -1
                                    ||
                                    key.indexOf('time') !== -1
                                    &&
                                    !isNaN(temp_log[key])
                                ) {
                                    const ts = new Date(temp_log[key]);
                                    temp_row.push(ts.toLocaleString());
                                }
                                else if(typeof temp_log[key] === 'undefined') {
                                    temp_log[key] = '';
                                    temp_row.push(temp_log[key]);
                                }
                                else {
                                    temp_row.push(temp_log[key]);
                                }
                            });
                            file_data.push(temp_row);
                        });
                        break;
                    case 'change_logs':
                        file_data.push(['time','action','module','user','change']);
                        $scope.logs.forEach((log) => {
                            let changes = '';
                            for(let i = 0; i < log.changed_keys.length; i++) {
                                changes += log.changed_keys[i].key_name + ': ';
                                if(log.log_method != 'POST') {
                                    changes += log.changed_keys[i].old_val + '(old) ';
                                }
                                if(log.log_method != 'DELETE') {
                                    changes += log.changed_keys[i].new_val + '(new) ';
                                }
                            }
                            file_data.push([new Date(log.time),log.action,log.display_api_request,log.api_user_name,changes]);
                        });
                        break;
                    case 'transformers':
                        file_data.push(['time','user','action','export','field','old','new']);
                        $scope.logs.forEach((log) => {
                            const field_name = log.action === 'delete' ? log.field_name_old : log.field_name_new;
                            const export_name = log.export_name ?? 'All';
                            file_data.push([new Date(log.time),log.api_user_name,log.action,export_name,field_name,
                                'if: ' + log.selector_old + ' | then: ' + log.transformer_old
                                + ' | order: ' + log.sort_order_old + ' | enabled: ' + log.enabled_old,
                                'if: ' + log.selector_new + ' | then: ' + log.transformer_new
                                + ' | order: ' + log.sort_order_new + ' | enabled: ' + log.enabled_new]);
                        });
                        break;
                    default:
                        break;
                }
                CSVService.download(log_type + '_logs.csv', file_data);
            }

            $scope.show_logs = true;
            $scope.log_type = log_type;
            $scope.grouped_logs_keys = Object.keys($scope.grouped_logs).sort().reverse();

            // $http.get('/api.php/dbs/' + databaseId + '/oldest_log', {'params': {'log_type':$scope.log_type}}).then( response => {
            //     const logOlderThanAYear = moment(response.data.time).isSameOrBefore( moment().subtract(1, 'year') );
            //     if (logOlderThanAYear) {
            //         $scope.oldLogsAlert = true;
            //     }
            // });
    }

    $scope.show_transformer_log_old = function(log) {
        const has_old_data = log.selector_old
            || log.transformer_old
            || log.sort_order_old
            || log.enabled_old;

        return log.action === 'create' || has_old_data
    };

    $scope.show_transformer_log_new = function(log) {
        const has_new_data = log.selector_new
            || log.transformer_new
            || log.sort_order_new
            || log.enabled_new;

        return log.action === 'delete' || has_new_data
    };

    $scope.download_logs = function() {
        $scope.change_log($scope.log_type, 1, true);
    };

    $scope.add_changed_key = function(changedKeysObj, key_name, old_val, new_val, log_method) {
        if(key_name === 'export_id'){
            if(old_val === '0'){
                old_val = 'All Exports';
            }
            if(new_val === '0') {
                new_val = 'All Exports';
            }
        }

        // hide unnecessary json from logs
        if(new_val) {
            if(new_val.db) {
                if (new_val.db.name) {
                    new_val.db = new_val.db.name;
                }
                else{
                    new_val.db = '*Unavailable*';
                }
            }
            if(new_val.export) {
                if(new_val.export.name){
                    new_val.export = new_val.export.name;
                }
                else {
                    new_val.export = '*Unavailable*';
                }
            }
        }

        old_val = prettyJSON(old_val);
        new_val = prettyJSON(new_val);

        changedKeysObj.changed_keys.push({key_name: key_name, old_val: old_val, new_val: new_val});
    };

    $scope.set_transformer_log_data = function(suffix, log_data, raw_data) {
        if(suffix !== 'new' && suffix !== 'old')
            return;

        const fields = ['field_name', 'sort_order', 'selector', 'transformer', 'enabled'];

        for(let i=0; i < fields.length; i++) {
            if(!raw_data || typeof raw_data[fields[i]] === 'undefined') {
                log_data[fields[i] + '_' + suffix] = '';
            } else {
                log_data[fields[i] + '_' + suffix] = raw_data[fields[i]];
            }
        }
    };

    $scope.pageChanged = function() {
        $scope.change_log($scope.log_type, $scope.currentPage);
    };

    // initialize function with imports_exports
    $scope.log_type_map = {
        imports_exports: 'Import / Export',
        transformers: 'Transformer',
        feed_alerts: 'Google Merchant Center',
        api: 'API',
        change_logs: 'Change',
        export_event_queue: 'Export Event Queue'
    };

    // If a specific log is set in the get string, ensure it's a viable one
    if(
        typeof(logType) !== 'undefined'
        && typeof($scope.log_type_map[logType]) !== 'undefined'
    ) {
        $scope.log_type = logType;
    } else {
        // Otherwise, default to the import and export logs
        $scope.log_type = 'imports_exports';
    }

    // Get imports/exports and cache the results
    $scope.imports = {};    // A map used to cache imports. Property key = import id. Value = import
    $scope.exports = {};    // A map used to cache exports. Property key = export id. Value = export

    // This is used for the Export Event Queue's searchable dropdown of exports - it uses the filter pipe that requires an array
    $scope.export_event_queue_exports = [];

    $q.all({
        imports_response: $http.get('/api.php/dbs/' + databaseId + '/imports', {'params': {'log_disabled':true}}),
        exports_response: $http.get('/api.php/dbs/' + databaseId + '/exports', {'params': {'log_disabled':true}})
    }).then( ({imports_response, exports_response}) => {

        $scope.importsList = deepCopy(imports_response.data); // array to populate the ng-select of imports
        $scope.exportsList = deepCopy(exports_response.data); // array to populate the ng-select of exports

        for (let n=0; n<imports_response.data.length; n++) {
            $scope.imports[imports_response.data[n].id] = imports_response.data[n];
        }
        for (let n=0; n<exports_response.data.length; n++) {
            $scope.exports[exports_response.data[n].id] = exports_response.data[n];
        }
        $scope.change_log($scope.log_type, $scope.currentPage);
    });

    $scope.check_day_length = function(logs, day) {
        $scope['show_'+ day] = false;
        if(typeof logs !== 'undefined') {
            for (let n=0; n<logs.length; n++) {
                if(logs[n].group_date === day) {
                    return true;
                }
            }
        }
        return false;
    }
    $scope.api_display_filter = function(api_log){
        if ($scope.api_logs.log_display === 'exports'){
            if (api_log.category !== 'exports') return false;
            const export_filter =
                $scope.api_logs.export_id > 0
                && (!api_log.hasOwnProperty('export_id')
                || api_log.export_id !== $scope.api_logs.export_id);
            if (export_filter) return false;
        }
        else if ($scope.api_logs.log_display === 'transformers'){
            if (api_log.category !== 'transformers') return false;
            const transformer_filter =
                $scope.api_logs.transformer_id > 0
                && (!api_log.hasOwnProperty('transformer_id')
                || api_log.transformer_id !== $scope.api_logs.transformer_id);
            if (transformer_filter) return false;
        }
        else if ($scope.api_logs.log_display === 'imports'){
            if (api_log.category !== 'imports') return false;
            const import_filter =
                $scope.api_logs.import_id > 0
                && (!api_log.hasOwnProperty('import_id')
                || api_log.import_id !== $scope.api_logs.import_id);
            if (import_filter) return false;
        }

        const log_date_obj = new Date(api_log.time);
        // $scope['show_'+ api_log.group_date] = false;
        if($scope.log_type ==='api' || $scope.log_type === 'change_logs') {
            // Filter Check
            if($scope.db_filter.field !== 'All' && $scope.db_filter.field !== api_log.field_name_new) {
                return false;
            }
            if($scope.db_filter.action !== 'All' && $scope.db_filter.action !== api_log.action) {
                return false;
            }
            const entity = !api_log.display_api_request || api_log.display_api_request.indexOf('Not found') === 0 ? 'Not found' : api_log.display_api_request;
            if($scope.db_filter.entity !== 'All' && $scope.db_filter.entity !== entity) {
                return false;
            }
            if($scope.db_filter.user !== 'All' && $scope.db_filter.user !== api_log.api_user_name) {
                return false;
            }
        }
        else if($scope.log_type === 'transformers') {
            // Filter Check
            if($scope.db_filter.field !== 'All' && $scope.db_filter.field !== api_log.field_name_new) {
                return false;
            }
            if($scope.db_filter.action !== 'All' && $scope.db_filter.action !== api_log.action) {
                return false;
            }
            if($scope.db_filter.user !== 'All' && $scope.db_filter.user !== api_log.api_user_name) {
                return false;
            }
        }
        else if($scope.log_type === 'feed_alerts') {
            if($scope.db_filter.status !== 'All' && $scope.db_filter.status !== api_log.status) {
                return false;
            }
        }

        if(!$scope.db_filter.start_date) {
            $scope.db_filter.start_date = $scope.db_filter.min_date;
        }
        if(!$scope.db_filter.end_date) {
            $scope.db_filter.end_date = $scope.db_filter.max_date;
        }
        let show_log = $scope.db_filter.start_date.getTime() <= log_date_obj.getTime()
            &&
            $scope.db_filter.end_date.getTime() > log_date_obj.getTime();
        if(
            typeof $scope.db_filter.keyword !== 'undefined'
            &&
            $scope.db_filter.keyword !== ''
            &&
            show_log === true
        ) {
            show_log = JSON.stringify(api_log).toLowerCase().indexOf($scope.db_filter.keyword.toLowerCase()) >= 0;
        }

        if(show_log === true) {
            $scope['show_'+ api_log.group_date] = true;
            return true;
        }
    }

    $scope.openRawLogModal = (rawResponse) => {
        NgbModalService.open(LogsInfoModalComponent, {
            resolve: {
                raw: rawResponse
            },
            size: 'lg',
            scrollable: true
        });
    }

    $scope.addTag = (item) => {

        const number = parseInt(item,10);

        if(isNaN(number) ) {
            return false;
        }

        return number;

    }

    $scope.importsExportsOnChange = () => {
        $scope.change_log($scope.log_type)
    }

}]);
