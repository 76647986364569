import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CategoryPath } from '@app/category-taxonomy/services/responses/category-paths.response';

@Component({
    standalone: true,
    selector: 'fdx-category-paths',
    templateUrl: './category-paths.component.html',
    styleUrls: ['./category-paths.component.scss'],
    imports: [
        CommonModule
    ]
})
export class CategoryPathsComponent {

    @Input() categoryPaths: CategoryPath[];
    @Input() showExtraPaths: boolean = false;
    @Input() expandable: boolean = false;

    @Input() expanded: boolean = false;
    @Output() readonly expandedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    trackByIndex(index: number): number {
        return index;
    }

    toggleExpand(): void {
        this.expanded = !this.expanded;
        this.expandedChange.emit(this.expanded);
    }
}
