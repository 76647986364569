import { CdkDragDrop, CdkDragStart, moveItemInArray } from '@angular/cdk/drag-drop';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { isEmpty } from '@app/core/functions/isEmpty';
import { DbFieldModel } from '@app/databases/models/db-field.model';
import { ExportColumnType, ExportField } from '@app/exports/services/responses/get-exports.response';
import { IconDefinition, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faSortAlt } from '@fortawesome/pro-solid-svg-icons';
import { DropdownPosition } from '@ng-select/ng-select';

@Component({
    selector: 'fdx-export-fields',
    templateUrl: 'export-fields.component.html',
    styleUrls: ['export-fields.component.scss']
})
export class ExportFieldsComponent implements OnChanges {

    @Input() fields: ExportField[] = [];
    @Output() readonly fieldsChange: EventEmitter<ExportField[]> = new EventEmitter<ExportField[]>();

    @Input() dbFields: DbFieldModel[] = [];
    @Input() showRequired: boolean = false;
    @Input() deltaExport: string = '0';
    @Input() existingExport?: boolean = false;
    @Input() expanded?: boolean = false;

    @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;

    xIcon: IconDefinition = faTimes;
    sortAltIcon: IconDefinition = faSortAlt;
    showFieldsList: boolean = true;
    scrollingFieldsList: boolean = false;

    colorMap: Record<ExportColumnType, string> = {
        '': '',
        'required': 'text-primary',
        'recommended': 'text-success',
        'usually required': 'text-primary',
        'sometimes required': 'text-primary',
        'optional': 'text-secondary'
    };

    idKey: string = 'fdx-export-fields-index-';
    itemSize: number = 44;    // 36px tall with 4px padding on either side makes for overall height of one scrollable item 44px
    bufferItemOffset: number = 5;
    bufferPx: number = this.itemSize * this.bufferItemOffset;
    lastDraggedIndex: number;
    searchInput: string = '';
    filteredFieldsCount: number = null;
    filteredFields: any = [];
    fieldsAddedDuringSearchCount: number = 0;

    ngOnChanges({ deltaExport, fields, expanded }: SimpleChanges): void {
        if (deltaExport?.currentValue || fields?.currentValue) {
            this.addExportFieldRow();
        }

        if (expanded && !expanded.firstChange) {
            this.reload(0);
        }

        if (fields?.currentValue && fields.firstChange === true) {
            this.updateFilteredFields('');
        }

        if (fields?.currentValue && fields?.previousValue) {
            this.updateFilteredFields(this.searchInput);
        }
    }

    reload(index: number = null): void {

        this.showFieldsList = false;

        setTimeout(() => {
            this.showFieldsList = true;

            // Scroll to index if it was defined
            if (index !== null) {
                this.scrollingFieldsList = true;
                setTimeout(() => {
                    this.viewport?.scrollToIndex(index);
                    setTimeout(() => {
                        this.scrollingFieldsList = false;
                    });
                });
            }
        });
    }

    drop(event: CdkDragDrop<any[]>): void {
        // Calculate absolute distance moved (intervals of at least 44), then reapply sign for moving forward or backwards
        const difference: number = Math.round(Math.abs(event.distance.y) / this.itemSize) * Math.sign(event.distance.y);
        const currentIndex: number = this.lastDraggedIndex + difference;

        if (this.lastDraggedIndex !== currentIndex) {
            const newArray = [...this.fields];
            moveItemInArray(newArray, this.lastDraggedIndex, currentIndex);
            this.fields = [...newArray];
            this.fieldsChangeHandler();

            let scrollIndex;
            if (this.viewport.getRenderedRange().start <= this.bufferItemOffset) {
                // If the rendered start is less than our buffered size of five, just scroll to the top (index 0)
                scrollIndex = 0;
            } else {
                // Otherwise, add our known buffer (in number of items) to the rendered range so the scroll will match up to when the item was dropped
                scrollIndex = this.viewport.getRenderedRange().start + this.bufferItemOffset;
            }

            this.reload(scrollIndex);
        }
        this.lastDraggedIndex = null;
    }

    dragStarted(event: CdkDragStart): void {
        this.lastDraggedIndex = this.getDragItemIndex(event.source.element.nativeElement.id);
    }

    fieldsChangeHandler(): void {
        this.fieldsChange.emit(this.fields);
    }

    onInputChangeHandler(index: number): void {
        this.addExportFieldRow();
        this.fields[index].export_field_name = this.fields[index].export_field_name.trim();
        this.fieldsChangeHandler();
        if (this.searchActive) {
            this.filteredFieldsCount = this.getFilteredFieldsCount;
        }
    }

    // automatically add export field rows when last row is not empty or delta_export change
    addExportFieldRow(): void {

        if (this.deltaExport === '0') {
            this.removeFieldByName('{{feedonomics::delta_status}}');
        } else {

            const found = this.fields.find((field) => {
                return field.field_name === '{{feedonomics::delta_status}}';
            });

            if (!found) {

                // add delta status
                this.addField({
                    field_name: '{{feedonomics::delta_status}}',
                    export_field_name: 'delta_status',
                    sort_order: '0'
                });

            }

        }

        this.removeBlanks();

        this.addBlankField();
    }

    addBlankField(): void {
        if( this.fields.length === 0 ) {
            this.addField({ field_name: '', export_field_name: '', sort_order: '' });
            return;
        }

        const last_index = this.fields.length - 1;

        if (this.fields[last_index].field_name !== '' || this.fields[last_index].export_field_name !== '') {
            this.addField({ field_name: '', export_field_name: '', sort_order: '' });
            this.reload(this.fields?.length - 1);
            if (this.searchActive) {
                this.fieldsAddedDuringSearchCount += 1;
                this.filteredFields = this.getFilteredFields;
            }
        }
    }

    addField(field): void{
        this.fields.push(field);
    }

    removeBlanks(): void {

        if (this.searchActive) {
            return;
        }

        const last_index = this.fields.length - 1;

        // Remove in place, do not use filter, do not change the reference to this.fields
        let reloadList = false;
        for (let i = this.fields.length - 1; i >= 0; i--) {
            if (this.fields[i].field_name === '' && this.fields[i].export_field_name == '' && i !== last_index) {
                this.fields.splice(i, 1);
                reloadList = true;
            }
        }
        if (reloadList) {
            this.reload();
        }
    }

    removeFieldByName(fieldName: string): void {
        // Remove in place, do not use filter, do not change the reference to this.fields
        for (let i = this.fields.length - 1; i >= 0; i--) {
            if (this.fields[i].field_name === fieldName) {
                this.fields.splice(i, 1);
            }
        }
    }

    removeField(fieldToRemove: ExportField): void {

        this.fields = this.fields.filter((field) => {
            return field !== fieldToRemove;
        });

        this.reload();
        this.fieldsChangeHandler();

    }

    getExportFieldClass(): string {
        return this.showRequired ? "col-4" : "col-6";
    }

    getColor(required): string {
        return this.colorMap[required];
    }

    getDragItemId(index: number): string {
        return `${this.idKey}${index}`;
    }

    getDragItemIndex(id: string): number {
        return Number(id.substring(this.idKey.length));
    }

    getFieldValue(field): string {
        return field.field_name === "" ? null : field.field_name;
    }

    setFieldValue($event: string, field: any): void {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        field.field_name = isEmpty($event) ? '' : $event;
    }

    // Temporary until we put the whole app on Angular 13 and can use ng-select appendTo for the dropdown option
    getDropdownPosition(index: number): DropdownPosition {
        if (this.fields.length > 10 && this.viewport.getRenderedRange().end - index < 9) {
            return 'top';
        }
        return 'bottom';
    }

    updateFilteredFields($searchEvent: string): any {

        if ($searchEvent !== this.searchInput) {
            this.fieldsAddedDuringSearchCount = 0;
        }

        this.searchInput = $searchEvent;

        if (this.fields && this.searchInput) {
            this.filteredFieldsCount = this.getFilteredFieldsCount;
            this.filteredFields = this.getFilteredFields;
        } else {
            this.filteredFieldsCount = null;
            this.filteredFields = this.fields;
            this.fieldsAddedDuringSearchCount = 0;
            this.removeBlanks();
        }
    }

    get searchActive(): boolean {
        return Boolean(this.searchInput);
    }

    get getFilteredFieldsCount(): number {
        const normalizedSearch = this.searchInput.toLowerCase();
        return this.fields.filter((field) => field.field_name?.toLowerCase().includes(normalizedSearch) ||
            field.export_field_name?.toLowerCase().includes(normalizedSearch)).length;
    }

    /**
    * keep blank row at the end, keep visible any rows that were added during this search state (fieldsAddedDuringSearchCount),
    * keep any blank rows that were created during editing in this search state
    */
    get getFilteredFields(): any {
        const normalizedSearch = this.searchInput.toLowerCase();
        const fieldsLastIndex: number = this.fields.length - 1;
        return this.fields.filter((field, index) => field.field_name?.toLowerCase().includes(normalizedSearch) ||
            field.export_field_name?.toLowerCase().includes(normalizedSearch) ||
            (index >= fieldsLastIndex - this.fieldsAddedDuringSearchCount) ||
            (field.field_name === '' && field.export_field_name === ''));
    }
}
