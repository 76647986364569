<div
    class="user-select-container"
    ngbTooltip="The user selected must have access to the store you want to submit a ticket for."
    placement="end">
    <ng-select
        [items]="analystUsers"
        [loading]="loadingUsers"
        loadingText="loading contacts..."
        bindLabel="user_name"
        [(ngModel)]="selectedUser"
        (change)="onUserChange()"
        [clearable]="false">

        @if (!selectedUser) {
            <ng-template ng-placeholder-tmp>
                <span class="text-secondary-500" >Select contact</span>
            </ng-template>
        }

        @if (displayPlaceholder) {
            <ng-template ng-placeholder-tmp>
                <span class="me-2">Submit request on behalf of:</span>
            </ng-template>
        }

    </ng-select>
</div>
