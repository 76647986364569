import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { CountryDropdownOption } from '@app/analyze-data/components/column-stats/column-stats.component';
import { ChannelOptionsType, ChannelRegionsType } from '@app/analyze-data/types/channel-options.type';
import { CategoryTaxonomyDataService } from '@app/category-taxonomy/services/category-taxonomy-data.service';
import { ChannelsWithRegionsResponse } from '@app/category-taxonomy/services/responses/channels-with-regions.response';
import FdxError from '@app/core/models/classes/fdx-error.class';
import { ChannelClient } from '@app/core/models/enums/channel-client.enum';
import { CountryService } from '@app/core/services/country.service';
import { OrderByPipe } from '@app/modules/pipes/pipes/order-by/order-by.pipe';
import { TCountryCode, TLanguageCode } from 'countries-list';
import { BehaviorSubject, Observable, Subject, filter, map, take, takeUntil, tap, throwError } from 'rxjs';


@Component({
    selector: 'fdx-channel-and-region-inputs',
    templateUrl: './channel-and-region-inputs.component.html',
    styleUrls: ['./channel-and-region-inputs.component.scss']
})
export class ChannelAndRegionInputsComponent implements OnInit, OnDestroy {

    databaseId: string = this.appStateService.getDatabaseId();

    @Input() form: FormGroupDirective;
    @Input() group: FormGroup;
    @Input() labelClass: string;
    @Input() inputClass: string;
    @Input() hideNoTaxonomy?: boolean;  // Temporary for hiding Google and Facebook from the list since they don't have taxonomy attributes yet

    @Output() readonly loadedChannels: EventEmitter<void> = new EventEmitter<void>();

    readonly channels$: BehaviorSubject<ChannelOptionsType[] | null> = new BehaviorSubject<ChannelOptionsType[] | null>(null);
    countries: CountryDropdownOption[] = [];

    private readonly unsubscribe$: Subject<void> = new Subject<void>();

    constructor(
        private readonly appStateService: AppStateService,
        private readonly categoryTaxonomyDataService: CategoryTaxonomyDataService,
        private readonly countryService: CountryService,
        private readonly fdxUI: FdxUI,
        private readonly orderByPipe: OrderByPipe
    ) {}

    ngOnInit(): void {
        this.initChannelsDropdown();
    }

    ngOnDestroy(): void {
        this.channels$.complete();

        this.unsubscribe$.next();
        this.unsubscribe$.complete();
    }

    private initChannelsDropdown(): void {
        this.group.controls.channel.valueChanges.pipe(
            tap((channel: ChannelOptionsType) => this.updateCountries(channel)),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.categoryTaxonomyDataService.getChannelsWithRegions(this.databaseId).pipe(
            tap((response: ChannelsWithRegionsResponse) => {
                if (this.hideNoTaxonomy) {
                    /* Hide Google */
                    const googleIndex = response.data.findIndex((value) => value.destinations.length > 0 && value.destinations[0].client === ChannelClient.Google);
                    if (googleIndex > -1) {
                        response.data.splice(googleIndex, 1);
                    }
                    /* Hide Google */

                    /* Hide Facebook */
                    const facebookIndex = response.data.findIndex((value) => value.destinations.length > 0 && value.destinations[0].client === ChannelClient.Facebook);
                    if (facebookIndex > -1) {
                        response.data.splice(facebookIndex, 1);
                    }
                    /* Hide Facebook */
                }

                this.channels$.next(this.orderByPipe.transform(response.data, 'display'));
                this.loadedChannels.emit();
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe({
            error: (error: unknown) => {
                if (error instanceof HttpErrorResponse) {
                    this.fdxUI.showToastError(`Something went wrong loading channels: ${error.message}`);
                }
                return throwError(() => new FdxError('UNKNOWN_ERROR', 'Error loading channels'));
            }
        });
    }

    /**
     * Returns the channel from the channels array that matches the passed in channel name
     * @param newChannelName the name of the channel to try to find
     * @returns the matching channel type, or undefined if one is not found
     */
    findMatchingChannel(newChannelName: string): Observable<ChannelOptionsType | undefined> {
        return this.channels$
            .pipe(
                filter((channels) => channels !== null),
                take(1),
                map((channels) =>
                    channels.find(
                        (channel: ChannelOptionsType) => channel.display.toLowerCase() === newChannelName.toLowerCase()
                    )
                ),
                takeUntil(this.unsubscribe$)
            );
    }

    /**
     * Update the countries dropdown/selection when we change channels
     * @param channel the channel we're updating to
     * @param urlCountry the country and region codes in the URL in the form of 'US-en-US', where the first 'US' is the country code, then 'en-US' is the region
     */
    updateCountries(channel: ChannelOptionsType, urlCountry: string = null): void {
        // Clear out any old country selection when changing channels
        this.group.controls.country.reset();

        // Loop through countries to determine duplicates
        const countriesSeen = new Set<TCountryCode>();
        const duplicateCountries: TCountryCode[] = [];
        for (const destination of channel.destinations) {
            if (countriesSeen.has(destination.country)) {
                duplicateCountries.push(destination.country);
            }
            countriesSeen.add(destination.country);
        }

        // Map our regions to the country dropdown option type
        const newCountries = channel.destinations.map<CountryDropdownOption>((destination: ChannelRegionsType) => {
            if (String(destination.country) === 'UK') {
                destination.country = 'GB';   //  Our library doesn't include the code 'UK', but Amazon actually uses 'UK' instead of the standard 'GB' code
            }

            return {
                ...destination,
                countryName: this.countryService.getCountryNameByCode(destination.country),
                languageName: this.countryService.getLanguageNameByCode(destination.region.substring(0, 2) as TLanguageCode),
                displayLanguage: duplicateCountries.includes(destination.country)    // Only display the language in the UI if it is a duplicate country
            };
        });

        // Finally, order the new list by the name of the country
        this.countries = this.orderByPipe.transform(newCountries, 'countryName');
        const urlCountryIndex = urlCountry ? this.countries.findIndex((value: CountryDropdownOption) => `${value.country}-${value.region}`.toLowerCase() === urlCountry.toLowerCase()) : -1;
        const unitedStatesIndex = this.countries.findIndex((value: CountryDropdownOption) => value.countryName === 'United States');

        if (urlCountryIndex >= 0) {                 // Auto-select country if there's a matching one in the URL
            this.group.controls.country.setValue(this.countries[urlCountryIndex]);
        } else if (this.countries.length === 1) {   // Or select the first one if there's only one value (HTML hides input)
            this.group.controls.country.setValue(this.countries[0]);
        } else if (unitedStatesIndex >= 0) {        // Or set to the index of United States
            this.group.controls.country.setValue(this.countries[unitedStatesIndex]);
        }
    }
}
