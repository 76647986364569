import { IPromise, ui } from 'angular';
import 'angular-mocks';

import { BootstrapThemeProp } from '@app/core/services/bootstrap-theme.service';
import { componentName as confirmationModalComponentName } from '../components/fdx-confirmation-modal/fdx-confirmation-modal.component';
import { componentName as infoModalComponentName } from '../components/fdx-info-modal/fdx-info-modal.component';

export default class ModalService {
	static $inject = [
		'$uibModal'
	];

	constructor(private $uibModal: ui.bootstrap.IModalService) { }

	/**
	 * Opens a bootstrap ui modal with the specified options.
	 * The fdx-modal and modal-dialog-centered css classes will be added to windowClass.
	 * The fdx-modal css class will be added to backdropClass.
	 * @param options IModalSetting options object
	 * @returns IPromise of modal result
	 */
	public open(options: ui.bootstrap.IModalSettings): IPromise<any> {
		return this.$uibModal
			.open(
				{
					...options,
					windowClass: this.getDistinctClasses(
						['fdx-modal', 'modal-dialog-centered'],
						options.windowClass
					),
					backdropClass: this.getDistinctClasses(
						['fdx-modal'],
						options.backdropClass
					)
				}
			)
			.result
	}

	public showInfoModal(
		title?: string,
		body?: string
	): IPromise<any> {
		return this.openInfoModal(
			'info',
			title,
			body
		);
	}

	public showDangerInfoModal(
		title?: string,
		body?: string
	): IPromise<any> {
		return this.openInfoModal(
			'danger',
			title,
			body
		);
	}

	public showConfirmationModal(
		title?: string,
		body?: string,
		confirmText?: string,
		cancelText?: string,
		enableHtml?: boolean
	): IPromise<any> {
		return this.openConfirmationModal(
			'primary',
			title,
			body,
			confirmText,
			cancelText,
			enableHtml
		);
	}

	public showDangerConfirmationModal(
		title?: string,
		body?: string,
		confirmText?: string,
		cancelText?: string,
		enableHtml?: boolean
	): IPromise<any> {
		return this.openConfirmationModal(
			'danger',
			title,
			body,
			confirmText,
			cancelText,
			enableHtml
		);
	}

	public showLockedAccessModal(
		title?: string,
		body?: string,
		confirmText?: string,
		cancelText?: string
	): IPromise<any> {
		return this.openLockedAccessModal(
			'danger',
			title,
			body,
			confirmText,
			cancelText
		);
	}

	private getDistinctClasses(a: string[], b: string): string;
	private getDistinctClasses(a: string[], b: string[]): string;
	private getDistinctClasses(a: string[], b: any): string {
		let arrayB: string[];

		if (typeof (b) === 'string') {
			arrayB = b.split(' ');
		} else if (Array.isArray(b)) {
			arrayB = b;
		} else {
			return a.join(' ');
		}

		const set: Set<string> = new Set(a);

		if (arrayB) {
			for (const clss of arrayB) {
				set.add(clss);
			}
		}

		return Array.from(set).join(' ');
	}

	private openInfoModal(
		theme: BootstrapThemeProp,
		title: string,
		body: string
	): IPromise<any> {
		return this.open(
			{
				component: infoModalComponentName,
				resolve: {
					modalTheme: () => (theme),
					title: () => (title),
					body: () => (body)
				}
			}
		);
	}

	private openConfirmationModal(
		theme: BootstrapThemeProp,
		title: string,
		body: string,
		confirmText: string,
		cancelText: string,
		enableHtml: boolean
	): IPromise<any> {
		return this.open(
			{
				component: confirmationModalComponentName,
				resolve: {
					modalTheme: () => (theme),
					title: () => (title),
					body: () => (body),
					confirmText: () => (confirmText),
					cancelText: () => (cancelText),
					enableHtml: () => (enableHtml)
				}
			}
		);
	}

	private openLockedAccessModal(
		theme: BootstrapThemeProp,
		title: string,
		body: string,
		confirmText: string,
		cancelText: string
	): IPromise<any> {
		return this.open(
			{
				backdrop:'static',
				component: confirmationModalComponentName,
				resolve: {
					modalTheme: () => (theme),
					title: () => (title),
					body: () => (body),
					confirmText: () => (confirmText),
					cancelText: () => (cancelText)
				}
			}
		);
	}
}
