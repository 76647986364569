import { UserModel } from '@ajs/models/user';
import AppStateService from '@ajs/services/AppStateService';
import ImpersonateUserService from '@ajs/services/ImpersonateUserService';
import { Component, Input } from '@angular/core';
import { isInternalUsername } from '@app/core/functions/isInternalUsername';
import { faCircle, faUser, IconDefinition } from '@fortawesome/pro-solid-svg-icons';

@Component({
    selector: 'fdx-user-initials',
    templateUrl: './user-initials.component.html',
    styleUrls: ['./user-initials.component.scss']
})
export class UserInitialsComponent {
    readonly circleIcon: IconDefinition = faCircle;
    readonly userIcon: IconDefinition = faUser;

    @Input() firstName?: string;
    @Input() lastName?: string;
    @Input() userId?: number;
    @Input() username?: string;

    @Input() user?: UserModel;
    @Input() hasNotifications: boolean = false;

    get isNonSystemUser(): boolean {
        return !this.user && !this.userId;
    }

    get initials(): string {
        if (this.username) {
            return this.getInitials(this.firstName, this.lastName);
        }

        if (!this.user?.user_fetched) {
            return '';
        }

        return this.getInitials(this.user.first_name, this.user.last_name);
    }

    get initialsClass(): string {
        if (!this.username && !this.user?.user_fetched) {
            return 'user-not-fetched';
        }

        if (this.username) {
            return this.getClassFromUsername();
        }

        return this.getClassFromUserModel();
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly impersonateUserService: ImpersonateUserService   // TODO: Upgrade to Angular
    ) { }

    private getInitials(firstName: string, lastName: string): string {
        return (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase()
    }

    getClassFromUsername(): string {
        if (this.isNonSystemUser) {
            return 'non-system-user'
        }

        // Display current user with demo styling
        const currentUser = this.appStateService.getUser();
        if (this.username === currentUser.user_name) {
            const settings = currentUser?.getSettings();
            if (settings['user.demo_client_view']) {
                return 'external-user';
            }
        }

        return isInternalUsername(this.username) ? 'internal-user' : 'external-user';
    }

    getClassFromUserModel(): string {
        if (this.impersonateUserService.getUser()) {
            return 'external-user';
        }

        return this.user.isInternal() ? 'internal-user' : 'external-user';
    }
}
